import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./Verification.css";

const VerificationInfoPage = () => {
  const { t } = useTranslation();
  const selectedCompanyInfo = useSelector(
    (state) => state.Auth.selectedCompanyInfo
  );

  return selectedCompanyInfo?.onHold ? (
    <div>
      <div className="rejected" style={{ color: "red" }}>
        {t("translations:on_hold")}
      </div>
      <div className="reason">{`${t("translations:on_hold_reason1")} ${
        selectedCompanyInfo?.onHoldReason
      } ${t("translations:on_hold_reason2")}`}</div>
    </div>
  ) : (
    <div>
      <div className="rejected" style={{ color: "rgb(255, 165, 0)" }}>
        {t("translations:under_verification")}
      </div>
      <div className="reason">{t("translations:activate_company")}</div>
    </div>
  );
};

export default VerificationInfoPage;
