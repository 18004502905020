// eslint-disable-next-line import/no-anonymous-default-export
export default {
  en: {
    translations: {
      campagin_publish_success: "Campaign successfully published",
      campaign_publish_error: "Problem occurred while publishing campaign",
      target_user: "No. of Target Users",
      target_user_placeholder: "Please enter no. of target users",
      target_user_error: "No. of target users is required",
      price_is_required: "Price is required",
      edit_know_how: "Edit Know-how",
      know_how_create_success: "Know-How successfully created",
      know_how_create_error: "Problem occurred while creating know-how",
      know_how_update_success: "Know-how successfully updated",
      know_how_update_error: "Problem occurred while updating know-how",
      know_how_publish_success: "Know-how published successfully",
      know_how_publish_error: "Problem occurred while publishing know-how",
      publish_know_how: "Publish know-how",
      profile_created_successfully: "Profile created successfully",
      continue: "Continue",
      complete_profile: "Complete Profile",
      join_company: "Would you like to join the company that invited you?",
      please_select_the_company: "Please Select the company",
      create_a_new_company: "Create a new company",
      or: "or",
      select_company: "Select a company to login",
      log_in: "Log In",
      email_error: "Please enter valid email",
      password: "Password",
      password_error: "Password is required",
      forgot_password: "Forgot Password?",
      login: "Login",
      doesnot_account: "Don't have account yet?",
      sign_up: "Sign Up",
      google: "Google",
      password_error1: "Password must have 8 characters",
      confirm_password: "Confirm Password",
      confirm_password_error: "Confirmed password must have 8 characters",
      password_error2: "Password and confirmed password must be same",
      already_account: "Already have an account?",
      sign_in: "Sign in",
      on_hold_reason1: "Your company is on hold due to",
      on_hold_reason2: "Please contact the admin",
      activate_company: "It will take around 24 hours to activate the company.",
      home: "Home",
      motivation: "Motivation",
      know_how: "Know-How",
      campaigns: "Campaign",
      support: "Support",
      team: "Team",
      companies: "Companies",
      packages: "Packages",
      settings: "Settings",
      about_us: "About Us",
      logout: "Logout",
      language_tooltip: "Select your language",
      analysis: "Analysis",
      total_revenues: "Total Revenues",
      total_customers: "Total Customers",
      total_distributions: "Total Distributions",
      total_likes: "Total Likes",
      total_companies: "Total Companies",
      monthly: "Monthly",
      weekly: "Weekly",
      daily: "Daily",
      revenue: "Revenue",
      customers: "Customers",
      distributions: "Distributions",
      tile_view: "Tile View",
      list_view: "List View",
      create_motivation: "Create Motivation",
      no_package_found: "No Package Found",
      status: "Status",
      active: "Active",
      on_hold: "On Hold",
      de_activate: "Deactivated",
      no_content_items: "No. of Content Items",
      created_on: "Created On",
      price: "Price",
      number_of_users: "Number of Users",
      views: "Views",
      rating: "Rating",
      status_change: "Status changed successfully",
      error_status_change: "Problem occurred while changing the status",
      title: "Title",
      image: "Image",
      description: "Description",
      no_of_content: "No. of Contents",
      no_of_users: "No. of Users",
      draft: "Draft",
      rejected: "Rejected",
      under_verification: "Under Verification",
      move_to_motivation: "Move To Motivation",
      move_message: "Are you sure want to move this package",
      move_message1: "to motivation?",
      cancel: "CANCEL",
      ok: "Ok",
      create_know_how: "Create Know-How",
      success_move: "Successfully moved to Motivation",
      error_move: "Problem occur while moving the package",
      create_campaign: "Create Campaign",
      budget: "Budget",
      add_member_error: "User with this email id is already exists in company",
      add_member_error1: "Email And Role are required",
      name: "Name",
      email: "Email",
      role: "Role",
      role_updation_failed: "Update of user role failed",
      remove_user: "Removal of user failed",
      remove_message: "You can not remove yourself from this company",
      add_team_members: "Add Team Members",
      email_label: "Enter Email Id to add user",
      admin: "Admin",
      user: "User",
      add: "Add",
      pending: "Pending",
      search: "Search…",
      clear: "Clear",
      no_member_found: "No Member Found",
      dialog: "Are you sure want to change this selected",
      role_from: "role from",
      to: "to",
      delete_user: "Are you sure want to delete this selected user",
      delete_user1: "from team",
      active_list: "Active List",
      rejected_list: "Rejected List",
      go_to_company_profile: "Go to Company Profile",
      change_campaign_price: "Change Campaign Price",
      hold_message: "Are you sure to put this company on hold",
      reason: "Reason",
      reason_placeholder: "Please enter the reason for the hold",
      reason_error: "Reason is required",
      campaign_dialog: "Change Campaign Price of selected company",
      campaign_price: "Campaign Price",
      campaign_price_placeholder: "Please enter the campaign price",
      campaign_price_error: "Campaign price is required",
      company_name: "Company Name",
      company_address: "Company Address",
      user_name: "User Name",
      user_email: "User Email",
      no_company_found: "No company found",
      accept_company: "Accept Company",
      rejected_reason: "Reason for Rejection",
      no_rejected_company_found: "No rejected company found",
      accept_caompany_dialog: "Are you sure want accept this company",
      deactive: "Deactive",
      team_details: "Team Details",
      network_error: "Network Error",
      pending_list: "Pending List",
      all: "All",
      owner_name: "Owner Name",
      company: "Company",
      view_package: "View Package",
      accept_pacakge: "Accept Package",
      reject_package: "Reject Package",
      accept_reject_dialog: "Are you sure want",
      accept: "to accept",
      reject: "to reject",
      this_pacakge: "this package",
      reason_placeholder1: "Please enter the reason of the rejection",
      on_hold_dialog: "Are you sure to put this package on hold",
      accept_package_dialog: "Are you sure want accept this package",
      package_details: "Package Details",
      package_content: "Package Content",
      back: "Back",
      view_content: "View Content",
      content: "Content",
      action: "Action",
      no_content_found: "No content found",
      close: "Close",
      file_upload_failed: "File upload failed",
      profile: "Profile",
      error_image: "Error while uploading image. Please try again in some time",
      first_name: "First Name",
      first_name_error: "First name is required",
      last_name: "Last Name",
      last_name_error: "Last name is required",
      update: "Update",
      all_field_is_required: "All fields are required",
      create_new_company: "Create New Company",
      company_name_error: "Company name is required",
      address: "Address",
      comapny_address_error: "Company address is required",
      create_company: "Create Company",
      your_company_name: "Your Company Name",
      main_address: "Main Address",
      address_is_required: "Address is required",
      logo: "Logo",
      create_package: "Create Package",
      add_content: "Add Content",
      publish_package: "Publish Package",
      edit_motivation: "Edit Motivation",
      motivation_create_success: "Motivation successfully created",
      motivation_create_error: "Problem occurred while creating the motivation",
      motivation_update_success: "Motivation successfully updated",
      motivation_update_error: "Problem occurred while updating the motivation",
      content_add_success: "Content added successfully",
      content_add_error: "Problem occurred while adding the content",
      content_update_success: "Content edited successfully",
      content_update_error: "Problem occurred while editing the content",
      content_delete_success: "Content deleted successfully",
      content_delete_error: "Problem occurred while deleting the content",
      motivation_publish_success: "Motivation published successfully",
      motivation_publish_error:
        "Problem occurred while publishing the motivation",
      category_is_required: "Category is required",
      image_is_required: "Image is required",
      image_error: "Image file is required",
      title_placeholder: "Please enter the title",
      title_Error: "Title is required",
      description_placeholder: "Please enter the description",
      description_error: "Description is required",
      characters: "Characters",
      category: "Category",
      first_check: "Add company name & no. of elements to content footer",
      second_check: "Add package name & logo to content header",
      save: "Save",
      forward: "Forward",
      next: "Next",
      add_content_error: "Please enter something to add content",
      edit: "Edit",
      delete: "Delete",
      previous: "Previous",
      view: "View",
      add_userName: "Add User Name",
      add_habit: "Add Habit",
      add_goal: "Add Goal",
      publish_motivation: "Publish Motivation",
      save_as_draft: "Save as Draft",
      publish_campaign: "Publish Campaign",
      edit_campaign: "Edit Campaign",
      campaign_create_success: "Campaign created successfully",
      campaign_create_error: "Problem occurred while publishing campaign",
      campaign_update_success: "Campaign updated successfully",
      campaign_update_error: "Problem occurred while updating campaign",
      likes: "Likes",
      company_introduction_link: "Company Introduction Link",
      company_bio: "Company Bio",
      wallet: "Wallet",
      redeem_yeap_coin: "Redeem Yeap Coins",
      total_revenue: "Total Revenue",
      total_withdraw: "Total Withdrawals",
      current_balance: "Current Balance",
      paypal_transfer: "Paypal Transfer",
      bank_transfer: "Bank Transfer",
      transaction_report: "Transaction Report",
      rate_settings: "Rate Settings",
      past_withdrawal: "Past Withdrawal",
      payment_method: "Payment Method",
      redeem_coin: "Redeem Coin",
      current_exchange_rate: "Current Payout Exchange Rate:",
      add_payment_method: "Please add any payment method to redeem it.",
      withdraw_amount: "Withdraw Amount",
      amount_placeholder: "Enter amount you have to withdraw",
      error_amount: "Withdraw Amount is required",
      error_amount1: 'You don"t have enough balance',
      transaction_type: "Transaction Type",
      transaction_error: "Transcation Type is required",
      redeem: "REDEEM",
      date: "Date",
      amount: "Amount",
      not_found_transaction: "Not Found Any Transaction",
      swift: "SWIFT/BIC",
      swift_error: "Swift/BIC code is required",
      iban: "IBAN",
      iban_no: "IBAN Number",
      iban_error: "IBAN is required",
      name_on_account: "Name on Account",
      name_error: "Name On Account is required",
      city_state: "City and State/Province",
      city_error: "City is required",
      country: "Country",
      country_error: "Country is required",
      phone_number: "Phone Number",
      phone_error: "Phone No is required",
      check_payment:
        "I attest that I am the owner and have full authorization to this bank account.",
      paypal: "Paypal",
      email_address: "Email Adrees",
      paypal_email: "Enter your paypal registered email ID",
      email_error1: "Email is required",
      receiver: "Receiver",
      info: "Info",
      bank: "Bank",
      approved: "Approved",
      processing: "Processing",
      completed: "Completed",
      approve: "Approve",
      process: "Process",
      complete: "Complete",
      show_bank_info: "Show Bank Info",
      transaction: "Transaction",
      payout_exchange_rate: "Payout Exchange Rate",
      payout_error: "Payout Exchange Rate must be Greater-than 0",
      purchase_exchange_rate: "Purchase Exchange Rate",
      purchase_error: "Purchase Exchange Rate must be Greater-than 0",
      report_history: "Report History",
      no_report_issue_found: "No Report Issue Found",
      reported_content: "Reported Content",
    },
  },
  fr: {
    translations: {
      campagin_publish_success: "Publier avec succès la campagne",
      campaign_publish_error:
        "Un problème survient lors de la publication de la campagne",
      target_user: "Nombre d'utilisateurs cibles",
      target_user_placeholder: "Veuillez saisir le n° de l'utilisateur cible",
      target_user_error: "Le nombre d'utilisateurs cibles est requis",
      price_is_required: "Le prix est requis",
      edit_know_how: "Modifier le savoir-faire",
      know_how_create_success: "Créer avec succès le savoir-faire",
      know_how_create_error:
        "Un problème survient lors de la création du savoir-faire",
      know_how_update_success: "Mettre à jour le savoir-faire avec succès",
      know_how_update_error:
        "Un problème survient lors de la mise à jour du savoir-faire",
      know_how_publish_success: "Réussir à publier le savoir-faire",
      know_how_publish_error:
        "Un problème survient lors de la publication du savoir-faire",
      publish_know_how: "Publier le savoir-faire",
      profile_created_successfully: "Profil créé avec succès",
      continue: "Continuer",
      complete_profile: "Profil complet",
      join_company: "Vous souhaitez rejoindre l'entreprise qui vous a invité ?",
      please_select_the_company: "Veuillez sélectionner l'entreprise",
      create_a_new_company: "Créer une nouvelle société",
      or: "OU",
      select_company: "Sélectionnez une entreprise pour vous connecter",
      log_in: "Connexion",
      email_error: "Veuillez saisir une adresse e-mail valide",
      password: "Mot de passe",
      password_error: "Mot de passe requis",
      forgot_password: "Mot de passe oublié ?",
      login: "Connexion",
      doesnot_account: "Vous n'avez pas encore de compte ?",
      sign_up: "S'inscrire",
      google: "Google",
      password_error1: "Le mot de passe doit comporter 8 caractères",
      confirm_password: "Confirmez le mot de passe",
      confirm_password_error:
        "Le mot de passe de confirmation doit comporter 8 caractères",
      password_error2:
        "Le mot de passe et le mot de passe de confirmation doivent être identiques",
      already_account: "Vous avez déjà un compte ?",
      sign_in: "S'identifier",
      on_hold_reason1: "Votre entreprise est en attente en raison de",
      on_hold_reason2: "veuillez contacter l'administrateur",
      activate_company: "Il faudra environ 24 heures pour activer la société.",
      home: "Maison",
      motivation: "Motivation",
      know_how: "Savoir-faire",
      campaigns: "Campagnes",
      support: "Soutien",
      team: "Équipe",
      companies: "Entreprises",
      packages: "Paquets",
      settings: "Réglages",
      about_us: "À propos de nous",
      logout: "Se déconnecter",
      language_tooltip: "choisissez votre langue",
      analysis: "Une analyse",
      total_revenues: "Revenus totaux",
      total_customers: "Nombre total de clients",
      total_distributions: "Distributions totales",
      total_likes: "Nombre total de J'aime",
      total_companies: "Entreprises totales",
      monthly: "Mensuel",
      weekly: "Hebdomadaire",
      daily: "du quotidien",
      revenue: "Revenu",
      customers: "Clients",
      distributions: "Distributions",
      tile_view: "Vue en mosaïque",
      list_view: "Affichage de liste",
      create_motivation: "Créer de la motivation",
      no_package_found: "Aucun colis trouvé",
      status: "Statut",
      active: "Actif",
      on_hold: "En attente",
      de_activate: "Désactiver",
      no_content_items: "Nombre d'éléments de contenu",
      created_on: "Créé sur",
      price: "Prix",
      number_of_users: "Nombre d'utilisateurs",
      views: "Vues",
      rating: "Évaluation",
      status_change: "Changer le statut avec succès",
      error_status_change:
        "Un problème survient lors de la modification de l'état",
      title: "Titre",
      image: "Image",
      description: "La description",
      no_of_content: "Nombre de contenu",
      no_of_users: "Nombre d'utilisateurs",
      draft: "Brouillon",
      rejected: "Rejeté",
      under_verification: "En cours de vérification",
      move_to_motivation: "Passer à la motivation",
      move_message: "Êtes-vous sûr de vouloir déplacer ce paquet",
      move_message1: "à la motivation ?",
      cancel: "ANNULER",
      ok: "D'accord",
      create_know_how: "Créer un savoir-faire",
      success_move: "Passer à la motivation avec succès",
      error_move: "Un problème survient lors du déplacement du package",
      create_campaign: "Créer une campagne",
      budget: "Budget",
      add_member_error:
        "L'utilisateur avec cet identifiant de messagerie est déjà présent dans l'entreprise",
      add_member_error1: "Email et rôle sont requis",
      name: "Nom",
      email: "E-mail",
      role: "Rôle",
      role_updation_failed: "Échec de la mise à jour du rôle utilisateur",
      remove_user: "la suppression de l'utilisateur a échoué",
      remove_message: "Vous ne pouvez pas vous retirer de cette entreprise",
      add_team_members: "Ajouter des membres d'équipe",
      email_label:
        "Entrez l'identifiant de messagerie pour ajouter un utilisateur",
      admin: "Administrateur",
      user: "Utilisateur",
      add: "Ajouter",
      pending: "En attente",
      search: "Chercher…",
      clear: "Dégager",
      no_member_found: "Aucun membre trouvé",
      dialog: "Voulez-vous vraiment modifier cette sélection",
      role_from: "rôle de",
      to: "à",
      delete_user:
        "Êtes-vous sûr de vouloir supprimer cet utilisateur sélectionné",
      delete_user1: "de l'équipe",
      active_list: "Liste active",
      rejected_list: "Liste rejetée",
      go_to_company_profile: "Aller au profil de l'entreprise",
      change_campaign_price: "Modifier le prix de la campagne",
      hold_message: "Voulez-vous vraiment mettre cette entreprise en attente ?",
      reason: "Raison",
      reason_placeholder: "Veuillez saisir la raison de la mise en attente",
      reason_error: "La raison est requise",
      campaign_dialog:
        "Modifier le prix de la campagne de la société sélectionnée",
      campaign_price: "Prix de la campagne",
      campaign_price_placeholder: "Veuillez saisir le prix de la campagne",
      campaign_price_error: "Le prix de la campagne est requis",
      company_name: "Nom de l'entreprise",
      company_address: "Adresse de la société",
      user_name: "Nom d'utilisateur",
      user_email: "Courriel de l'utilisateur",
      no_company_found: "Aucune entreprise trouvée",
      accept_company: "Accepter la société",
      rejected_reason: "Raison rejetée",
      no_rejected_company_found: "Aucune entreprise rejetée n'a été trouvée",
      accept_caompany_dialog:
        "Êtes-vous sûr de vouloir accepter cette entreprise",
      deactive: "Désactiver",
      team_details: "Détails de l'équipe",
      network_error: "Erreur réseau",
      pending_list: "Liste en attente",
      all: "Tout",
      owner_name: "Le nom du propriétaire",
      company: "Compagnie",
      view_package: "Voir le forfait",
      accept_pacakge: "Accepter le paquet",
      reject_package: "Rejeter le colis",
      accept_reject_dialog: "Voulez-vous vraiment",
      accept: "J'accepte",
      reject: "rejeter",
      this_pacakge: "ce forfait",
      reason_placeholder1: "Veuillez saisir le motif du rejet",
      on_hold_dialog: "Êtes-vous sûr de vouloir mettre ce paquet en attente",
      accept_package_dialog: "Êtes-vous sûr de vouloir accepter ce paquet",
      package_details: "Détails du forfait",
      package_content: "Contenu du coffret",
      back: "Retour",
      view_content: "Afficher le contenu",
      content: "Contenu",
      action: "Action",
      no_content_found: "Aucun contenu trouvé",
      close: "proche",
      file_upload_failed: "Le téléchargement du fichier a échoué",
      profile: "Profil",
      error_image:
        "Erreur lors du téléchargement de l'image, veuillez réessayer dans un certain temps",
      first_name: "Prénom",
      first_name_error: "Le prénom est requis",
      last_name: "Nom de famille",
      last_name_error: "Le nom de famille est requis",
      update: "Mise à jour",
      all_field_is_required: "Tous les champs sont obligatoires",
      create_new_company: "Créer une nouvelle entreprise",
      company_name_error: "Le nom de l'entreprise est requis",
      address: "Adresse",
      comapny_address_error: "L'adresse de l'entreprise est obligatoire",
      create_company: "Créer une entreprise",
      your_company_name: "Le nom de votre société",
      main_address: "Adresse principale",
      address_is_required: "L'adresse est obligatoire",
      logo: "LOGO",
      create_package: "Créer un package",
      add_content: "Ajouter du contenu",
      publish_package: "Publier le paquet",
      edit_motivation: "Modifier la motivation",
      motivation_create_success: "Création réussie de la motivation",
      motivation_create_error:
        "Un problème survient lors de la création de la motivation",
      motivation_update_success: "Mettre à jour avec succès la Motivation",
      motivation_update_error:
        "Un problème survient lors de la mise à jour de Motivation",
      content_add_success: "Contenu ajouté avec succès",
      content_add_error: "Un problème survient lors de l'ajout du contenu",
      content_update_success: "Modifier le contenu avec succès",
      content_update_error:
        "Un problème survient lors de la modification du contenu",
      content_delete_success: "Le contenu a bien été supprimé",
      content_delete_error:
        "Un problème survient lors de la suppression du contenu",
      motivation_publish_success: "Publier avec succès la motivation",
      motivation_publish_error:
        "Un problème survient lors de la publication de la motivation",
      category_is_required: "La catégorie est obligatoire",
      image_is_required: "L'image est requise",
      image_error: "Le fichier image est requis",
      title_placeholder: "Veuillez entrer le titre",
      title_Error: "Le titre est requis",
      description_placeholder: "Veuillez entrer la description",
      description_error: "La description est obligatoire",
      characters: "Personnages",
      category: "Catégorie",
      first_check:
        "Ajouter le nom de l'entreprise et le numéro de l'élément au pied de page du contenu",
      second_check:
        "Ajouter le nom et le logo du package à l'en-tête du contenu",
      save: "sauvegarder",
      forward: "Vers l'avant",
      next: "Prochain",
      add_content_error:
        "Veuillez entrer quelque chose pour ajouter du contenu",
      edit: "Éditer",
      delete: "Effacer",
      previous: "Précédent",
      view: "Voir",
      add_userName: "Ajouter un nom d'utilisateur",
      add_habit: "Ajouter une habitude",
      add_goal: "Ajouter un objectif",
      publish_motivation: "Publier la motivation",
      save_as_draft: "Enregistrer comme brouillon",
      publish_campaign: "Publier la campagne",
      edit_campaign: "Modifier la campagne",
      campaign_create_success: "Campagne créée avec succès",
      campaign_create_error:
        "Un problème survient lors de la création de la campagne",
      campaign_update_success: "Campagne mise à jour avec succès",
      campaign_update_error:
        "Un problème survient lors de la mise à jour de la campagne",
      likes: "Aime",
      company_introduction_link: "Lien de présentation de l'entreprise",
      company_bio: "Biographie de l'entreprise",
      wallet: "Porte monnaie",
      redeem_yeap_coin: "Échanger la pièce Yeap",
      total_revenue: "Revenu total",
      total_withdraw: "Retrait total",
      current_balance: "Solde actuel",
      paypal_transfer: "Virement Paypal",
      bank_transfer: "Virement",
      transaction_report: "Rapport de transaction",
      rate_settings: "Paramètres de taux",
      past_withdrawal: "Retrait passé",
      payment_method: "Mode de paiement",
      redeem_coin: "Échanger une pièce",
      current_exchange_rate: "Taux de change actuel :",
      add_payment_method:
        "Veuillez ajouter n'importe quel mode de paiement pour l'utiliser.",
      withdraw_amount: "Montant du retrait",
      amount_placeholder: "Entrez le montant que vous devez retirer",
      error_amount: "Le montant du retrait est requis",
      error_amount1: "Vous n'avez pas assez d'équilibre",
      transaction_type: "Type de transaction",
      transaction_error: "Le type de transaction est requis",
      redeem: "RACHETER",
      date: "Date",
      amount: "Montant",
      not_found_transaction: "Aucune transaction trouvée",
      swift: "SWIFT/BIC",
      swift_error: "Le code Swift/BIC est requis",
      iban: "IBAN",
      iban_no: "Numéro IBAN",
      iban_error: "IBAN est requis",
      name_on_account: "Le nom sur le compte",
      name_error: "Le nom sur le compte est requis",
      city_state: "Ville et État/Province",
      city_error: "La ville est obligatoire",
      country: "Pays",
      country_error: "Le pays est requis",
      phone_number: "Numéro de téléphone",
      phone_error: "Le numéro de téléphone est requis",
      check_payment:
        "J'atteste que je suis le propriétaire et que j'ai l'autorisation complète sur ce compte bancaire.",
      paypal: "Pay Pal",
      email_address: "Adresses e-mail",
      paypal_email: "Entrez votre identifiant de messagerie enregistré paypal",
      email_error1: "L'e-mail est requis",
      receiver: "Destinataire",
      info: "Info",
      bank: "Banque",
      approved: "Approuvé",
      processing: "Traitement",
      completed: "Complété",
      approve: "Approuver",
      process: "Traiter",
      complete: "Complet",
      show_bank_info: "Afficher les informations bancaires",
      transaction: "Transaction",
      payout_exchange_rate: "Taux de change de paiement",
      payout_error: "Le taux de change du paiement doit être supérieur à 0",
      purchase_exchange_rate: "Taux de change d'achat",
      purchase_error: "Le taux de change d'achat doit être supérieur à 0",
      report_history: "Historique des rapports",
      no_report_issue_found: "Aucun problème de rapport trouvé",
      reported_content: "Contenu signalé",
    },
  },
  de: {
    translations: {
      campagin_publish_success: "Kampagne erfolgreich veröffentlicht",
      campaign_publish_error: "Problem bei Veröffentlichung der Kampagne",
      target_user: "Anzahl anzusprechende Nutzer",
      target_user_placeholder: "Bitte Anzahl anzusprechender Nutzer eingeben",
      target_user_error: "Anzahl anzusprechende Nutzer wird benötigt",
      price_is_required: "Preis wird benötigt",
      edit_know_how: "Know-How bearbeiten",
      know_how_create_success: "Know-How erfolgreich erstellt",
      know_how_create_error: "Problem bei Erstellung des Know-Hows",
      know_how_update_success: "Know-How erfolgreich geändert",
      know_how_update_error: "Problem bei Änderung des Know-Hows",
      know_how_publish_success: "Know-How erfolgreich veröffentlicht",
      know_how_publish_error: "Problem bei Veröffentlichung des Know-How",
      publish_know_how: "Know-How veröffentlicht",
      profile_created_successfully: "Profile erfolgreich erstellt",
      continue: "Weiter",
      complete_profile: "Profil vervollständigen",
      join_company:
        "Möchtest Du dem Unternehmen beitreten, dass Dich eingeladen hat?",
      please_select_the_company: "Bitte Unternehmen auswählen",
      create_a_new_company: "Neues Unternehmen erstellen",
      or: "oder",
      select_company: "Wähle ein Unternehmen zum Einloggen aus",
      log_in: "Einloggen",
      email_error: "Bitte gib eine gültige E-Mail-Adresse an",
      password: "Passwort",
      password_error: "Passwort wird benötigt",
      forgot_password: "Passwort vergessen?",
      login: "Einloggen",
      doesnot_account: "Hast Du noch keinen Account?",
      sign_up: "Registrieren",
      google: "Google",
      password_error1: "Passwort muss mindestens 8 Zeichen haben",
      confirm_password: "Passwort bestätigen",
      confirm_password_error:
        "Passwort-Bestätigung muss mindestens 8 Zeichen haben",
      password_error2:
        "Passwort und Passwort-Bestätigung müssen übereinstimmen",
      already_account: "Hast Du bereits einen Account?",
      sign_in: "Einloggen",
      on_hold_reason1: "Der Account Deines Unternehmens ist pausiert wegen",
      on_hold_reason2: "Bitte Admin kontaktieren",
      activate_company:
        "Es wird 24 Stunden dauern, Dein Unternehmen zu aktivieren",
      home: "Home",
      motivation: "Motivation",
      know_how: "Know-How",
      campaigns: "Kampagne",
      support: "Support",
      team: "Team",
      companies: "Unternehmen",
      packages: "Pakete",
      settings: "Einstellungen",
      about_us: "Über Uns",
      logout: "Ausloggen",
      language_tooltip: "Wähle Deine Sprache",
      analysis: "Analyse",
      total_revenues: "Gewinn gesamt",
      total_customers: "Kunden gesamt",
      total_distributions: "Verteilung gesamt",
      total_likes: "Likes gesamt",
      total_companies: "Unternehmen gesamt",
      monthly: "monatlich",
      weekly: "wöchentlich",
      daily: "täglich",
      revenue: "Gewinn",
      customers: "Kunden",
      distributions: "Verteilungen",
      tile_view: "Kachel-Ansicht",
      list_view: "Listen-Ansticht",
      create_motivation: "Motivation erstellen",
      no_package_found: "Keine Pakete gefunden",
      status: "Status",
      active: "Aktiv",
      on_hold: "Pausiert",
      de_activate: "Deaktiviert",
      no_content_items: "Anzahl Inhalte",
      created_on: "Erstellt am",
      price: "Preis",
      number_of_users: "Anzahl Nutzer",
      views: "Aufrufe",
      rating: "Bewertung",
      status_change: "Status erfolgreich geändert",
      error_status_change: "Problem bei Änderung des Status aufgetreten",
      title: "Titel",
      image: "Bild",
      description: "Beschreibung",
      no_of_content: "Anzahl Inhalte",
      no_of_users: "Anzahl Nutzer",
      draft: "Entwurf",
      rejected: "Abgelehnt",
      under_verification: "In Prüfung",
      move_to_motivation: "Ändere in Motivation",
      move_message: "Möchtest du die Art des Paketes wirklich ändern?",
      move_message1: "in Motivation?",
      cancel: "ABBRECHEN",
      ok: "Ok",
      create_know_how: "Know-How erstellen",
      success_move: "Erfolgreich zu Motivation verschoben",
      error_move: "Problem aufgetreten beim Verschieben des Paketes",
      create_campaign: "Kampagne erstellen",
      budget: "Budget",
      add_member_error:
        "Im Unternehmen gibt es bereits einen Nutzer mit dieser E-Mail",
      add_member_error1: "E-Mail und Rolle werden benötigt",
      name: "Name",
      email: "E-Mail",
      role: "Rolle",
      role_updation_failed: "Rollenupdate fehlgeschlagen",
      remove_user: "Nutzerlöschen fehlgeschlagen",
      remove_message:
        "Du kannst Dich selbst nicht aus dem Unternehmen entfernen",
      add_team_members: "Teammitglied hinzufügen",
      email_label: "E-Mail eingeben um Nutzer hinzuzufügen",
      admin: "Admin",
      user: "Nutzer",
      add: "Hinzufügen",
      pending: "Ausstehend",
      search: "Suche...",
      clear: "Leeren",
      no_member_found: "Kein Mitglied gefunden",
      dialog: "Bist du sicher, dass du die ausgewählte",
      role_from: "Rolle von",
      to: "in ändern möchtest",
      delete_user:
        "Bist du sicher, dass du den ausgewählten Nutzer löschen möchtest",
      delete_user1: "aus dem Team",
      active_list: "Liste Aktiv",
      rejected_list: "Liste Abgelehnt",
      go_to_company_profile: "Gehe zum Unternehmensprofil",
      change_campaign_price: "Ändere Kampagnenpreis",
      hold_message:
        "Bist Du sicher, dass Du dieses Unternehmen pausieren möchtest",
      reason: "Grund",
      reason_placeholder: "Bitte Grund für die Pause angeben",
      reason_error: "Grund wird benötigt",
      campaign_dialog: "Ändere Kampagnenpreis für gewähltes Unternehmen",
      campaign_price: "Kampagnenpreis",
      campaign_price_placeholder: "Bitte Kampagnenpreis eingeben",
      campaign_price_error: "Kampagnenpreis wird benötigt",
      company_name: "Name des Unternehmens",
      company_address: "Adresse des Unternehmens",
      user_name: "Name des Nutzers",
      user_email: "E-Mail des Nutzers",
      no_company_found: "Kein Unternehmen gefunden",
      accept_company: "Unternehmen akzeptieren",
      rejected_reason: "Ablehnungsgrund",
      no_rejected_company_found: "Kein abgelehntes Unternehmen gefunden",
      accept_caompany_dialog:
        "Bist du sicher, dass du dieses Unternehmen akzeptieren möchtest",
      deactive: "Deaktiviert",
      team_details: "Teamdetails",
      network_error: "Netzwerkfehler",
      pending_list: "Liste Ausstehend",
      all: "Alle",
      owner_name: "Name Eigentümer",
      company: "Unternehmen",
      view_package: "Paket ansehen",
      accept_pacakge: "Paket akzeptieren",
      reject_package: "Paket abgelehnt",
      accept_reject_dialog: "Bist Du sicher, Du möchtest",
      accept: "akzeptieren",
      reject: "ablehnen",
      this_pacakge: "dieses Paket",
      reason_placeholder1: "Bitte gebe den Ablehnungsgrund an",
      on_hold_dialog: "Bist Du sicher, dass dieses Paket pausieren möchtest",
      accept_package_dialog:
        "Bist Du sicher, dass Du dieses Paket akzeptieren möchtest",
      package_details: "Paketdetails",
      package_content: "Paketinhalt",
      back: "Zurück",
      view_content: "Inhalt ansehen",
      content: "Inhalt",
      action: "Aktion",
      no_content_found: "Kein Inhalt gefunden",
      close: "Schließen",
      file_upload_failed: "Upload fehlgeschlagen",
      profile: "Profil",
      error_image:
        "Fehler beim Upload des Bildes, bitte versuche es später erneut",
      first_name: "Vorname",
      first_name_error: "Vorname wird benötigt",
      last_name: "Nachname",
      last_name_error: "Nachname wird benötigt",
      update: "Update",
      all_field_is_required: "Alle Felder werden benötigt",
      create_new_company: "Neues Unternehmen erstellen",
      company_name_error: "Name des Unternehmens wird benötigt",
      address: "Adresse",
      comapny_address_error: "Adresse des Unternehmens wird benötigt",
      create_company: "Unternehmen erstellen",
      your_company_name: "Dein Firmenname",
      main_address: "Hauptadresse",
      address_is_required: "Adresse wird benötigt",
      logo: "LOGO",
      create_package: "Paket erstellen",
      add_content: "Inhalt hinzufügen",
      publish_package: "Paket veröffentlichen",
      edit_motivation: "Motivation bearbeiten",
      motivation_create_success: "Motivation erfolgreich erstellt",
      motivation_create_error: "Problem beim Erstellen der Motivation",
      motivation_update_success: "Motivation erfolgreich aktualisiert",
      motivation_update_error:
        "Problem bei der Aktualisierung der Motivation aufgetreten",
      content_add_success: "Inhalt erfolgreich hinzugefügt",
      content_add_error: "Problem beim Hinzufügen des Inhalts aufgetreten",
      content_update_success: "Inhalt erfolgreich bearbeitet",
      content_update_error:
        "Problem bei der Bearbeitung des Inhalts aufgetreten",
      content_delete_success: "Inhalt erfolgreich gelöscht",
      content_delete_error: "Problem beim Löschen des Inhalts aufgetreten",
      motivation_publish_success: "Motivation erfolgreich veröffentlicht",
      motivation_publish_error:
        "Problem bei der Veröffentlichung der Motivation aufgetreten",
      category_is_required: "Kategorie wird benötigt",
      image_is_required: "Bild wird benötigt",
      image_error: "Bilddatei wird benötigt",
      title_placeholder: "Bitte Titel hier eingeben",
      title_Error: "Titel wird benötigt",
      description_placeholder: "Bitte Beschreibung eingeben",
      description_error: "Beschreibung wird benötigt",
      characters: "Zeichen",
      category: "Kategorie",
      first_check: "Füge Firmennamen und Anzahl der Inhalte in den Footer ein",
      second_check: "Füge Paketnamen und Logo in den Header ein",
      save: "Speichern",
      forward: "Weiter",
      next: "Nächste",
      add_content_error: "Bitte gib etwas ein, um Inhalt hinzuzufügen",
      edit: "Bearbeiten",
      delete: "Löschen",
      previous: "Vorherige",
      view: "Ansehen",
      add_userName: "Nutzernamen hinzufügen",
      add_habit: "Gewohnheit hinzufügen",
      add_goal: "Ziel hinzufügen",
      publish_motivation: "Motivation veröffentlichen",
      save_as_draft: "Entwurf speichern",
      publish_campaign: "Kampagne veröffentlichen",
      edit_campaign: "Kampagne bearbeiten",
      campaign_create_success: "Kampagne erfolgreich erstellt",
      campaign_create_error: "Problem beim Erstellen der Kampagne aufgetreten",
      campaign_update_success: "Kampagne erfolgreich bearbeitet",
      campaign_update_error:
        "Problem bei der Aktualisierung der Kampagne aufgetreten",
      likes: "Likes",
      company_introduction_link: "Firmenvorstellung Link",
      company_bio: "Firmen Bio",
      wallet: "Geldbörse",
      redeem_yeap_coin: "Yeap-Münze einlösen",
      total_revenue: "Gesamteinnahmen",
      total_withdraw: "Gesamtabhebung",
      current_balance: "Aktuelles Guthaben",
      paypal_transfer: "",
      bank_transfer: "",
      transaction_report: "",
      rate_settings: "",
      past_withdrawal: "",
      payment_method: "",
      redeem_coin: "",
      current_exchange_rate: "",
      add_payment_method: "",
      withdraw_amount: "",
      amount_placeholder: "",
      error_amount: "",
      error_amount1: "",
      transaction_type: "",
      transaction_error: "",
      redeem: "",
      date: "",
      amount: "",
      not_found_transaction: "",
      swift: "",
      swift_error: "",
      iban: "",
      iban_no: "",
      iban_error: "",
      name_on_account: "",
      name_error: "",
      city_state: "",
      city_error: "",
      country: "",
      country_error: "",
      phone_number: "",
      phone_error: "",
      check_payment: "",
      paypal: "",
      email_address: "",
      paypal_email: "",
      email_error1: "",
      receiver: "",
      info: "",
      bank: "",
      approved: "",
      processing: "",
      completed: "",
      approve: "",
      process: "",
      complete: "",
      show_bank_info: "",
      transaction: "",
      payout_exchange_rate: "",
      payout_error: "",
      purchase_exchange_rate: "",
      purchase_error: "",
      report_history: "Verlauf melden",
      no_report_issue_found: "Kein Berichtsproblem gefunden",
      reported_content: "Gemeldeter Inhalt",
    },
  },
  it: {
    translations: {
      campagin_publish_success: "Pubblica con successo la campagna",
      campaign_publish_error:
        "Si verifica un problema durante la pubblicazione della campagna",
      target_user: "N. di utenti target",
      target_user_placeholder:
        "Si prega di inserire il numero dell'utente di destinazione",
      target_user_error: "È richiesto il numero di utenti di destinazione",
      price_is_required: "Il prezzo è richiesto",
      edit_know_how: "Modifica know-how",
      know_how_create_success: "Creato con successo il know-how",
      know_how_create_error:
        "Si verificano problemi durante la creazione del know-how",
      know_how_update_success: "Aggiorna con successo il Knowhow",
      know_how_update_error:
        "Si verificano problemi durante l'aggiornamento del Knowhow",
      know_how_publish_success: "Pubblica con successo il Knowhow",
      know_how_publish_error:
        "Si verificano problemi durante la pubblicazione del Knowhow",
      publish_know_how: "Pubblica know-how",
      profile_created_successfully: "Profilo creato con successo",
      continue: "Continua",
      complete_profile: "Profilo completo",
      join_company:
        "Ti piacerebbe entrare a far parte dell'azienda che ti ha invitato?",
      please_select_the_company: "Si prega di selezionare l'azienda",
      create_a_new_company: "Crea una nuova azienda",
      or: "O",
      select_company: "Seleziona un'azienda per accedere",
      log_in: "Accesso",
      email_error: "inserire una email valida, grazie",
      password: "Parola d'ordine",
      password_error: "E 'richiesta la password",
      forgot_password: "Ha dimenticato la password ?",
      login: "Login",
      doesnot_account: "Non hai ancora un account?",
      sign_up: "Iscrizione",
      google: "Google",
      password_error1: "La password deve essere di 8 caratteri",
      confirm_password: "Conferma password",
      confirm_password_error: "Conferma password deve essere di 8 caratteri",
      password_error2:
        "La password e la password di conferma devono essere uguali",
      already_account: "Hai già un account?",
      sign_in: "Registrazione",
      on_hold_reason1: "La tua azienda è in attesa a causa di",
      on_hold_reason2: "si prega di contattare l'amministratore",
      activate_company: "Ci vorranno circa 24 ore per attivare l'azienda.",
      home: "Casa",
      motivation: "Motivazione",
      know_how: "Competenza",
      campaigns: "Campagne",
      support: "Supporto",
      team: "Squadra",
      companies: "Aziende",
      packages: "Pacchi",
      settings: "Impostazioni",
      about_us: "Chi siamo",
      logout: "Disconnettersi",
      language_tooltip: "seleziona la tua lingua",
      analysis: "Analisi",
      total_revenues: "Ricavi totali",
      total_customers: "Totale clienti",
      total_distributions: "Distribuzioni totali",
      total_likes: "Mi piace totali",
      total_companies: "Compagnie totali",
      monthly: "Mensile",
      weekly: "settimanalmente",
      daily: "Quotidiano",
      revenue: "Reddito",
      customers: "Clienti",
      distributions: "Distribuzioni",
      tile_view: "Vista piastrellata",
      list_view: "Visualizzazione elenco",
      create_motivation: "Crea motivazione",
      no_package_found: "Nessun pacchetto trovato",
      status: "Stato",
      active: "Attivo",
      on_hold: "In attesa",
      de_activate: "Disattiva",
      no_content_items: "Numero di elementi di contenuto",
      created_on: "Creato",
      price: "Prezzo",
      number_of_users: "Numero di utenti",
      views: "Visualizzazioni",
      rating: "Valutazione",
      status_change: "Modificare lo stato con successo",
      error_status_change:
        "Si verifica un problema durante la modifica dello stato",
      title: "Titolo",
      image: "Immagine",
      description: "Descrizione",
      no_of_content: "N. di contenuto",
      no_of_users: "N. di Utenti",
      draft: "Brutta copia",
      rejected: "Respinto",
      under_verification: "In fase di verifica",
      move_to_motivation: "Passa alla motivazione",
      move_message: "Sei sicuro di voler spostare questo pacchetto",
      move_message1: "alla motivazione?",
      cancel: "ANNULLA",
      ok: "Ok",
      create_know_how: "Crea know-how",
      success_move: "Passare con successo alla motivazione",
      error_move:
        "Si verifica un problema durante lo spostamento del pacchetto",
      create_campaign: "Crea campagna",
      budget: "Bilancio",
      add_member_error:
        "L'utente con questo ID e-mail è già presente in azienda",
      add_member_error1: "E-mail e ruolo sono obbligatori",
      name: "Nome",
      email: "E-mail",
      role: "Ruolo",
      role_updation_failed: "Aggiornamento del ruolo utente non riuscito",
      remove_user: "rimozione utente non riuscita",
      remove_message: "Non puoi rimuovere te stesso da questa azienda",
      add_team_members: "Aggiungi membri del team",
      email_label: "Inserisci l'ID e-mail per aggiungere l'utente",
      admin: "amministratore",
      user: "Utente",
      add: "Aggiungere",
      pending: "In attesa di",
      search: "Ricerca…",
      clear: "Chiaro",
      no_member_found: "Nessun membro trovato",
      dialog: "Sei sicuro di voler cambiare questo selezionato",
      role_from: "ruolo da",
      to: "a",
      delete_user: "Sei sicuro di voler eliminare questo utente selezionato",
      delete_user1: "dalla squadra",
      active_list: "Elenco Attivo",
      rejected_list: "Elenco rifiutato",
      go_to_company_profile: "Vai al profilo dell'azienda",
      change_campaign_price: "Modifica il prezzo della campagna",
      hold_message: "Sei sicuro di voler tenere in attesa questa compagnia",
      reason: "Motivo",
      reason_placeholder: "Inserisci il motivo della sospensione",
      reason_error: "La ragione è richiesta",
      campaign_dialog:
        "Modifica il prezzo della campagna dell'azienda selezionata",
      campaign_price: "Prezzo della campagna",
      campaign_price_placeholder: "Inserisci il prezzo della campagna",
      campaign_price_error: "Il prezzo della campagna è obbligatorio",
      company_name: "Nome della ditta",
      company_address: "indirizzo aziendale",
      user_name: "Nome utente",
      user_email: "Email utente",
      no_company_found: "Nessuna azienda trovata",
      accept_company: "Accetta compagnia",
      rejected_reason: "Motivo rifiutato",
      no_rejected_company_found: "Nessuna azienda rifiutata trovata",
      accept_caompany_dialog: "Sei sicuro di voler accettare questa compagnia",
      deactive: "Disattivo",
      team_details: "Dettagli della squadra",
      network_error: "Errore di rete",
      pending_list: "Lista in attesa",
      all: "Tutto",
      owner_name: "Nome del proprietario",
      company: "Azienda",
      view_package: "Visualizza pacchetto",
      accept_pacakge: "Accetta Pacchetto",
      reject_package: "Rifiuta pacchetto",
      accept_reject_dialog: "Sei sicuro di volere?",
      accept: "accettare",
      reject: "rifiutare",
      this_pacakge: "questo pacchetto",
      reason_placeholder1: "Inserisci il motivo del rifiuto",
      on_hold_dialog: "Sei sicuro di voler tenere questo pacchetto",
      accept_package_dialog: "Sei sicuro di voler accettare questo pacchetto",
      package_details: "Dettagli del pacchetto",
      package_content: "Contenuto del pacco",
      back: "Di ritorno",
      view_content: "Visualizza contenuto",
      content: "Contenuto",
      action: "Azione",
      no_content_found: "Nessun contenuto trovato",
      close: "Chiudere",
      file_upload_failed: "Caricamento file non riuscito",
      profile: "Profilo",
      error_image:
        "Errore Durante il caricamento dell'immagine, riprovare tra qualche tempo",
      first_name: "Nome di battesimo",
      first_name_error: "Il nome è obbligatorio",
      last_name: "Cognome",
      last_name_error: "Il cognome è obbligatorio",
      update: "Aggiornare",
      all_field_is_required: "Tutti i campi sono obbligatori",
      create_new_company: "Crea una nuova azienda",
      company_name_error: "Il nome dell'azienda è obbligatorio",
      address: "Indirizzo",
      comapny_address_error: "L'indirizzo dell'azienda è obbligatorio",
      create_company: "Crea azienda",
      your_company_name: "Il nome della tua azienda",
      main_address: "Indirizzo principale",
      address_is_required: "L'indirizzo è obbligatorio",
      logo: "LOGO",
      create_package: "Crea pacchetto",
      add_content: "Aggiungi contenuto",
      publish_package: "Pubblica pacchetto",
      edit_motivation: "Modifica motivazione",
      motivation_create_success: "Creata con successo la motivazione",
      motivation_create_error:
        "Si verificano problemi durante la creazione della motivazione",
      motivation_update_success: "Aggiorna con successo la motivazione",
      motivation_update_error:
        "Si verificano problemi durante l'aggiornamento della motivazione",
      content_add_success: "Contenuto aggiunto con successo",
      content_add_error:
        "Si verificano problemi durante l'aggiunta del contenuto",
      content_update_success: "Modifica con successo il contenuto",
      content_update_error:
        "Si verifica un problema durante la modifica del contenuto",
      content_delete_success: "Eliminato il contenuto con successo",
      content_delete_error:
        "Si verificano problemi durante l'eliminazione del contenuto",
      motivation_publish_success: "Pubblica con successo la motivazione",
      motivation_publish_error:
        "Si verificano problemi durante la pubblicazione della motivazione",
      category_is_required: "La categoria è obbligatoria",
      image_is_required: "L'immagine è richiesta",
      image_error: "Il file immagine è richiesto",
      title_placeholder: "Si prega di inserire il titolo",
      title_Error: "Il titolo è obbligatorio",
      description_placeholder: "Si prega di inserire la descrizione",
      description_error: "La descrizione è richiesta",
      characters: "Personaggi",
      category: "Categoria",
      first_check:
        "Aggiungi il nome e il numero dell'azienda dell'elemento al piè di pagina del contenuto",
      second_check:
        "Aggiungi il nome e il logo del pacchetto all'intestazione del contenuto'9m",
      save: "Salva",
      forward: "Inoltrare",
      next: "Prossimo",
      add_content_error: "Inserisci qualcosa per aggiungere contenuto",
      edit: "Modificare",
      delete: "Elimina",
      previous: "Precedente",
      view: "Visualizzazione",
      add_userName: "Aggiungi nome utente",
      add_habit: "Aggiungi abitudine",
      add_goal: "Aggiungi obiettivo",
      publish_motivation: "Pubblica motivazione",
      save_as_draft: "Salva come bozza",
      publish_campaign: "Pubblica campagna",
      edit_campaign: "Modifica campagna",
      campaign_create_success: "Campagna creata con successo",
      campaign_create_error:
        "Si verifica un problema durante la creazione della campagna",
      campaign_update_success: "Campagna aggiornata con successo",
      campaign_update_error:
        "Si verifica un problema durante l'aggiornamento della campagna",
      likes: "Piace",
      company_introduction_link: "Collegamento all'introduzione dell'azienda",
      company_bio: "Biografia aziendale",
      wallet: "Portafoglio",
      redeem_yeap_coin: "Riscatta la moneta Yeap",
      total_revenue: "Entrate totali",
      total_withdraw: "Prelievo totale",
      current_balance: "Bilancio corrente",
      paypal_transfer: "Bonifico Paypal",
      bank_transfer: "Trasferimento bancario",
      transaction_report: "Rapporto di transazione",
      rate_settings: "Impostazioni tariffarie",
      past_withdrawal: "Prelievo passato",
      payment_method: "Metodo di pagamento",
      redeem_coin: "Riscatta la moneta",
      current_exchange_rate: "Tasso di cambio del pagamento attuale:",
      add_payment_method:
        "Aggiungi qualsiasi metodo di pagamento per riscattarlo.",
      withdraw_amount: "Prelevare l'importo",
      amount_placeholder: "Inserisci l'importo che devi prelevare",
      error_amount: "È necessario prelevare l'importo",
      error_amount1: "Non hai abbastanza equilibrio",
      transaction_type: "Tipo di transazione",
      transaction_error: "Il tipo di transazione è obbligatorio",
      redeem: "RISCATTARE",
      date: "Data",
      amount: "Quantità",
      not_found_transaction: "Nessuna transazione trovata",
      swift: "SWIFT/BIC",
      swift_error: "È richiesto il codice Swift/BIC",
      iban: "IBAN",
      iban_no: "Numero IBAN",
      iban_error: "È richiesto l'IBAN",
      name_on_account: "Nome sull'account",
      name_error: "Nome sull'account è richiesto",
      city_state: "Città e Stato/Provincia",
      city_error: "La città è obbligatoria",
      country: "Paese",
      country_error: "Paese è obbligatorio",
      phone_number: "Numero di telefono",
      phone_error: "Il numero di telefono è richiesto",
      check_payment:
        "Dichiaro di essere il proprietario e di avere la piena autorizzazione a questo conto bancario.",
      paypal: "Paypal",
      email_address: "Indirizzi e-mail",
      paypal_email: "Inserisci il tuo ID e-mail registrato PayPal",
      email_error1: "L'e-mail è richiesta",
      receiver: "Ricevitore",
      info: "Informazioni",
      bank: "Banca",
      approved: "Approvato",
      processing: "in lavorazione",
      completed: "Completato",
      approve: "Approvare",
      process: "Processi",
      complete: "Completare",
      show_bank_info: "Mostra informazioni bancarie",
      transaction: "Transazione",
      payout_exchange_rate: "Tasso di cambio di pagamento",
      payout_error:
        "Il tasso di cambio del pagamento deve essere maggiore di 0",
      purchase_exchange_rate: "Acquisto tasso di cambio",
      purchase_error:
        "Il tasso di cambio dell'acquisto deve essere maggiore di 0",
      report_history: "Segnala la cronologia",
      no_report_issue_found: "Nessun problema riscontrato nel rapporto",
      reported_content: "Contenuto segnalato",
    },
  },
  es: {
    translations: {
      campagin_publish_success: "Publicar con éxito la campaña",
      campaign_publish_error: "Ocurrió un problema al publicar la campaña",
      target_user: "Número de usuarios objetivo",
      target_user_placeholder: "Ingrese el número de usuario objetivo",
      target_user_error: "Se requiere el número de usuario objetivo",
      price_is_required: "Se requiere precio",
      edit_know_how: "Editar conocimientos",
      know_how_create_success: "Creó con éxito el Know-how",
      know_how_create_error: "Ocurrió un problema al crear el know-how",
      know_how_update_success: "Actualizar con éxito el Knowhow",
      know_how_update_error: "Ocurrió un problema al actualizar el Knowhow",
      know_how_publish_success: "Publicar con éxito el Knowhow",
      know_how_publish_error: "Ocurrió un problema al publicar el Knowhow",
      publish_know_how: "Publicar conocimientos",
      profile_created_successfully: "Perfil creado con éxito",
      continue: "Continuar",
      complete_profile: "Perfil Completo",
      join_company: "¿Te gustaría unirte a la empresa que te invitó?",
      please_select_the_company: "Seleccione la empresa",
      create_a_new_company: "Crear una nueva empresa",
      or: "O",
      select_company: "Seleccione una empresa para iniciar sesión",
      log_in: "Iniciar sesión",
      email_error: "Por favor introduzca un correo electrónico válido",
      password: "Clave",
      password_error: "se requiere contraseña",
      forgot_password: "Has olvidado tu contraseña ?",
      login: "Acceso",
      doesnot_account: "¿Aún no tienes cuenta?",
      sign_up: "Inscribirse",
      google: "Google",
      password_error1: "La contraseña debe tener 8 caracteres",
      confirm_password: "Confirmar contraseña",
      confirm_password_error: "Confirmar contraseña debe tener 8 caracteres",
      password_error2:
        "La contraseña y la contraseña de confirmación deben ser las mismas",
      already_account: "¿Ya tienes cuenta?",
      sign_in: "Iniciar sesión",
      on_hold_reason1: "Su empresa está en espera debido a",
      on_hold_reason2: "por favor contacte al administrador",
      activate_company: "Tomará alrededor de 24 horas para activar la empresa.",
      home: "Hogar",
      motivation: "Motivación",
      know_how: "Saber cómo",
      campaigns: "Campañas",
      support: "Apoyo",
      team: "Equipo",
      companies: "Compañías",
      packages: "Paquetes",
      settings: "Ajustes",
      about_us: "Sobre nosotros",
      logout: "Cerrar sesión",
      language_tooltip: "elige tu idioma",
      analysis: "Análisis",
      total_revenues: "Ingresos totales",
      total_customers: "Clientes totales",
      total_distributions: "Distribuciones Totales",
      total_likes: "Me gusta totales",
      total_companies: "Empresas Totales",
      monthly: "Mensual",
      weekly: "Semanalmente",
      daily: "Diariamente",
      revenue: "Ingresos",
      customers: "Clientes",
      distributions: "Distribuciones",
      tile_view: "Vista de mosaico",
      list_view: "Vista de la lista",
      create_motivation: "Crear Motivación",
      no_package_found: "No se encontró ningún paquete",
      status: "Estado",
      active: "Activo",
      on_hold: "En espera",
      de_activate: "Desactivar",
      no_content_items: "Número de elementos de contenido",
      created_on: "Creado en",
      price: "Precio",
      number_of_users: "Número de usuarios",
      views: "Puntos de vista",
      rating: "Clasificación",
      status_change: "Cambiar con éxito el estado",
      error_status_change: "El problema ocurre mientras cambia el estado",
      title: "Título",
      image: "Imagen",
      description: "Descripción",
      no_of_content: "Nº de contenido",
      no_of_users: "Nº de Usuarios",
      draft: "Reclutar",
      rejected: "Rechazado",
      under_verification: "En verificación",
      move_to_motivation: "Mover a la motivación",
      move_message: "¿Seguro que quieres mover este paquete?",
      move_message1: "a la motivación?",
      cancel: "CANCELAR",
      ok: "OK",
      create_know_how: "Crear conocimientos",
      success_move: "Pasando con éxito a la motivación.",
      error_move: "Ocurrió un problema al mover el paquete",
      create_campaign: "Crear campaña",
      budget: "Presupuesto",
      add_member_error:
        "El usuario con esta identificación de correo electrónico ya está allí en la empresa",
      add_member_error1: "Se requiere correo electrónico y rol",
      name: "Nombre",
      email: "Correo electrónico",
      role: "Role",
      role_updation_failed: "La actualización de la función de usuario falló",
      remove_user: "eliminar usuario fallido",
      remove_message: "No puede eliminar su auto de esta empresa",
      add_team_members: "Agregar miembros del equipo",
      email_label:
        "Ingrese la identificación de correo electrónico para agregar un usuario",
      admin: "Administración",
      user: "Usuario",
      add: "Agregar",
      pending: "Pendiente",
      search: "Búsqueda…",
      clear: "Claro",
      no_member_found: "Ningún miembro encontrado",
      dialog: "¿Seguro que quieres cambiar este seleccionado?",
      role_from: "papel de",
      to: "a",
      delete_user:
        "¿Está seguro de que desea eliminar este usuario seleccionado?",
      delete_user1: "del equipo",
      active_list: "Lista activa",
      rejected_list: "Lista de Rechazados",
      go_to_company_profile: "Ir al perfil de la empresa",
      change_campaign_price: "Cambiar precio de campaña",
      hold_message: "¿Estás seguro de que deseas poner en espera esta empresa?",
      reason: "Razón",
      reason_placeholder: "Ingrese el motivo de la espera",
      reason_error: "Se requiere razón",
      campaign_dialog:
        "Cambiar el precio de la campaña de la empresa seleccionada",
      campaign_price: "Precio de campaña",
      campaign_price_placeholder:
        "Por favor, introduzca el precio de la campaña",
      campaign_price_error: "El precio de la campaña es obligatorio",
      company_name: "nombre de empresa",
      company_address: "Dirección de la empresa",
      user_name: "Nombre de usuario",
      user_email: "Correo electrónico del usuario",
      no_company_found: "No se encontró ninguna empresa",
      accept_company: "Aceptar Empresa",
      rejected_reason: "Motivo del rechazo",
      no_rejected_company_found: "No se encontró ninguna empresa rechazada",
      accept_caompany_dialog: "¿Seguro que quieres aceptar esta empresa?",
      deactive: "Desactivado",
      team_details: "Detalles del equipo",
      network_error: "Error de red'[39m",
      pending_list: "Lista de pendientes",
      all: "Todos",
      owner_name: "Nombre del dueño",
      company: "Compañía",
      view_package: "Ver paquete",
      accept_pacakge: "Aceptar paquete",
      reject_package: "Rechazar paquete",
      accept_reject_dialog: "Seguro que quieres",
      accept: "aceptar",
      reject: "rechazar",
      this_pacakge: "este paquete",
      reason_placeholder1: "Por favor ingrese el motivo del rechazo",
      on_hold_dialog: "¿Está seguro de que desea retener este paquete?",
      accept_package_dialog:
        "¿Estás seguro de que quieres aceptar este paquete?",
      package_details: "Detalles del paquete",
      package_content: "Contenido del paquete",
      back: "atrás",
      view_content: "Ver contenido",
      content: "Contenido",
      action: "Acción",
      no_content_found: "No se encontró contenido",
      close: "Cerca",
      file_upload_failed: "Carga de archivo fallida",
      profile: "Perfil",
      error_image:
        "Error al cargar la imagen, intente nuevamente en algún momento",
      first_name: "Primer nombre",
      first_name_error: "Se requiere el primer nombre",
      last_name: "Apellido",
      last_name_error: "Se requiere apellido",
      update: "Actualizar",
      all_field_is_required: "Todo el campo es requerido",
      create_new_company: "Crear nueva empresa",
      company_name_error: "El nombre de la empresa es obligatorio",
      address: "Dirección",
      comapny_address_error: "Se requiere la dirección de la empresa",
      create_company: "Crear Empresa",
      your_company_name: "El nombre de tu compañía",
      main_address: "dirección principal",
      address_is_required: "La dirección es necesaria",
      logo: "LOGO",
      create_package: "Crear paquete",
      add_content: "Agregar contenido",
      publish_package: "Publicar paquete",
      edit_motivation: "Editar motivación",
      motivation_create_success: "Creó con éxito la motivación",
      motivation_create_error: "Ocurrió un problema al crear la motivación",
      motivation_update_success: "Actualice con éxito la motivación",
      motivation_update_error:
        "Ocurrió un problema al actualizar la motivación",
      content_add_success: "Contenido agregado con éxito",
      content_add_error: "Ocurrió un problema al agregar el contenido",
      content_update_success: "Editar con éxito el contenido",
      content_update_error: "El problema ocurre mientras se edita el contenido",
      content_delete_success: "Eliminado con éxito el contenido",
      content_delete_error: "Ocurrió un problema al eliminar el contenido",
      motivation_publish_success: "Publicar con éxito la motivación",
      motivation_publish_error: "Ocurrió un problema al publicar la Motivación",
      category_is_required: "la categoria es requerida",
      image_is_required: "La imagen es requerida",
      image_error: "Se requiere archivo de imagen",
      title_placeholder: "Por favor ingrese el título",
      title_Error: "Se requiere título",
      description_placeholder: "Por favor Ingrese la descripción",
      description_error: "Se requiere descripción",
      characters: "Caracteres",
      category: "Categoría",
      first_check:
        "Agregue el nombre de la empresa y el número del elemento al pie de página del contenido",
      second_check:
        "Agregue el nombre y el logotipo del paquete al encabezado del contenido",
      save: "Ahorrar",
      forward: "Delantero",
      next: "próximo",
      add_content_error: "Por favor ingrese algo para agregar contenido",
      edit: "Editar",
      delete: "Borrar",
      previous: "Anterior",
      view: "Vista",
      add_userName: "Añadir nombre de usuario",
      add_habit: "Añadir hábito",
      add_goal: "Añadir objetivo",
      publish_motivation: "Publicar Motivación",
      save_as_draft: "Guardar como borrador",
      publish_campaign: "Publicar Campaña",
      edit_campaign: "Editar campaña",
      campaign_create_success: "Campaña creada con éxito",
      campaign_create_error: "Ocurrió un problema al crear la campaña",
      campaign_update_success: "Campaña actualizada con éxito",
      campaign_update_error: "Ocurrió un problema al actualizar la campaña",
      likes: "Gustos",
      company_introduction_link: "Enlace de introducción de la empresa",
      company_bio: "Biografía de la empresa",
      wallet: "Cartera",
      redeem_yeap_coin: "Canjear moneda Yeap",
      total_revenue: "Los ingresos totales",
      total_withdraw: "Retiro Total",
      current_balance: "Saldo actual",
      paypal_transfer: "Transferencia de PayPal",
      bank_transfer: "Transferencia bancaria",
      transaction_report: "Reporte de transacción",
      rate_settings: "Ajustes de tarifas",
      past_withdrawal: "Retiro pasado",
      payment_method: "Método de pago",
      redeem_coin: "Canjear moneda",
      current_exchange_rate: "Tasa de cambio de pago actual:",
      add_payment_method: "Agrega cualquier método de pago para canjearlo.",
      withdraw_amount: "Retirar cantidad",
      amount_placeholder: "Introduce la cantidad que tienes que retirar",
      error_amount: "Se requiere retirar el monto",
      error_amount1: "No tienes saldo suficiente",
      transaction_type: "tipo de transacción",
      transaction_error: "El tipo de transacción es obligatorio",
      redeem: "REDIMIR",
      date: "Fecha",
      amount: "Monto",
      not_found_transaction: "No se encontró ninguna transacción",
      swift: "SWIFT BIC",
      swift_error: "Se requiere el código Swift/BIC",
      iban: "IBAN",
      iban_no: "Número IBAN",
      iban_error: "Se requiere IBAN",
      name_on_account: "Nombre en la cuenta",
      name_error: "Se requiere nombre en la cuenta",
      city_state: "Ciudad y Estado/Provincia",
      city_error: "Ciudad es requerida",
      country: "País",
      country_error: "El país es obligatorio",
      phone_number: "Número de teléfono",
      phone_error: "Se requiere número de teléfono",
      check_payment:
        "Doy fe de que soy el titular y tengo plena autorización a esta cuenta bancaria.",
      paypal: "PayPal",
      email_address: "Dirección de correo electrónico",
      paypal_email: "Ingrese su ID de correo electrónico registrado en PayPal",
      email_error1: "correo electronico es requerido",
      receiver: "Receptor",
      info: "Información",
      bank: "Banco",
      approved: "Aprobado",
      processing: "Procesando",
      completed: "Terminado",
      approve: "Aprobar",
      process: "Proceso",
      complete: "Completo",
      show_bank_info: "Mostrar información bancaria",
      transaction: "Transacción",
      payout_exchange_rate: "Tipo de cambio de pago",
      payout_error: "La tasa de cambio de pago debe ser mayor que 0",
      purchase_exchange_rate: "Tipo de cambio de compra",
      purchase_error: "La tasa de cambio de compra debe ser mayor que 0",
      report_history: "Historial de informes",
      no_report_issue_found: "No se encontró ningún problema de informe",
      reported_content: "Contenido denunciado",
    },
  },
};
