import * as actionTypes from "../Action/ActionType";

const initstate = {
  loading: false,
  payoutExchangeRate: 0,
  purchaseExchangeRate: 0,
  message: "",
  error: "",
};

const rate = (state = initstate, action) => {
  switch (action.type) {
    case actionTypes.GET_RATE_SUCCESS:
      return {
        ...state,
        payoutExchangeRate: action?.payload?.payoutExchangeRate,
        purchaseExchangeRate: action?.payload?.purchaseExchangeRate,
        loading: false,
      };
    case actionTypes.GET_RATE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_RATE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.ON_EDIT_RATE_SUCCESS:
      console.log("action", action);
      return {
        ...state,
        payoutExchangeRate: action?.payload?.payoutExchangeRate,
        purchaseExchangeRate: action?.payload?.purchaseExchangeRate,
        message: action?.message,
        loading: false,
      };
    case actionTypes.ON_EDIT_RATE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ON_EDIT_RATE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.ON_EDIT_RATE_AFTER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "",
        error: "",
      };
    default:
      return state;
  }
};

export default rate;
