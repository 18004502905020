import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RESET_ON_EDIT_PROFILE_ERROR } from "../../Redux/Action/ActionType";
import { onEditProfile } from "../../Redux/Action/Authaction";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import { message } from "antd";
import profile from "../../Assets/profile1.jpg";
import EditIcon from "@mui/icons-material/Edit";
import axios from "../../axios";
import Progress from "../../Component/Progress/Progress";
import { useTranslation } from "react-i18next";
import "./Profile.css";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#ff6600",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ff6600",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#1490cc",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ff6600",
    },
  },
});

const Profile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    image: "",
    id: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { user, loading, error, updateMessage, sas } = useSelector(
    (state) => state.Auth
  );
  const [selectedFile, setSelectedFile] = useState();
  const [imageUrl, setImageUrl] = useState(undefined);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setImageUrl(objectUrl);
    }
  }, [selectedFile]);

  useEffect(() => {
    setIsLoading(true);
    if (Object.keys(user).length !== 0) {
      setUserData({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        image: user.image,
        id: user._id,
      });
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    setIsLoading(false);
  }, [user]);

  useEffect(() => {
    if (updateMessage !== "") {
      message.success(updateMessage);
      dispatch({ type: RESET_ON_EDIT_PROFILE_ERROR });
    }
  }, [updateMessage]);

  useEffect(() => {
    if (error !== "") {
      message.error(error);
      dispatch({ type: RESET_ON_EDIT_PROFILE_ERROR });
    }
  }, [error]);

  const validate = () => {
    if (userData?.firstName === "" || userData?.lastName === "") {
      return false;
    } else {
      return true;
    }
  };

  const uploadFile = () => {
    if (selectedFile.length !== 0) {
      const form = new FormData();
      form.append("file", selectedFile);
      setIsLoading(true);
      axios
        .post("/uploading", form)
        .then((res) => {
          dispatch(
            onEditProfile({
              ...userData,
              image: res.data.blobName,
            })
          );
          setIsLoading(false);
        })
        .catch((e) => {
          console.error(e);
          message.error(t("translations:error_image"));
          setIsLoading(false);
        });
    }
  };

  const onSubmit = () => {
    setClicked(true);
    const isValid = validate();
    if (isValid) {
      if (selectedFile) {
        uploadFile();
      } else {
        dispatch(onEditProfile({ ...userData }));
      }
    }
  };

  return isLoading || loading ? (
    <Progress />
  ) : (
    <div className="main-user-wrapper">
      <div className="profile">
        <div className="row w-100">
          <div className="col-sm-12 col-md-6 col-lg-5 ">
            <div className="profile-part">
              <div className="profile-img-wrapper">
                {" "}
                <img
                  className="profile-img"
                  alt="profile"
                  src={
                    !selectedFile && userData?.image === "t.ly/a321"
                      ? profile
                      : selectedFile
                      ? imageUrl
                      : `https://yeapportal.blob.core.windows.net/yeapportal/${userData?.image}?${sas}`
                  }
                />
                <div className="edit-part">
                  <input
                    type="file"
                    className="profile-input"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                    accept="image/*"
                    title=""
                  ></input>
                  <EditIcon />
                </div>
              </div>
            </div>
            <div className="user-data">
              {userData?.firstName.toUpperCase() +
                " " +
                userData?.lastName.toUpperCase()}
            </div>
          </div>

          <div className="align-items-center col-sm-12 col-md-6 col-lg-7">
            <div className="d-flex justify-content-center align-items-center">
              <h4 className="heading">{t("translations:profile")}</h4>
            </div>
            <div>
              <CssTextField
                value={userData?.firstName}
                label={t("translations:first_name")}
                type="text"
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
                className="w-100 mb-3"
                autoComplete="off"
                onChange={(e) =>
                  setUserData({ ...userData, firstName: e.target.value })
                }
                error={clicked && userData?.firstName === ""}
                helperText={
                  clicked && userData?.firstName === ""
                    ? t("translations:first_name_error")
                    : ""
                }
              />
            </div>
            <div>
              <CssTextField
                value={userData?.lastName}
                label={t("translations:last_name")}
                type="text"
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
                className="w-100 mb-3"
                autoComplete="off"
                onChange={(e) =>
                  setUserData({ ...userData, lastName: e.target.value })
                }
                error={clicked && userData?.lastName === ""}
                helperText={
                  clicked && userData?.lastName === ""
                    ? t("translations:last_name_error")
                    : ""
                }
              />
            </div>
            <div>
              <CssTextField
                value={userData?.email}
                label={t("translations:email")}
                type="email"
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
                className="w-100 mb-3"
                autoComplete="off"
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    email: e.target.value.toLowerCase(),
                  })
                }
                disabled={true}
              />
            </div>
            {/* <div>
              <TextField
                value={userData?.address?.toUpperCase()}
                label="Address"
                type="text"
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
                className="w-100 mb-3"
                autoComplete="off"
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    address: e.target.value.toLowerCase(),
                  })
                }
              />
            </div> */}
            {/* <div>
              <TextField
                value={userData?.company}
                label="Company"
                type="text"
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
                className="w-100 mb-3"
                autoComplete="off"
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    company: e.target.value,
                  })
                }
                disabled={user?.role === 'user'}
              />
            </div> */}
            <div className="d-flex justify-content-center mt-4">
              <button className="px-4 profile-button" onClick={onSubmit}>
                {t("translations:update")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
