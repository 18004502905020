import * as actionTypes from "../Action/ActionType";

const initstate = {
  allCompany: [],
  loading: false,
  error: "",
  updateMessage: "",
};

const allComapnies = (state = initstate, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_COMPANIES_SUCCESS:
      return {
        ...state,
        allCompany: action?.allCompany,
        loading: false
      };
    case actionTypes.GET_ALL_COMPANIES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ALL_COMPANIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.RESET_GET_ALL_COMPANIES_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
        updateMessage: "",
      };
    case actionTypes.ACCEPT_REJECT_COMPANY_SUCCESS:
      return {
        updateMessage: action?.message,
        loading: false,
        error: "",
        allCompany: state.allCompany?.map(comp=>{
          if(comp?._id === action?.data?.id){
            return {
              ...comp,
              onHold: action?.data?.onHold,
              onHoldReason: action?.data?.onHoldReason,
              campaignPrice: action?.data?.campaignPrice
            }
          }
          return comp;
        }),
      };
    case actionTypes.ACCEPT_REJECT_COMPANY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ACCEPT_REJECT_COMPANY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default allComapnies;
