import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Component/Header/Header";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { message } from "antd";
import Progress from "../../Component/Progress/Progress";
import axios from "../../axios";
import profile from "../../Assets/profile.svg";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import * as Authaction from "../../Redux/Action/Authaction";
import { useTranslation } from "react-i18next";
import "./CompleteProfile.css";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#ff6600",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ff6600",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#1490cc",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ff6600",
    },
  },
});

const CompleteProfile = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    company: "",
    address: "",
    companyBio: "",
    introductionLink: "",
    newCompany: "",
    companyId: "",
  });
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isShow, setShow] = useState(false);
  const [res, setRes] = useState({});
  const [newRes, setNewRes] = useState({});
  const [selectedFile, setSelectedFile] = useState();
  const [imageUrl, setImageUrl] = useState(undefined);
  const [imageError, setImageError] = useState("");
  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setImageUrl(objectUrl);
    }
  }, [selectedFile]);

  useEffect(() => {
    if (user?.company?.length !== 0) {
      setShow(true);
      setUserData({ ...userData, newCompany: "" });
    } else {
      setShow(false);
      setUserData({ ...userData, newCompany: "other" });
    }
  }, [user]);

  const onCreateNewCompany = (logo) => {
    setLoading(true);
    axios
      .post("/completeProfile", {
        id: user?._id,
        name: userData?.company,
        address: userData?.address,
        companyBio: userData?.companyBio,
        introductionLink: userData?.introductionLink,
        logo: logo,
      })
      .then((res) => {
        if (res.status === 200) {
          setDialogOpen(true);
          setLoading(false);
          setNewRes(res?.data);
        }
      })
      .catch((e) => {
        console.error(e);
        message.error({
          content: e.response.data.error,
          style: { zIndex: 10000000000, marginTop: "10vh" },
        });
        setLoading(false);
      });
  };

  const uploadFile = () => {
    if (selectedFile.length !== 0) {
      setLoading(true);
      const form = new FormData();
      form.append("file", selectedFile);
      axios
        .post("/uploading", form)
        .then((res) => {
          onCreateNewCompany(res.data.blobName);
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          message.error(t("translations:error_image"));
          setLoading(false);
        });
    }
  };

  const onCompleteProfile = async () => {
    setClicked(true);
    if (userData?.newCompany !== "other") {
      if (userData?.newCompany === "") {
        return;
      } else {
        setLoading(true);
        axios
          .post(`/getCompanyByName`, {
            name: userData?.newCompany,
            userId: user?._id,
          })
          .then(async (res) => {
            if (res?.status) {
              setRes(res?.data?.user);
              setDialogOpen(true);
              setLoading(false);
            }
          })
          .catch((e) => {
            message.error(e || t("translations:network_error"));
            setLoading(false);
          });
        setDialogOpen(true);
        setLoading(false);
      }
    } else {
      if (
        selectedFile &&
        userData?.company !== "" &&
        userData?.address !== ""
      ) {
        uploadFile();
      } else {
        setImageError(t("translations:all_field_is_required"));
      }
    }
  };

  const onCloseDialog = async () => {
    dispatch(
      Authaction.companyProfile({
        company: res,
      })
    );
    if (Object.keys(res).length !== 0) {
      localStorage.setItem(
        "company",
        JSON.stringify({
          company: res.company[0].name,
          companyId: res.company[0].id,
          role: res.company[0].role,
        })
      );
      dispatch(
        Authaction.setSelectedCompany({
          company: res.company[0].name,
          companyId: res.company[0].id,
          role: res.company[0].role,
        })
      );
    } else {
      localStorage.setItem(
        "company",
        JSON.stringify({
          company: newRes.company.name,
          companyId: newRes.company._id,
          role: "admin",
        })
      );
      dispatch(
        Authaction.setSelectedCompany({
          company: newRes.company.name,
          companyId: newRes.company._id,
          role: "admin",
        })
      );
    }
    history.push("/");
    window.location.reload();
  };

  return loading ? (
    <Progress />
  ) : (
    <div>
      {dialogOpen && (
        <Dialog
          className="custom-modal-wrapper"
          open={dialogOpen}
          onClose={onCloseDialog}
        >
          <DialogTitle>
            {t("translations:profile_created_successfully")}
          </DialogTitle>
          <DialogActions>
            <Button
              variant="contained"
              onClick={onCloseDialog}
              className="px-4 complete-profile-button"
            >
              {t("translations:continue")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <div className="complete-profile-main d-flex align-items-center justify-content-center">
        <div className="complete-profile-main-card">
          <div className="complete-profile-wrapper d-flex align-items-center row">
            <div style={{ paddingBottom: "50px" }}>
              <Header />
            </div>
            <div className="row">
              <div className="complete-profile-form-wrapper align-items-center col-sm-12 col-md-6 col-lg-7 col-xl-5 order-1 order-md-1 order-lg-0">
                <div className="complete-profile-headding">
                  <h4 className="complete-profile-head">
                    {t("translations:complete_profile")}
                  </h4>
                </div>
                {user && user?.company?.length !== 0 && !user?.completeProfile && (
                  <>
                    <div className="category">
                      <Button
                        className="text-start"
                        onClick={() => {
                          setShow(true);
                          setUserData({ ...userData, newCompany: "" });
                        }}
                      >
                        {t("translations:join_company")}
                      </Button>
                      {isShow && (
                        <FormControl
                          fullWidth
                          error={clicked && userData?.newCompany === ""}
                          style={{ marginTop: "14px" }}
                        >
                          <InputLabel>{t("translations:company")}</InputLabel>
                          <Select
                            id="demo-simple-select"
                            value={userData?.newCompany}
                            label="Company"
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                newCompany: e.target.value,
                              })
                            }
                          >
                            {user?.company &&
                              user?.company.map((i) => (
                                <MenuItem key={i._id} value={i.name}>
                                  {i.name}
                                </MenuItem>
                              ))}
                          </Select>
                          {clicked && userData?.newCompany === "" && (
                            <FormHelperText sx={{ color: "#d32f2f" }}>
                              {t("translations:please_select_the_company")}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    </div>
                    <div className="d-flex justify-content-center">
                      <Button style={{ cursor: "auto" }}>
                        {t("translations:or")}
                      </Button>
                    </div>
                    <Button
                      onClick={() => {
                        setShow(false);
                        setUserData({ ...userData, newCompany: "other" });
                      }}
                    >
                      {t("translations:create_a_new_company")}
                    </Button>
                  </>
                )}

                {userData?.newCompany === "other" && !isShow && (
                  <>
                    <div>
                      <CssTextField
                        value={userData?.company}
                        label={t("translations:your_company_name")}
                        type="text"
                        className="w-100 mb-3"
                        error={clicked && userData?.company === ""}
                        helperText={
                          clicked && userData?.company === ""
                            ? t("translations:company_name_error")
                            : ""
                        }
                        onChange={(e) =>
                          setUserData({ ...userData, company: e.target.value })
                        }
                      />
                    </div>
                    <div>
                      <CssTextField
                        value={userData?.address}
                        label={t("translations:main_address")}
                        type="text"
                        className="w-100 mb-3"
                        autoComplete="off"
                        error={clicked && userData?.address === ""}
                        helperText={
                          clicked && userData?.address === ""
                            ? t("translations:address_is_required")
                            : ""
                        }
                        onChange={(e) =>
                          setUserData({ ...userData, address: e.target.value })
                        }
                      />
                    </div>
                    <div>
                      <CssTextField
                        value={userData?.introductionLink}
                        label={t("translations:company_introduction_link")}
                        type="text"
                        className="w-100 mb-3"
                        autoComplete="off"
                        // error={clicked && userData?.address === ""}
                        // helperText={
                        //   clicked && userData?.address === ""
                        //     ? t("translations:address_is_required")
                        //     : ""
                        // }
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            introductionLink: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div>
                      <CssTextField
                        value={userData?.companyBio}
                        label={t("translations:company_bio")}
                        type="text"
                        className="w-100 mb-3"
                        autoComplete="off"
                        multiline
                        rows={2.5}
                        // error={clicked && userData?.address === ""}
                        // helperText={
                        //   clicked && userData?.address === ""
                        //     ? t("translations:address_is_required")
                        //     : ""
                        // }
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            companyBio: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div>
                      <label className="logo">
                        {t("translations:logo")}
                        <input
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            setImageError("");
                            setSelectedFile(e.target.files[0]);
                          }}
                          accept="image/*"
                        ></input>
                      </label>
                      {selectedFile && (
                        <img
                          className="selected-logo"
                          src={imageUrl}
                          alt="Logo"
                        />
                      )}
                    </div>
                    {imageError && (
                      <div
                        className="error-message"
                        style={{ marginLeft: "0px" }}
                      >
                        {imageError}
                      </div>
                    )}
                  </>
                )}
                <div className="d-flex justify-content-center mt-4">
                  <Button
                    className="px-4 complete-profile-button"
                    onClick={(e) => onCompleteProfile(e)}
                    variant="contained"
                  >
                    {t("translations:continue")}
                  </Button>
                </div>
              </div>
              <div className="complete-profile-img-wrapper col-sm-12 col-md-12 col-lg-5 col-xl-7 order-0 order-md-0 order-lg-1 d-flex justify-content-center">
                <img src={profile} className="img-fluid" alt="yeap" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompleteProfile;
