import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../Component/Header/Header";
import { styled } from "@mui/material/styles";
import {
  Input,
  TextField,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import isemail from "validator/lib/isEmail";
import axios from "../../axios";
import Progress from "../../Component/Progress/Progress";
import { useAuth0 } from "@auth0/auth0-react";
import GoogleIcon from "@mui/icons-material/Google";
import image from "../../Assets/image.svg";
import * as Authaction from "../../Redux/Action/Authaction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "./SignUp.css";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#ff6600",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ff6600",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#1490cc",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ff6600",
    },
  },
});

const CssFormControl = styled(FormControl)({
  "& label.Mui-focused": {
    color: "#ff6600",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ff6600",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#1490cc",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ff6600",
    },
  },
});

const SignUp = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    conPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const { isAuthenticated, logout, user, loginWithRedirect, isLoading } =
    useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    if (isAuthenticated) {
      setLoading(true);
      if (user) {
        axios
          .post("/register", {
            firstName: user?.given_name,
            lastName: user?.family_name,
            email: user?.email,
            googleSignUp: true,
          })
          .then((res) => {
            if (res.status === 201) {
              localStorage.setItem("token", res?.data?.token);
              localStorage.setItem("userId", res?.data?.user?._id);
              dispatch(
                Authaction.storeuser({
                  user: res?.data?.user,
                  token: res?.data?.token,
                })
              );
              history.push("/completeProfile");
              setLoading(false);
            }
          })
          .catch((e) => {
            setLoading(false);
            setDialogTitle(e?.response?.data?.error);
            setDialogOpen(true);
          });
      }
    } else {
      setLoading(false);
    }
  }, [isAuthenticated]);

  const validate = () => {
    if (
      userData?.firstName === "" ||
      userData?.lastName === "" ||
      userData?.email === "" ||
      !isemail(userData?.email) ||
      userData?.password === "" ||
      userData.password.toString().length < 8 ||
      userData?.conPassword === "" ||
      userData?.conPassword.toString().length < 8 ||
      userData?.password !== userData?.conPassword
    ) {
      return false;
    }
    return true;
  };

  const onSignUp = (e) => {
    e.preventDefault();
    setClicked(true);
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      axios
        .post("/register", userData)
        .then((res) => {
          if (res.status === 201) {
            localStorage.setItem("token", res?.data?.token);
            localStorage.setItem("userId", res?.data?.user?._id);
            history.push("/completeProfile", {
              userDispatch: res?.data?.user,
              tokenDispatch: res?.data?.token,
            });
            setLoading(false);
          }
          dispatch(
            Authaction.storeuser({
              user: res?.data?.user,
              token: res?.data?.token,
            })
          );
        })
        .catch((e) => {
          setErrorMessage(e.response?.data?.error);
          setLoading(false);
        });
    }
  };

  const onCloseDialog = () => {
    setDialogOpen(false);
    logout();
    // logout({ returnTo: "http://localhost:3000" });
  };

  return loading || isLoading ? (
    <Progress />
  ) : (
    <div>
      <Dialog open={dialogOpen} onClose={onCloseDialog}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogActions>
          <Button onClick={onCloseDialog}>{t("translations:continue")}</Button>
        </DialogActions>
      </Dialog>
      <div className="signup-main d-flex align-items-center justify-content-center">
        <div className="signup-main-card">
          <div className="signup-wrapper d-flex align-items-center row">
            <Header />
            <div className="signup-img-wrapper col-sm-12 col-md-12 col-lg-5 col-xl-7 d-flex justify-content-center">
              <img src={image} className="img-fluid" alt="signup-img" />
            </div>

            <div className="signup-form-wrapper align-items-center col-sm-12 col-md-6 col-lg-7 col-xl-5">
              {errorMessage !== "" && (
                <div className="error">{errorMessage}</div>
              )}
              <div className="signup-headding">
                <h4 className="signup-head">{t("translations:sign_up")}</h4>
              </div>
              <div>
                <CssTextField
                  value={userData?.firstName}
                  label={t("translations:first_name")}
                  type="text"
                  variant="standard"
                  className="w-100 mb-3"
                  autoComplete="off"
                  helperText={
                    clicked && userData?.firstName === ""
                      ? t("translations:first_name_error")
                      : ""
                  }
                  onChange={(e) =>
                    setUserData({ ...userData, firstName: e.target.value })
                  }
                />
              </div>
              <div>
                <CssTextField
                  value={userData?.lastName}
                  label={t("translations:last_name")}
                  type="text"
                  variant="standard"
                  className="w-100 mb-3"
                  // autoComplete="off"
                  helperText={
                    clicked && userData?.lastName === ""
                      ? t("translations:last_name_error")
                      : ""
                  }
                  onChange={(e) =>
                    setUserData({ ...userData, lastName: e.target.value })
                  }
                />
              </div>
              <div>
                <CssTextField
                  value={userData?.email}
                  label={t("translations:email")}
                  type="email"
                  variant="standard"
                  className="w-100 mb-3"
                  autoComplete="off"
                  helperText={
                    clicked &&
                    (userData?.email === "" || !isemail(userData?.email))
                      ? t("translations:email_error")
                      : ""
                  }
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      email: e.target.value.toLowerCase(),
                    })
                  }
                />
              </div>
              <div style={{ paddingBottom: "16px" }}>
                <CssFormControl variant="standard" className="w-100">
                  <InputLabel>{t("translations:password")}</InputLabel>
                  <Input
                    type={showPassword ? "text" : "password"}
                    value={userData?.password}
                    autoComplete="off"
                    onChange={(e) =>
                      setUserData({ ...userData, password: e.target.value })
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {clicked &&
                    (userData?.password === "" ||
                      userData.password.toString().length < 8) && (
                      <FormHelperText>
                        {t("translations:password_error1")}
                      </FormHelperText>
                    )}
                </CssFormControl>
              </div>
              <div>
                <CssFormControl variant="standard" className="w-100">
                  <InputLabel htmlFor="standard-adornment-password">
                    {t("translations:confirm_password")}
                  </InputLabel>
                  <Input
                    type={showConfirmPassword ? "text" : "password"}
                    value={userData?.conPassword}
                    autoComplete="off"
                    onChange={(e) =>
                      setUserData({ ...userData, conPassword: e.target.value })
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {clicked &&
                    (userData?.conPassword === "" ||
                      userData?.conPassword.toString().length < 8) && (
                      <FormHelperText>
                        {t("translations:confirm_password_error")}
                      </FormHelperText>
                    )}
                  {clicked &&
                    userData?.conPassword.toString().length >= 8 &&
                    userData?.password !== userData?.conPassword && (
                      <FormHelperText>
                        {t("translations:password_error2")}
                      </FormHelperText>
                    )}
                </CssFormControl>
              </div>
              <div className="d-flex justify-content-center mt-4">
                <button
                  className="px-4 sign-button"
                  onClick={(e) => onSignUp(e)}
                >
                  {t("translations:sign_up")}
                </button>
              </div>
              <div>
                <p
                  className="mt-2 create-p text-center mb-0"
                  style={{ color: "#A9A9A9", paddingTop: "10px" }}
                >
                  {t("translations:already_account")}{" "}
                  <span className="sign-up" onClick={() => history.push("/")}>
                    {t("translations:sign_in")}
                  </span>
                </p>
              </div>
              <hr />
              <div className="d-flex justify-content-center">
                <button
                  onClick={() =>
                    loginWithRedirect({
                      redirectUri: "https://portal.yeap.tv/signUp",
                      // redirectUri: "http://localhost:3000/signUp",
                    })
                  }
                  className="google-button"
                >
                  <GoogleIcon />
                  <span>{t("translations:google")}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
