import * as actionsTypes from './ActionType';
import axios from "../../axios";

export const getAllCompanies = () => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.GET_ALL_COMPANIES_LOADING });
    await axios
      .get("/getAllComapny")
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionsTypes.GET_ALL_COMPANIES_SUCCESS,
            allCompany: res.data,
          });
        } else {
          dispatch({
            type: actionsTypes.GET_ALL_COMPANIES_ERROR,
            error: res.data.message,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionsTypes.GET_ALL_COMPANIES_ERROR,
          error: e,
        });
      });
  };
};

export const onAcceptRejectCompany = (data) => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.ACCEPT_REJECT_COMPANY_LOADING });
    await axios
      .patch("/updateCompany",{...data})
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionsTypes.ACCEPT_REJECT_COMPANY_SUCCESS,
            data: data,
            message: res.data.message,
          });
        } else {
          dispatch({
            type: actionsTypes.ACCEPT_REJECT_COMPANY_ERROR,
            error: res.data.message,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionsTypes.ACCEPT_REJECT_COMPANY_ERROR,
          error: e,
        });
      });
  };
};
