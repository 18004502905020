import React from "react";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";

export const BackButton = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="back-wrapper" onClick={() => history.goBack()}>
      <ArrowBackIcon style={{ fill: "#aea5a5" }} />
      <span className="back-text">{t("translations:back")}</span>
    </div>
  );
};
