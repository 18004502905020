import React from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import moment from "moment";
import Rating from "@mui/material/Rating";
import coin from "../../../Assets/coin.png";
import { useTranslation } from "react-i18next";

const PackageDetails = ({ selectedPackage, sas }) => {
  const { t } = useTranslation();
  return (
    <div className="default-container mt-5 mb-5" key={selectedPackage?._id}>
      <div className="box-bg row" style={{ margin: 0 }}>
        <div className="col-xl-3 col-lg-4 col-md-5 image-left">
          <img
            className="w-100 border-image"
            src={
              selectedPackage?.image &&
              `https://yeapportal.blob.core.windows.net/yeapportal/${selectedPackage?.image}?${sas}`
            }
            alt="demo"
          />
        </div>
        <div className="col-xl-9 col-lg-8 col-md-7 box-map">
          <div className="heading-drop">
            <h4 className="title-box">{selectedPackage?.title}</h4>
          </div>
          <div className=" right-side-content">
            <div className="box-text-fisrt">
              <div className="border-input">{selectedPackage?.description}</div>
            </div>

            <div className="secound-box mt-xl-4 mt-3">
              <div className=" box_secound d-flex  w-100 flex-wrap mt-xl-4 mt-3 mb-3">
                <div className="back_in_border d-flex flex-column flex-grow-1">
                  <span className="title_head">
                    {t("translations:no_content_items")}
                  </span>
                  <span className="t_text">
                    {selectedPackage?.noOfFeedback === 0
                      ? "-"
                      : selectedPackage?.noOfFeedback}
                  </span>
                </div>
                <div className="back_in_border d-flex flex-column flex-grow-1">
                  <span className="title_head">
                    {t("translations:created_on")}
                  </span>
                  <span className="">
                    {moment(selectedPackage?.createdAt).format("LL")}
                  </span>
                </div>
                <div className="back_in_border d-flex flex-column flex-grow-1">
                  <span className="title_head">{t("translations:price")}</span>
                  <span className="d-flex align-items-center">
                    {selectedPackage?.price}{" "}
                    <img
                      src={coin}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "8px",
                      }}
                      alt="coin"
                    />
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-between w-100 flex-wrap">
                <div>
                  <div className="d-flex">
                    <p
                      className="s-title_head"
                      style={{
                        color:
                          selectedPackage?.status === "Draft"
                            ? "#A9A9A9"
                            : selectedPackage?.status === "Active"
                            ? "#008000"
                            : selectedPackage?.status === "On Hold"
                            ? "#CCCC00"
                            : selectedPackage?.status === "Rejected"
                            ? "#FF0000"
                            : "#FFA500",
                      }}
                    >
                      {selectedPackage?.status === "Draft" &&
                        t("translations:draft")}
                      {selectedPackage?.status === "Active" &&
                        t("translations:active")}
                      {selectedPackage?.status === "On Hold" &&
                        t("translations:on_hold")}
                      {selectedPackage?.status === "Rejected" &&
                        t("translations:rejected")}
                      {selectedPackage?.status === "De Activate" &&
                        t("translations:de_activate")}
                      {selectedPackage?.status === "Under Verification" &&
                        t("translations:under_verification")}
                    </p>
                    {selectedPackage?.rejectedReason && (
                      <Tooltip title={selectedPackage?.rejectedReason}>
                        <QuestionCircleOutlined
                          style={{
                            margin: "6px 2px",
                            fontSize: "14px",
                            color: "#837c7c",
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                  <span className="s-t_text">{t("translations:status")}</span>
                </div>
                <div>
                  <p className="s-title_head">
                    {selectedPackage?.subscription === 0
                      ? "-"
                      : selectedPackage?.subscription}
                  </p>
                  <span className="s-t_text">
                    {t("translations:number_of_users")}
                  </span>
                </div>
                <div>
                  <p className="s-title_head">
                    {selectedPackage?.contents?.reduce((a, b) => {
                      return a + b?.views;
                    }, 0) === 0
                      ? "-"
                      : selectedPackage?.contents?.reduce((a, b) => {
                          return a + b?.views;
                        }, 0)}
                  </p>
                  <span className="s-t_text">{t("translations:views")}</span>
                </div>
                <div>
                  <p className="s-title_head">
                    {selectedPackage?.rating === 0 ? (
                      "-"
                    ) : (
                      <div className="d-flex align-items-center">
                        {selectedPackage?.rating}
                        <Rating
                          defaultValue={1}
                          max={1}
                          readOnly
                          style={{ paddingLeft: "5px" }}
                        />
                      </div>
                    )}
                  </p>
                  <span className="s-t_text">{t("translations:rating")}</span>
                </div>
                <div>
                  {selectedPackage?.revenue === 0 ? (
                    <span className="s-title_head"> - </span>
                  ) : (
                    <span className="s-title_head">
                      {selectedPackage?.revenue}{" "}
                      <img
                        src={coin}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginLeft: "8px",
                        }}
                        alt="coin"
                      />
                    </span>
                  )}
                  <span className="s-t_text">{t("translations:revenue")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageDetails;
