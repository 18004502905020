import * as actionsTypes from "./ActionType";
import axios from "../../axios";

export const editRate = (data) => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.ON_EDIT_RATE_LOADING });
    await axios
      .patch("/editRate", { ...data })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionsTypes.ON_EDIT_RATE_SUCCESS,
            payload: { ...data },
            message: res.data.message,
          });
        } else {
          dispatch({
            type: actionsTypes.ON_EDIT_RATE_ERROR,
            error: res.data.message,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionsTypes.ON_EDIT_RATE_ERROR,
          error: e.response.data.message,
        });
      });
  };
};

export const getRate = (id) => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.GET_RATE_LOADING });
    await axios
      .get("/getRate")
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionsTypes.GET_RATE_SUCCESS,
            payload: res.data,
          });
        } else {
          dispatch({
            type: actionsTypes.GET_RATE_ERROR,
            error: res.data.message,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionsTypes.GET_RATE_ERROR,
          error: e.response.data.message,
        });
      });
  };
};
