import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  TextField,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import ListIcon from "@mui/icons-material/List";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { Tooltip } from "antd";
import moment from "moment";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import coin from "../../Assets/coin.png";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#373030",
    color: theme.palette.common.white,
    padding: "8px 0 8px 16px",
    "& > .Mui-active": {
      color: "#fff",
      "& svg": {
        opacity: 1,
        color: "#fff",
      },
    },
    "& > span:hover": {
      color: "#fff",
      opacity: 1,
      "& svg": {
        opacity: 1,
      },
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: "nowrap",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Search = styled("div")(() => ({
  position: "relative",
  border: "1px solid rgb(193 193 193)",
  borderRadius: "5px",
  marginRight: "0 !important",
  marginLeft: "0 !important",
  width: "240px !important",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const PendingPackages = ({ pendingList, sas, onAccept }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [accept, setAccept] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [clicked, setClicked] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [allList, setAllList] = useState([]);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const open = Boolean(anchorEl);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    setClicked(false);
  }, [dialogOpen]);

  const onAccepting = () => {
    setClicked(true);
    if (accept) {
      onAccept({
        id: selectedPackage?._id,
        status: "Active",
        rejectedReason: "",
        onHoldByYeap: false,
        type: selectedPackage?.type,
      });
      setDialogOpen(false);
    } else {
      if (rejectReason === "") {
        return;
      } else {
        onAccept({
          id: selectedPackage?._id,
          status: "Rejected",
          rejectedReason: rejectReason,
          onHoldByYeap: true,
          type: selectedPackage?.type,
        });
        setDialogOpen(false);
      }
    }
  };

  useEffect(() => {
    const array = [];
    pendingList.forEach((item) => {
      array.push({
        ...item,
        ownerName: `${item?.owner?.firstName} ${item?.owner?.lastName}`,
        companyName: item?.company?.name,
      });
    });
    setAllList(array);
  }, [pendingList]);

  useEffect(() => {
    if (searchValue === "") {
      setFilteredList(allList);
    } else {
      const dynamicFilter = allList.filter((single) => {
        return (
          single?.title?.toLowerCase().includes(searchValue.toLowerCase()) ||
          single?.description
            ?.toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          single?.price.toString().includes(searchValue) ||
          single?.ownerName
            ?.toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          single?.companyName
            ?.toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          moment(single?.createdAt)
            .format("LL")
            ?.toLowerCase()
            .includes(searchValue.toLowerCase())
        );
      });
      setFilteredList(dynamicFilter);
    }
  }, [allList, searchValue]);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    {
      id: "title",
      disablePadding: false,
      label: t("translations:title"),
    },
    {
      id: "description",
      disablePadding: false,
      label: t("translations:description"),
    },
    {
      id: "price",
      disablePadding: false,
      label: t("translations:price"),
    },
    {
      id: "noOfFeedback",
      disablePadding: false,
      label: t("translations:no_of_content"),
    },
    {
      id: "createdAt",
      disablePadding: false,
      label: t("translations:created_on"),
    },
    {
      id: "ownerName",
      disablePadding: false,
      label: t("translations:owner_name"),
    },
    {
      id: "companyName",
      disablePadding: false,
      label: t("translations:company"),
    },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <StyledTableCell />
          <StyledTableCell>{t("translations:image")}</StyledTableCell>
          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align={"left"}
              padding={"normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
          <StyledTableCell />
        </TableRow>
      </TableHead>
    );
  }

  return (
    <div style={{ marginTop: "20px" }}>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() =>
            history.push(`/packages/packageDetails?id=${selectedPackage?._id}`)
          }
        >
          <ListItemIcon>
            <ListIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("translations:view_package")}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAccept(true);
            setDialogOpen(true);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <CheckIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("translations:accept_pacakge")}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAccept(false);
            setDialogOpen(true);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <ClearIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("translations:reject_package")}</ListItemText>
        </MenuItem>
      </Menu>
      <div className="d-flex justify-content-end pb-3">
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            value={searchValue}
            placeholder={t("translations:search")}
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Search>
        <Button
          variant="contained"
          className="ms-2"
          onClick={() => setSearchValue("")}
        >
          {t("translations:clear")}
        </Button>
      </div>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <colgroup>
              <col style={{ width: "5%" }} />
              <col style={{ width: "5%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "5%" }} />
              <col style={{ width: "8%" }} />
              <col style={{ width: "13%" }} />
              <col style={{ width: "14%" }} />
              <col style={{ width: "15%" }} />
              <col style={{ width: "5%" }} />
            </colgroup>

            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {filteredList.length !== 0 ? (
                stableSort(filteredList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell>
                        <img
                          src={
                            row?.image &&
                            `https://yeapportal.blob.core.windows.net/yeapportal/${row?.image}?${sas}`
                          }
                          alt="demo"
                          style={{ width: "50px", height: "50px" }}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          history.push(
                            `/packages/packageDetails?id=${row?._id}`
                          )
                        }
                      >
                        <Tooltip placement="bottom" title={row?.title}>
                          {row?.title.length >= 20
                            ? row?.title.substring(0, 20)
                            : row?.title}
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Tooltip placement="bottom" title={row?.description}>
                          {row?.description.length >= 40
                            ? row?.description.substring(0, 40) + "..."
                            : row?.description}
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell>
                        <span className="d-flex align-items-center">
                          {row?.price}{" "}
                          <img
                            src={coin}
                            style={{
                              width: "20px",
                              height: "20px",
                              marginLeft: "8px",
                            }}
                            alt="coin"
                          />
                        </span>
                      </StyledTableCell>
                      <StyledTableCell>{row?.noOfFeedback}</StyledTableCell>
                      <StyledTableCell>
                        {moment(row?.createdAt).format("LL")}
                      </StyledTableCell>
                      <StyledTableCell>{row?.ownerName}</StyledTableCell>
                      <StyledTableCell
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          history.push(
                            `/companies/companyProfile?id=${row?.company?._id}&name=${row?.company?.name}`
                          )
                        }
                      >
                        {row?.companyName}
                      </StyledTableCell>
                      <StyledTableCell>
                        <IconButton
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={(e) => {
                            setSelectedPackage(row);
                            handleClick(e);
                          }}
                        >
                          <MoreVertRoundedIcon color="primary" />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
              ) : (
                <StyledTableCell component="th" scope="row">
                  {t("translations:no_package_found")}
                </StyledTableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            borderTop: "1px solid #e0e0e0",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <DialogTitle>
          {`${t("translations:accept_reject_dialog")} ${
            accept ? t("translations:accept") : t("translations:reject")
          }  ${t("translations:this_pacakge")} ${selectedPackage?.title}?`}
        </DialogTitle>
        <DialogContent>
          {!accept && (
            <div style={{ paddingTop: "10px" }}>
              <TextField
                value={rejectReason}
                multiline
                rows={4}
                label={t("translations:reason")}
                type="text"
                className="w-100 mb-3"
                id="custom-css-outlined-input"
                autoComplete="off"
                placeholder={t("translations:reason_placeholder1")}
                helperText={
                  clicked && rejectReason === ""
                    ? t("translations:reason_error")
                    : ""
                }
                error={clicked && rejectReason === ""}
                onChange={(e) => setRejectReason(e.target.value)}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>
            {t("translations:cancel")}
          </Button>
          <Button
            onClick={() => {
              onAccepting();
            }}
            autoFocus
          >
            {t("translations:ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PendingPackages;
