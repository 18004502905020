import * as actionTypes from "../Action/ActionType";

const initstate = {
  allPackage: [],
  allKnowHow: [],
  allCampaign: [],
  loading: false,
  error: "",
  updateMessage: "",
};

const AllPackages = (state = initstate, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_PACKAGES_SUCCESS:
      return {
        ...state,
        allPackage: action?.allPackage,
        loading: false,
      };
    case actionTypes.GET_ALL_PACKAGES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ALL_PACKAGES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.GET_ALL_KNOWHOW_SUCCESS:
      return {
        ...state,
        allKnowHow: action?.allKnowHow,
        loading: false,
      };
    case actionTypes.GET_ALL_KNOWHOW_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ALL_KNOWHOW_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.GET_ALL_CAMPAIGN_SUCCESS:
      return {
        ...state,
        allCampaign: action?.allCampaign,
        loading: false,
      };
    case actionTypes.GET_ALL_CAMPAIGN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ALL_CAMPAIGN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.RESET_GET_ALL_PACKAGES_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
        updateMessage: "",
      };
    case actionTypes.ACCEPT_REJECT_PACKAGE_SUCCESS:
      return {
        ...state,
        updateMessage: action?.message,
        loading: false,
        error: "",
        allPackage: state.allPackage?.map((pack) => {
          if (pack?._id === action?.data?.id) {
            return {
              ...pack,
              status: action?.data?.status,
              rejectedReason: action?.data?.rejectedReason,
              onHoldByYeap: action?.data?.onHoldByYeap
            };
          }
          return pack;
        }),
      };
    case actionTypes.ACCEPT_REJECT_PACKAGE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ACCEPT_REJECT_PACKAGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.ACCEPT_REJECT_KNOWHOW_SUCCESS:
      return {
        ...state,
        updateMessage: action?.message,
        loading: false,
        error: "",
        allKnowHow: state.allKnowHow?.map((pack) => {
          if (pack?._id === action?.data?.id) {
            return {
              ...pack,
              status: action?.data?.status,
              rejectedReason: action?.data?.rejectedReason,
              onHoldByYeap: action?.data?.onHoldByYeap
            };
          }
          return pack;
        }),
      };
    case actionTypes.ACCEPT_REJECT_CAMPAIGN_SUCCESS:
      return {
        ...state,
        updateMessage: action?.message,
        loading: false,
        error: "",
        allCampaign: state.allCampaign?.map((pack) => {
          if (pack?._id === action?.data?.id) {
            return {
              ...pack,
              status: action?.data?.status,
              rejectedReason: action?.data?.rejectedReason,
              onHoldByYeap: action?.data?.onHoldByYeap
            };
          }
          return pack;
        }),
      };
    default:
      return state;
  }
};

export default AllPackages;
