import * as actionsTypes from "./ActionType";
import axios from "../../axios";

export const storeuser = (data) => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.ON_LOADING });
    dispatch({
      type: actionsTypes.STORE_TOKEN_SUCEESS,
      payload: data,
    });
  };
};

export const companyProfile = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionsTypes.COMPLETE_PROFILE_SUCCESS,
      payload: data,
    });
  };
};

export const setSelectedCompany = (data) => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.ON_LOADING });
    dispatch({
      type: actionsTypes.SET_SELECTED_COMPANY,
      payload: data,
    });
  };
};

export const setSelectedCompanyInfo = (data) => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.ON_LOADING });
    dispatch({
      type: actionsTypes.SET_SELECTED_COMPANYINFO,
      payload: data,
    });
  };
};

export const setSasToken = () => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.ON_LOADING });
    await axios
      .get("/getSasToken")
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionsTypes.SET_SAS_TOKEN,
            sas: res.data,
          });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };
};

export const onEditCompany = (data) => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.ON_EDIT_COMPANY_LOADING });
    await axios
      .patch("/updateCompany", { ...data })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionsTypes.ON_EDIT_COMPANY_SUCCESS,
            data: data,
            message: res.data.message,
          });
        } else {
          dispatch({
            type: actionsTypes.ON_EDIT_COMPANY_ERROR,
            error: res.data.message,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionsTypes.ON_EDIT_COMPANY_ERROR,
          error: e,
        });
      });
  };
};

export const onEditProfile = (data) => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.ON_EDIT_PROFILE_LOADING });
    await axios
      .patch("/updateProfile", { ...data })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionsTypes.ON_EDIT_PROFILE_SUCCESS,
            data: data,
            message: res.data.message,
          });
        } else {
          debugger;
          dispatch({
            type: actionsTypes.ON_EDIT_PROFILE_ERROR,
            error: res.data.message,
          });
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch({
          type: actionsTypes.ON_EDIT_PROFILE_ERROR,
          error: e.response.data.message,
        });
      });
  };
};

export const onAddCompany = (data) => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.ON_LOADING });
    await axios
      .post("/createCompany", { ...data })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionsTypes.ON_ADD_COMPANY_SUCCESS,
            user: res.data.user,
            message: res.data.message,
          });
        } else {
          dispatch({
            type: actionsTypes.ON_ADD_COMPANY_ERROR,
            error: res.data.message,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionsTypes.ON_ADD_COMPANY_ERROR,
          error: e,
        });
      });
  };
};