import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllCompanies,
  onAcceptRejectCompany,
} from "../../Redux/Action/AllCopaniesaction";
import {
  RESET_GET_ALL_COMPANIES_ERROR,
  CHANGE_CAMPAIGN_PRICE,
} from "../../Redux/Action/ActionType";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ActiveCompany from "./ActiveCompany";
import RejectedCompany from "./RejectedCompany";
import Progress from "../../Component/Progress/Progress";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import "./Companies.css";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Companies = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const handleChange = (event, newValue) => {
    setActiveStep(newValue);
  };
  const data = useSelector((state) => state.AllCompanies);
  const { selectedCompanyInfo } = useSelector((state) => state.Auth);
  const { allCompany, loading, error, updateMessage } = data;
  const [activeList, setActiveList] = useState([]);
  const [rejectedList, setRejectedList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCompanies());
  }, []);

  useEffect(() => {
    setActiveList(allCompany.filter((com) => !com?.onHold));
    setRejectedList(allCompany.filter((com) => com?.onHold));
  }, [allCompany]);

  useEffect(() => {
    if (error !== "") {
      message.error(error);
      dispatch({ type: RESET_GET_ALL_COMPANIES_ERROR });
    }
  }, [error]);

  useEffect(() => {
    if (updateMessage !== "") {
      message.success(updateMessage);
      dispatch({ type: RESET_GET_ALL_COMPANIES_ERROR });
    }
  }, [updateMessage]);

  const onAccept = (data) => {
    dispatch(onAcceptRejectCompany({ ...data }));
    selectedCompanyInfo?._id === data?.id &&
      dispatch({
        type: CHANGE_CAMPAIGN_PRICE,
        campaignPrice: data?.campaignPrice,
      });
  };

  return loading ? (
    <Progress />
  ) : (
    <div style={{ paddingTop: "20px" }}>
      <div className="companies-tab default-container">
        <Tabs
          value={activeStep}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {/* <Tab label="Pending List" {...a11yProps(0)} /> */}
          <Tab label={t("translations:active_list")} {...a11yProps(0)} />
          <Tab label={t("translations:rejected_list")} {...a11yProps(1)} />
        </Tabs>
      </div>
      <div className="default-container">
        {/* {activeStep === 0 && <PendingCompany pendingList={pendingList} onAccept={(data) => onAccept(data)}/> } */}
        {activeStep === 0 && (
          <ActiveCompany
            activeList={activeList}
            onAccept={(data) => onAccept(data)}
          />
        )}
        {activeStep === 1 && (
          <RejectedCompany
            rejectedList={rejectedList}
            onAccept={(data) => onAccept(data)}
          />
        )}
      </div>
    </div>
  );
};

export default Companies;
