import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Card from "../../../Component/Card/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Progress from "../../../Component/Progress/Progress";
import { getWallet } from "../../../Redux/Action/Walletaction";
import {
  redeemYeapCoin,
  getTrasnaction,
  getAllTrasnaction,
} from "../../../Redux/Action/Transactionaction";
import {
  getPaypalInfo,
  getBankInfo,
} from "../../../Redux/Action/BankInfoaction";
import { getRate } from "../../../Redux/Action/Rate";
import * as actionsTypes from "../../../Redux/Action/ActionType";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { message } from "antd";
import "./Revenue.css";
import TransactionDetail from "./User/TransactionDetail/TransactionDetail";
import PaymentInfo from "./User/PaymentInfo/PaymentInfo";
import AdminTransactionDetail from "./Admin/AdminTransactionDetail/AdminTransactionDetail";
import RateSettings from "./Admin/RateSettings/RateSettings";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#ff6600",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ff6600",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#1490cc",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ff6600",
    },
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Revenue = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedCompanyInfo, user } = useSelector((state) => state.Auth);
  const walletData = useSelector((state) => state.Wallet);
  const { withdrawCoin, balanceCoin, totalRevenue, loading, error } =
    walletData;
  const {
    transaction,
    transactionLoading,
    transactionError,
    transactionSuccess,
  } = useSelector((state) => state.Transaction);
  const data = useSelector((state) => state.Rate);
  const {
    paypalInfo,
    bankInfo,
    bankInfoLoading,
    bankInfoError,
    bankInfoMessage,
  } = useSelector((state) => state.BankInfo);
  const [activeStep, setActiveStep] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [redeemClicked, setRedeemClicked] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [allTransactionType, setAllTransactionType] = useState([]);

  const handleChange = (event, newValue) => {
    setActiveStep(newValue);
  };

  const adminPackages = [
    {
      title: t("translations:total_revenue"),
      number: totalRevenue,
      background: "#55B4B0",
      firstColor: "#FFFFFF",
      secondColor: "#FFFFFF",
    },
    {
      title: t("translations:total_withdraw"),
      number: withdrawCoin,
      background: "#6B5B95",
      firstColor: "#FFFFFF",
      secondColor: "#FFFFFF",
    },
    {
      title: t("translations:current_balance"),
      number: balanceCoin,
      background: "#FF6F61",
      firstColor: "#FFFFFF",
      secondColor: "#FFFFFF",
    },
  ];

  useEffect(() => {
    const types = [];
    if (dialogOpen === true) {
      if (paypalInfo?.email !== "") {
        types.push(t("translations:paypal_transfer"));
      }
      if (bankInfo?.swift !== "") {
        types.push(t("translations:bank_transfer"));
      }
      setAllTransactionType(types);
    }
  }, [dialogOpen]);

  useEffect(() => {
    dispatch(getRate());
    if (selectedCompanyInfo?._id === "623a23085f6b21fda23d1d2a") {
      dispatch(getAllTrasnaction());
    } else {
      dispatch(getWallet(selectedCompanyInfo._id));
      dispatch(getTrasnaction(selectedCompanyInfo._id));
      dispatch(getPaypalInfo(selectedCompanyInfo._id));
      dispatch(getBankInfo(selectedCompanyInfo._id));
    }
  }, []);

  useEffect(() => {
    error !== "" && message.error(error);
    if (transactionError !== "") {
      message.error(transactionError);
      dispatch({ type: actionsTypes.CREATE_YEAP_TRANSACTION_AFTER_SUCCESS });
    }
    if (transactionSuccess !== "") {
      message.success(transactionSuccess);
      dispatch({ type: actionsTypes.CREATE_YEAP_TRANSACTION_AFTER_SUCCESS });
      if (selectedCompanyInfo?._id !== "623a23085f6b21fda23d1d2a") {
        dispatch(getWallet(selectedCompanyInfo._id));
        dispatch(getTrasnaction(selectedCompanyInfo._id));
      }
    }
    if (bankInfoError !== "") {
      message.error(bankInfoError);
      dispatch({ type: actionsTypes.ON_EDIT_BANK_INFO_AFTER_SUCCESS });
    }
    if (bankInfoMessage !== "") {
      message.success(bankInfoMessage);
      dispatch({ type: actionsTypes.ON_EDIT_BANK_INFO_AFTER_SUCCESS });
      dispatch(getPaypalInfo(selectedCompanyInfo._id));
      dispatch(getBankInfo(selectedCompanyInfo._id));
    }
  }, [
    error,
    transactionError,
    transactionSuccess,
    bankInfoError,
    bankInfoMessage,
  ]);

  useEffect(() => {
    if (data?.message !== "") {
      message.success(data?.message);
      dispatch({ type: actionsTypes.ON_EDIT_RATE_AFTER_SUCCESS });
    }
    if (data?.error !== "") {
      message.error(data?.error);
      dispatch({ type: actionsTypes.ON_EDIT_RATE_AFTER_SUCCESS });
    }
  }, [data]);

  const onCloseDialog = () => {
    setRedeemClicked(false);
    setWithdrawAmount("");
    setTransactionType("");
    setDialogOpen(false);
    // setCompanyAddClicked(false);
    // setSelectedFile(null);
    // setAddCompanyData({ company: "", address: "" });
  };

  const validate = () => {
    if (
      withdrawAmount <= 0 ||
      transactionType === "" ||
      withdrawAmount > balanceCoin
    ) {
      return false;
    }
    return true;
  };

  // company: req?.body?.company,
  //     requestAmount: req?.body?.requestAmount,
  //     transactionType: req?.body?.transactionType,
  //     user: req?.body?.user,

  const onRedeemCoin = () => {
    setRedeemClicked(true);
    const isValid = validate();
    if (isValid) {
      dispatch(
        redeemYeapCoin({
          company: selectedCompanyInfo._id,
          requestAmount: Number(withdrawAmount),
          transactionType: transactionType,
          user: user._id,
        })
      );
      setRedeemClicked(false);
      setWithdrawAmount("");
      setTransactionType("");
      setDialogOpen(false);
    }
  };

  return transactionLoading || loading || bankInfoLoading ? (
    <Progress />
  ) : (
    <div className="revenue-wrapper">
      {selectedCompanyInfo?._id === "623a23085f6b21fda23d1d2a" ? (
        <>
          <div className="companies-tab default-container select-tab-redeem">
            <Tabs
              value={activeStep}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label={t("translations:transaction_report")}
                {...a11yProps(1)}
              />
              <Tab label={t("translations:rate_settings")} {...a11yProps(0)} />
            </Tabs>
          </div>
          <div className="transaction-detail-wrapper">
            {activeStep === 0 && (
              <AdminTransactionDetail transaction={transaction} />
            )}
            {activeStep === 1 && <RateSettings />}
          </div>
        </>
      ) : (
        <>
          <div className="revenue-company-wrapper">
            <span className="revenue-company-name">
              {selectedCompanyInfo?.name}
            </span>
            <div className="p-0 main-button">
              <Button
                variant="contained"
                className="add-button"
                onClick={() => {
                  setDialogOpen(true);
                }}
              >
                {t("translations:redeem_yeap_coin")}
              </Button>
            </div>
          </div>
          <Card cards={adminPackages} id={selectedCompanyInfo._id} />
          <div className="companies-tab default-container select-tab-redeem">
            <Tabs
              value={activeStep}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label={t("translations:past_withdrawal")}
                {...a11yProps(0)}
              />
              <Tab label={t("translations:payment_method")} {...a11yProps(1)} />
            </Tabs>
          </div>
          <div className="transaction-detail-wrapper">
            {activeStep === 0 && (
              <TransactionDetail transaction={transaction} />
            )}
            {activeStep === 1 && (
              <PaymentInfo paypalInfo={paypalInfo} bankInfo={bankInfo} />
            )}
          </div>
        </>
      )}
      <Dialog
        open={dialogOpen}
        onClose={() => {
          onCloseDialog();
        }}
        fullWidth={true}
      >
        <DialogTitle>{t("translations:redeem_coin")}</DialogTitle>
        <DialogContent>
          <>
            <div>
              <span style={{ fontSize: "16px", fontWeight: 600 }}>
                {t("translations:current_exchange_rate")}
              </span>
              <span style={{ fontSize: "18px" }}>
                {data?.payoutExchangeRate}
              </span>
            </div>
            {allTransactionType.length === 0 && (
              <div style={{ color: "red" }}>
                {t("translations:add_payment_method")}
              </div>
            )}
            <div>
              <CssTextField
                value={withdrawAmount}
                label={t("translations:withdraw_amount")}
                type="number"
                className="w-100 mb-3 mt-3"
                placeholder={t("translations:amount_placeholder")}
                error={
                  (redeemClicked && withdrawAmount <= 0) ||
                  (redeemClicked && withdrawAmount > balanceCoin)
                }
                helperText={
                  redeemClicked && withdrawAmount <= 0
                    ? t("translations:error_amount")
                    : redeemClicked && withdrawAmount > balanceCoin
                    ? t("translations:error_amount1")
                    : ""
                }
                onChange={(e) => setWithdrawAmount(e.target.value)}
              />
            </div>
            <div className="mb-5">
              <FormControl fullWidth>
                <InputLabel>{t("translations:transaction_type")}</InputLabel>
                <Select
                  id="demo-simple-select"
                  value={transactionType}
                  label={t("translations:transaction_type")}
                  onChange={(e) => {
                    setTransactionType(e.target.value);
                  }}
                  error={redeemClicked && transactionType === ""}
                >
                  {allTransactionType.map((cate, index) => {
                    return (
                      <MenuItem value={cate} key={index}>
                        {cate}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {redeemClicked && transactionType === "" && (
                <div className="error-message">
                  {t("translations:transaction_error")}
                </div>
              )}
            </div>
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onCloseDialog()}>
            {t("translations:cancel")}
          </Button>
          <Button
            disabled={allTransactionType.length === 0}
            onClick={() => onRedeemCoin()}
          >
            {t("translations:redeem")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Revenue;
