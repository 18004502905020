import React from "react";

const Support = () => {
  return (
    <div>
      <div>
        <h1 className="dashboard-main">Support</h1>
      </div>
    </div>
  );
};

export default Support;
