import CircularProgress from "@mui/material/CircularProgress";
import "./Progress.css";

const Progress = () => {
  return (
    <div className="progress-new">
      <CircularProgress size={80} color="info" />
    </div>
  );
};

export default Progress;
