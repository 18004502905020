import React from "react";
import Logo from "../../Assets/logo.png";
import "./Header.css";

const Header = () => {
  return (
    <div className="logo-img">
      <img src={Logo} alt="logo" className="logo-image"></img>
    </div>
  );
};

export default Header;
