import * as actionTypes from "../Action/ActionType";

const initstate = {
  withdrawCoin: 0,
  balanceCoin: 0,
  totalRevenue: 0,
  loading: false,
  error: "",
};

const companyProfile = (state = initstate, action) => {
  switch (action.type) {
    case actionTypes.GET_WALLET_SUCCESS:
      return {
        ...state,
        withdrawCoin: action?.payload?.withdrawCoin,
        balanceCoin: action?.payload?.balanceCoin,
        totalRevenue: action?.payload?.totalRevenue,
        loading: false,
      };
    case actionTypes.GET_WALLET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_WALLET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default companyProfile;
