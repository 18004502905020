import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import axios from "../../../axios";
import { message } from "antd";
import Progress from "../../../Component/Progress/Progress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#373030",
    color: theme.palette.common.white,
    "& > .Mui-active": {
      color: "#fff",
      "& svg": {
        opacity: 1,
        color: "#fff",
      },
    },
    "& > span:hover": {
      color: "#fff",
      opacity: 1,
      "& svg": {
        opacity: 1,
      },
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: "nowrap",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CompanyTeam = ({ companyId }) => {
  const { t } = useTranslation();
  const [allMember, setAllMember] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState(0);
  const [pendingUsers, setPendingUsers] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt");

  const getCopanyUsersData = () => {
    setIsLoading(true);
    if (companyId) {
      axios
        .get(`/getInvitedUser/${companyId}`)
        .then((res) => {
          if (res.data.success) {
            const allData = [];
            res?.data?.users.forEach((user) => {
              allData.push({
                ...user,
                name: `${user?.firstName} ${user?.lastName}`,
                status: t("translations:active"),
                role: user?.company
                  ?.filter((j) => j.id === companyId)
                  .map((k) => _.capitalize(k.role))[0],
              });
            });
            res?.data?.pendingUsers.forEach((user) => {
              allData.push({
                ...user,
                role: _.capitalize(user?.role),
                name: `${user?.firstName} ${user?.lastName}`,
                status: t("translations:pending"),
              });
            });
            setAllMember(allData);
            setInvitedUsers(res?.data?.users?.length);
            setPendingUsers(res?.data?.pendingUsers?.length);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        })
        .catch((e) => {
          message.error(e || t("translations:network_error"));
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    getCopanyUsersData();
  }, [companyId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    {
      id: "name",
      disablePadding: false,
      label: t("translations:name"),
    },
    {
      id: "email",
      disablePadding: false,
      label: t("translations:email"),
    },
    {
      id: "role",
      disablePadding: false,
      label: t("translations:role"),
    },
    {
      id: "createdAt",
      disablePadding: false,
      label: t("translations:created_on"),
    },
    {
      id: "status",
      disablePadding: false,
      label: t("translations:status"),
    },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <StyledTableCell />
          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align={"left"}
              padding={"normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return isLoading ? (
    <Progress />
  ) : (
    <div className="default-container">
      <div className="teamContent">
        <div className="content-wrapper">
          <div className="teamWrapper team-second">
            <h2 className="heading">{t("translations:active")}</h2>
            <h1
              className="d-flex justify-content-center fw-bold"
              style={{ color: "green" }}
            >
              {invitedUsers}
            </h1>
          </div>
          <div className="teamWrapper team-third">
            <h2 className="heading">{t("translations:pending")}</h2>
            <h1
              className="d-flex justify-content-center fw-bold"
              style={{ color: "red" }}
            >
              {pendingUsers}
            </h1>
          </div>
        </div>
      </div>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table aria-label="customized table">
            <colgroup>
              <col style={{ width: "10%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "30%" }} />
              <col style={{ width: "13%" }} />
              <col style={{ width: "14%" }} />
              <col style={{ width: "13%" }} />
            </colgroup>

            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {allMember.length !== 0 ? (
                stableSort(allMember, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.name === "undefined undefined" ? "-" : row?.name}
                      </StyledTableCell>
                      <StyledTableCell>{row?.email}</StyledTableCell>
                      <StyledTableCell>{row?.role}</StyledTableCell>
                      <StyledTableCell>
                        {moment(row?.createdAt).format("LL")}
                      </StyledTableCell>
                      <StyledTableCell>
                        <div
                          style={{
                            color:
                              row?.status === "Active" ? "#008000" : "#FF0000",
                          }}
                        >
                          {row?.status}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
              ) : (
                <StyledTableCell component="th" scope="row">
                  {t("translations:no_member_found")}
                </StyledTableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ borderTop: "1px solid #e0e0e0" }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={allMember.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </div>
  );
};

export default CompanyTeam;
