import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { Tooltip } from "antd";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import coin from "../../../Assets/coin.png";
import { visuallyHidden } from "@mui/utils";
import Progress from "../../../Component/Progress/Progress";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "./CompanyProfile.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#373030",
    color: theme.palette.common.white,
    "& > .Mui-active": {
      color: "#fff",
      "& svg": {
        opacity: 1,
        color: "#fff",
      },
    },
    "& > span:hover": {
      color: "#fff",
      opacity: 1,
      "& svg": {
        opacity: 1,
      },
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: "nowrap",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CompanyPackage = ({ motivation, campaign, knowhow }) => {
  const { t } = useTranslation();
  const sas = useSelector((state) => state.Auth.sas);
  const [all, setall] = useState([]);
  const [data, setData] = useState({
    active: 0,
    hold: 0,
    under: 0,
    deactive: 0,
    draft: 0,
    rejected: 0,
  });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const newMotivation = motivation.map((moti) => {
      let rating = 0;
      let count = 0;
      moti.contents.forEach((con) => {
        if (con?.rating !== 0) {
          rating = rating + con?.rating;
          count++;
        }
      });
      return {
        ...moti,
        rating: rating / count ? (rating / count).toFixed(2) : 0,
      };
    });
    const newCampaign = campaign.map((moti) => {
      let rating = 0;
      let count = 0;
      moti.contents.forEach((con) => {
        if (con?.rating !== 0) {
          rating = rating + con?.rating;
          count++;
        }
      });
      return {
        ...moti,
        rating: rating / count ? (rating / count).toFixed(2) : 0,
      };
    });
    const newKnowhow = knowhow.map((moti) => {
      let rating = 0;
      let count = 0;
      moti?.contents.length !== 0 &&
        moti.contents.forEach((con) => {
          if (con?.rating !== 0) {
            rating = rating + con?.rating;
            count++;
          }
        });
      return {
        ...moti,
        rating: rating / count ? (rating / count).toFixed(2) : 0,
      };
    });
    const data = newMotivation.concat(newCampaign).concat(newKnowhow);
    setall(data);
    setLoading(false);
  }, [motivation, campaign, knowhow]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    {
      id: "title",
      disablePadding: false,
      label: t("translations:title"),
    },
    {
      id: "price",
      disablePadding: false,
      label: t("translations:price"),
    },
    {
      id: "revenue",
      disablePadding: false,
      label: t("translations:revenue"),
    },
    {
      id: "rating",
      disablePadding: false,
      label: t("translations:rating"),
    },
    {
      id: "subscription",
      disablePadding: false,
      label: t("translations:number_of_users"),
    },
    {
      id: "noOfFeedback",
      disablePadding: false,
      label: t("translations:no_of_content"),
    },
    {
      id: "createdAt",
      disablePadding: false,
      label: t("translations:created_on"),
    },
    {
      id: "status",
      disablePadding: false,
      label: t("translations:status"),
    },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <StyledTableCell />
          <StyledTableCell>{t("translations:image")}</StyledTableCell>
          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align={"center"}
              padding={"normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  useEffect(() => {
    let active = 0;
    let hold = 0;
    let under = 0;
    let deactive = 0;
    let draft = 0;
    let rejected = 0;
    all &&
      all.forEach((data) => {
        data?.status === "Active"
          ? active++
          : data?.status === "On Hold"
          ? hold++
          : data?.status === "Under Verification"
          ? under++
          : data?.status === "De Activate"
          ? deactive++
          : data?.status === "Rejected"
          ? rejected++
          : draft++;
      });
    setData({ active, hold, under, deactive, rejected, draft });
  }, [all]);

  return loading ? (
    <Progress />
  ) : (
    <div className="default-container py-3">
      <div className="company-package-wrapper">
        <div className="package-card">
          <h2 className="package-heading-text">{t("translations:active")}</h2>
          <h1
            className="d-flex justify-content-center fw-bold"
            style={{ color: "green" }}
          >
            {data?.active}
          </h1>
        </div>
        <div className="package-card">
          <h2 className="package-heading-text">{t("translations:rejected")}</h2>
          <h1
            className="d-flex justify-content-center fw-bold"
            style={{ color: "red" }}
          >
            {data?.rejected}
          </h1>
        </div>
        <div className="package-card">
          <h2 className="package-heading-text">{t("translations:on_hold")}</h2>
          <h1
            className="d-flex justify-content-center fw-bold"
            style={{ color: "#CCCC00" }}
          >
            {data?.hold}
          </h1>
        </div>
        <div className="package-card">
          <h2 className="package-heading-text">{t("translations:deactive")}</h2>
          <h1
            className="d-flex justify-content-center fw-bold"
            style={{ color: "red" }}
          >
            {data?.deactive}
          </h1>
        </div>
        <div className="package-card">
          <h2 className="package-heading-text">{t("translations:draft")}</h2>
          <h1
            className="d-flex justify-content-center fw-bold"
            style={{ color: "#A9A9A9" }}
          >
            {data?.draft}
          </h1>
        </div>
        <div className="package-card">
          <h2 className="package-heading-text">
            {t("translations:under_verification")}
          </h2>
          <h1
            className="d-flex justify-content-center fw-bold"
            style={{ color: "#FFA500" }}
          >
            {data?.under}
          </h1>
        </div>
      </div>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table aria-label="customized table">
            <colgroup>
              <col style={{ width: "5%" }} />
              <col style={{ width: "5%" }} />
              <col style={{ width: "12%" }} />
              <col style={{ width: "12%" }} />
              <col style={{ width: "12%" }} />
              <col style={{ width: "12%" }} />
              <col style={{ width: "12%" }} />
              <col style={{ width: "12%" }} />
              <col style={{ width: "12%" }} />
              <col style={{ width: "12%" }} />
            </colgroup>

            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {all.length !== 0 ? (
                stableSort(all, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell>
                        <img
                          src={
                            row?.image &&
                            `https://yeapportal.blob.core.windows.net/yeapportal/${row?.image}?${sas}`
                          }
                          alt="demo"
                          style={{ width: "50px", height: "50px" }}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ paddingRight: "45px" }}
                      >
                        <Tooltip placement="bottom" title={row?.title}>
                          {row?.title.length >= 20
                            ? row?.title.substring(0, 20)
                            : row?.title}
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ paddingRight: "45px" }}
                      >
                        <span>
                          {row?.price}{" "}
                          <img
                            src={coin}
                            style={{
                              width: "20px",
                              height: "20px",
                              marginLeft: "8px",
                            }}
                            alt="coin"
                          />
                        </span>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ paddingRight: "45px" }}
                      >
                        <span>
                          {row?.revenue}{" "}
                          <img
                            src={coin}
                            style={{
                              width: "20px",
                              height: "20px",
                              marginLeft: "8px",
                            }}
                            alt="coin"
                          />
                        </span>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ paddingRight: "45px" }}
                      >
                        {row?.rating}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ paddingRight: "45px" }}
                      >
                        {row?.subscription}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ paddingRight: "45px" }}
                      >
                        {row?.noOfFeedback}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ paddingRight: "25px" }}
                      >
                        {moment(row?.createdAt).format("LL")}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ paddingRight: "45px" }}
                      >
                        <div
                          style={{
                            color:
                              row?.status === "Active"
                                ? "#008000"
                                : row?.status === "On Hold"
                                ? "#CCCC00"
                                : row?.status === "Draft"
                                ? "#A9A9A9"
                                : row?.status === "Under Verification"
                                ? "#FFA500"
                                : "#FF0000",
                          }}
                        >
                          {row?.status === "Draft" && t("translations:draft")}
                          {row?.status === "Active" && t("translations:active")}
                          {row?.status === "On Hold" &&
                            t("translations:on_hold")}
                          {row?.status === "Rejected" &&
                            t("translations:rejected")}
                          {row?.status === "De Activate" &&
                            t("translations:de_activate")}
                          {row?.status === "Under Verification" &&
                            t("translations:under_verification")}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
              ) : (
                <StyledTableCell component="th" scope="row">
                  {t("translations:no_package_found")}
                </StyledTableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ borderTop: "1px solid #e0e0e0" }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={all.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </div>
  );
};

export default CompanyPackage;

/* {motivation.concat(campaign).concat(knowhow).sort(function (a, b) { return new Date(b.createdAt) - new Date(a.createdAt) }).map((data) => (
                <div>{data?.title} {data?.description}</div>
    ))} */
