import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import Header from "../../Component/Header/Header";
import { styled } from "@mui/material/styles";
import {
  Input,
  TextField,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  FormHelperText,
  Dialog,
  DialogTitle,
  ListItem,
  ListItemText,
} from "@mui/material";
import logo from "../../Assets/logo.png";
import GoogleIcon from "@mui/icons-material/Google";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAuth0 } from "@auth0/auth0-react";
import Progress from "../../Component/Progress/Progress";
import axios from "../../axios";
import { message } from "antd";
import isemail from "validator/lib/isEmail";
import * as Authaction from "../../Redux/Action/Authaction";
import { useSelector, useDispatch } from "react-redux";
import login from "../../Assets/login.svg";
import List from "@mui/material/List";
import { useTranslation } from "react-i18next";
import "./SignIn.css";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#ff6600",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ff6600",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#1490cc",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ff6600",
    },
  },
});

const CssFormControl = styled(FormControl)({
  "& label.Mui-focused": {
    color: "#ff6600",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ff6600",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#1490cc",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ff6600",
    },
  },
});

const SignIn = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [clicked, setClicked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [res, setRes] = useState({});
  const { isLoading, isAuthenticated, logout, user, loginWithRedirect } =
    useAuth0();
  const { sas } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    if (isAuthenticated) {
      setLoading(true);
      if (user) {
        axios
          .post("/loginWithGoogle", { email: user?.email })
          .then((res) => {
            if (res.status === 200) {
              setRes(res.data);
              if (res?.data?.user?.company?.length > 1) {
                setDialogOpen(true);
                setCompanyList(res?.data?.user?.company);
                setLoading(false);
              } else {
                dispatch(
                  Authaction.storeuser({
                    user: res?.data?.user,
                    token: res?.data?.token,
                  })
                );
                setLoading(false);
              }
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("userId", res.data.user?._id);
              if (res.data.user.company.length > 0) {
                localStorage.setItem(
                  "company",
                  JSON.stringify({
                    company: res.data.user.company[0].name,
                    companyId: res.data.user.company[0].id,
                    role: res?.data.user.company[0].role,
                  })
                );
                dispatch(
                  Authaction.setSelectedCompany({
                    company: res.data.user.company[0].name,
                    companyId: res.data.user.company[0].id,
                    role: res?.data.user.company[0].role,
                  })
                );
              }
              history.push("/");
              setLoading(false);
            }
          })
          .catch((e) => {
            message.error(
              e.response?.data?.error || t("translations:network_error")
            );
            logout();
            // logout({ returnTo: "http://localhost:3000" });
            setLoading(false);
          });
      }
    } else {
      setLoading(false);
    }
  }, [isAuthenticated]);

  const validate = () => {
    if (email === "" || !isemail(email) || password === "") {
      return false;
    }
    return true;
  };

  const onLogin = (e) => {
    setClicked(true);
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      axios
        .post("/signin", { email, password })
        .then((res) => {
          if (res.status === 200) {
            setRes(res.data);
            if (res?.data?.user?.company?.length > 1) {
              setDialogOpen(true);
              setCompanyList(res?.data?.user?.company);
              setLoading(false);
            } else {
              dispatch(
                Authaction.storeuser({
                  user: res?.data?.user,
                  token: res?.data?.token,
                })
              );
            }
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("userId", res.data.user?._id);
            res.data.user.company.length > 0 &&
              localStorage.setItem(
                "company",
                JSON.stringify({
                  company: res.data.user.company[0].name,
                  companyId: res.data.user.company[0].id,
                  role: res?.data.user.company[0].role,
                })
              );

            setLoading(false);
            window.location.reload();
          }
        })
        .catch((e) => {
          setErrorMessage(e.response?.data.error);
          setLoading(false);
        });
    }
  };

  const handleListItemClick = (value) => {
    localStorage.setItem(
      "company",
      JSON.stringify({
        company: value.name,
        companyId: value.id,
        role: value.role,
      })
    );
    dispatch(
      Authaction.setSelectedCompany({
        company: value.name,
        companyId: value.id,
        role: value.role,
      })
    );
    dispatch(
      Authaction.storeuser({
        user: res?.user,
        token: res?.token,
      })
    );
    setDialogOpen(false);
  };

  return loading || isLoading ? (
    <Progress />
  ) : (
    <>
      <Dialog
        open={dialogOpen}
        maxWidth={"xs"}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="text-lg-center text-dark">
          {t("translations:select_company")}
        </DialogTitle>
        <List sx={{ pt: 0 }}>
          {companyList.map((company) => (
            <ListItem
              button
              onClick={() => handleListItemClick(company)}
              key={company._id}
            >
              <div className="logo-main-wrapper">
                <img
                  className="logo-img-part"
                  alt="Logo"
                  src={
                    company?.logo
                      ? `https://yeapportal.blob.core.windows.net/yeapportal/${company?.logo}?${sas}`
                      : logo
                  }
                />
              </div>
              <ListItemText primary={company.name} />
            </ListItem>
          ))}
        </List>
      </Dialog>

      <div className="signin-main d-flex align-items-center justify-content-center">
        <div className="signup-main-card">
          <div className="signup-wrapper d-flex align-items-center row">
            <div style={{ paddingBottom: "50px" }}>
              <Header />
            </div>
            <div className="signup-form-wrapper align-items-center col-sm-12 col-md-6 col-lg-7 col-xl-5 order-1 order-md-1 order-lg-0">
              <div className="signup-headding">
                <h4 className="signup-head">{t("translations:log_in")}</h4>
              </div>
              <div>
                {errorMessage !== "" && (
                  <span className="error">{errorMessage}</span>
                )}
                <div>
                  <CssTextField
                    value={email}
                    label={t("translations:email")}
                    type="email"
                    variant="standard"
                    className="w-100 mb-3"
                    autoComplete="off"
                    onChange={(e) => setEmail(e.target.value)}
                    helperText={
                      clicked && (email === "" || !isemail(email))
                        ? t("translations:email_error")
                        : ""
                    }
                  />
                </div>
                <div>
                  <CssFormControl
                    variant="standard"
                    className="w-100 password-part"
                  >
                    <InputLabel htmlFor="standard-adornment-password">
                      {t("translations:password")}
                    </InputLabel>
                    <Input
                      id="standard-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      autoComplete="off"
                      onChange={(e) => setPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {clicked && password === "" && (
                      <FormHelperText>
                        {t("translations:password_error")}
                      </FormHelperText>
                    )}
                  </CssFormControl>
                </div>
              </div>
              <div>
                <p className="mt-1 create-p text-end mb-0">
                  <span
                    className="forgot-password"
                    onClick={() => history.push("/")}
                  >
                    {t("translations:forgot_password")}
                  </span>
                </p>
              </div>
              <div className="d-flex justify-content-center mt-4">
                <button
                  className="px-4 sign-button"
                  onClick={(e) => onLogin(e)}
                >
                  {t("translations:login")}
                </button>
              </div>
              <div>
                <p
                  className="mt-2 create-p text-center mb-0"
                  style={{ color: "#A9A9A9", paddingTop: "10px" }}
                >
                  {t("translations:doesnot_account")}{" "}
                  <span
                    className="sign-up"
                    onClick={() => history.push("/signup")}
                  >
                    {t("translations:sign_up")}
                  </span>
                </p>
              </div>
              <hr />
              <div className="d-flex justify-content-center">
                <button onClick={loginWithRedirect} className="google-button">
                  <GoogleIcon />
                  <span>{t("translations:google")}</span>
                </button>
              </div>
            </div>
            <div className="signup-img-wrapper col-sm-12 col-md-12 col-lg-5 col-xl-7 order-0 order-md-0 order-lg-1 d-flex justify-content-center">
              <img src={login} className="img-fluid" alt="yeap" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(SignIn);
