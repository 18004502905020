import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import Progress from "../../../../../Component/Progress/Progress";
import { editRate } from "../../../../../Redux/Action/Rate";
import "./RateSettings.css";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#ff6600",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ff6600",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#1490cc",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ff6600",
    },
  },
});

const RateSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedCompanyInfo } = useSelector((state) => state.Auth);
  const [payoutExchangeRate, setPayoutExchangeRate] = useState(2);
  const [purchaseExchangeRate, setPurchaseExchangeRate] = useState(2);
  const [clicked, setClicked] = useState(false);
  const data = useSelector((state) => state.Rate);

  useEffect(() => {
    setPayoutExchangeRate(data?.payoutExchangeRate);
    setPurchaseExchangeRate(data?.purchaseExchangeRate);
  }, [data]);

  const validate = () => {
    if (payoutExchangeRate <= 0 || purchaseExchangeRate <= 0) {
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    setClicked(true);
    const isValid = validate();
    if (isValid) {
      dispatch(
        editRate({
          payoutExchangeRate: Number(payoutExchangeRate),
          purchaseExchangeRate: Number(purchaseExchangeRate),
          adminId: selectedCompanyInfo?._id,
        })
      );
    }
  };

  return data?.loading ? (
    <Progress />
  ) : (
    <div className="rate-setting-wrapper">
      <div className="rate-setting-form row">
        <div className="col-sm">
          <CssTextField
            value={payoutExchangeRate}
            label={t("translations:payout_exchange_rate")}
            type="number"
            // variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            className="w-100 mb-3"
            autoComplete="off"
            onChange={(e) => setPayoutExchangeRate(e.target.value)}
            error={clicked && payoutExchangeRate <= 0}
            helperText={
              clicked && payoutExchangeRate <= 0
                ? t("translations:payout_error")
                : ""
            }
          />
        </div>
        <div className="col-sm">
          <CssTextField
            value={purchaseExchangeRate}
            label={t("translations:purchase_exchange_rate")}
            type="number"
            // variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            className="w-100 mb-3"
            autoComplete="off"
            onChange={(e) => setPurchaseExchangeRate(e.target.value)}
            error={clicked && purchaseExchangeRate <= 0}
            helperText={
              clicked && purchaseExchangeRate <= 0
                ? t("translations:purchase_error")
                : ""
            }
          />
        </div>
        <div className="d-flex justify-content-center mt-4">
          <button className="px-4 profile-button" onClick={() => onSubmit()}>
            {t("translations:update")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RateSettings;
