import * as actionsTypes from "./ActionType";
import axios from "../../axios";

export const redeemYeapCoin = (data) => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.CREATE_YEAP_TRANSACTION_LOADING });
    await axios
      .post("createtranscation", { ...data })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionsTypes.CREATE_YEAP_TRANSACTION_SUCCESS,
            message: res.data.message,
          });
        } else {
          dispatch({
            type: actionsTypes.CREATE_YEAP_TRANSACTION_ERROR,
            error: res.data.message,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionsTypes.CREATE_YEAP_TRANSACTION_ERROR,
          error: e.response.data.message,
        });
      });
  };
};

export const getTrasnaction = (id) => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.GET_TRANSACTION_LOADING });
    await axios
      .get(`transcation/${id}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionsTypes.GET_TRANSACTION_SUCCESS,
            payload: res.data,
          });
        } else {
          dispatch({
            type: actionsTypes.GET_TRANSACTION_ERROR,
            error: res.data.message,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionsTypes.GET_TRANSACTION_ERROR,
          error: e.response.data.message,
        });
      });
  };
};

export const getAllTrasnaction = () => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.GET_TRANSACTION_LOADING });
    await axios
      .get("alltransaction")
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionsTypes.GET_TRANSACTION_SUCCESS,
            payload: res.data,
          });
        } else {
          dispatch({
            type: actionsTypes.GET_TRANSACTION_ERROR,
            error: res.data.message,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionsTypes.GET_TRANSACTION_ERROR,
          error: e.response.data.message,
        });
      });
  };
};

export const onChangeStatus = (data) => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.ON_EDIT_STATUS_LOADING });
    await axios
      .patch("/updateTransaction/", { ...data })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionsTypes.ON_EDIT_STATUS_SUCCESS,
            payload: { ...data, message: res.data.message },
          });
        } else {
          dispatch({
            type: actionsTypes.ON_EDIT_STATUS_ERROR,
            error: res.data.message,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionsTypes.ON_EDIT_STATUS_ERROR,
          error: e.response.data.message,
        });
      });
  };
};
