import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import PaypalForm from "./PaypalForm";
import BankForm from "./BankForm";
import { useTranslation } from "react-i18next";
import "./PaymentInfo.css";

const PaymentInfo = ({ paypalInfo, bankInfo }) => {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState("paypal");

  return (
    <div className="payment-info-wrapper">
      <RadioGroup
        row
        aria-labelledby="demo-radio-buttons-group-label"
        value={selectedType}
        name="radio-buttons-group"
        onChange={(e) => setSelectedType(e.target.value)}
      >
        <FormControlLabel
          value="paypal"
          control={<Radio />}
          label={t("translations:paypal")}
        />
        <FormControlLabel
          value="bank"
          control={<Radio />}
          label={t("translations:bank_transfer")}
        />
      </RadioGroup>
      {selectedType === "paypal" && <PaypalForm paypalInfo={paypalInfo} />}
      {selectedType === "bank" && <BankForm bankInfo={bankInfo} />}
    </div>
  );
};

export default PaymentInfo;
