import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import logo from "../../Assets/logo.png";
import Progress from "../../Component/Progress/Progress";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import DensityMediumRoundedIcon from "@mui/icons-material/DensityMediumRounded";
import HomeIcon from "@mui/icons-material/Home";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import CampaignIcon from "@mui/icons-material/Campaign";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import Divider from "@mui/material/Divider";
import GroupIcon from "@mui/icons-material/Group";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";
import * as Authaction from "../../Redux/Action/Authaction";
import companyLogo from "../../Assets/companylogo.png";
import { useTranslation } from "react-i18next";
import "./MainHeader.css";

const MainHeader = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [path, setPath] = useState("/");
  const { logout } = useAuth0();
  const user = useSelector((state) => state.Auth.user);
  const localCompany = useSelector((state) => state.Auth.selectedCompany);
  const { selectedCompanyInfo, selectedCompany, sas } = useSelector(
    (state) => state.Auth
  );
  const [sidebar, setSidebar] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEll, setAnchorEll] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const languageLocal = localStorage.getItem("i18nextLng");
  const open = Boolean(anchorEl);
  const openl = Boolean(anchorEll);

  useEffect(() => {
    if (languageLocal === "en-GB") {
      setSelectedLanguage("en");
    } else {
      setSelectedLanguage(languageLocal);
    }
  }, []);

  const languageList = [
    { code: "en", name: "English" },
    { code: "fr", name: "French" },
    { code: "de", name: "German" },
    { code: "it", name: "Italian" },
    { code: "es", name: "Spanish" },
  ];

  useEffect(() => {
    localCompany?.companyId === "623a23085f6b21fda23d1d2a"
      ? setSuperAdmin(true)
      : setSuperAdmin(false);
  }, [localCompany]);

  useEffect(() => {
    setPath(history.location.pathname);
  }, [sidebar]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setSidebar(open);
  };

  const list = () => (
    <Box
      sx={{ width: 250, paddingTop: "20px" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      className={superAdmin ? "mobile-header-super" : ""}
    >
      <List className={"mobile-header"}>
        <ListItem
          button
          className={
            "mobile-links" +
            (superAdmin ? " mobile-links-super" : "") +
            (path === "/"
              ? superAdmin
                ? " mobile-active-super"
                : " mobile-active"
              : "")
          }
          onClick={() => history.push("/")}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            className={"sidebar-text"}
            primary={t("translations:home")}
          />
        </ListItem>
        <ListItem
          className={
            "mobile-links" +
            (superAdmin ? " mobile-links-super" : "") +
            (path === "/motivation"
              ? superAdmin
                ? " mobile-active-super"
                : " mobile-active"
              : "")
          }
          button
          onClick={() => history.push("/motivation")}
        >
          <ListItemIcon>
            <LeaderboardIcon />
          </ListItemIcon>
          <ListItemText
            className="sidebar-text"
            primary={t("translations:motivation")}
          />
        </ListItem>
        <ListItem
          className={
            "mobile-links" +
            (superAdmin ? " mobile-links-super" : "") +
            (path === "/know-how"
              ? superAdmin
                ? " mobile-active-super"
                : " mobile-active"
              : "")
          }
          button
          onClick={() => history.push("/know-how")}
        >
          <ListItemIcon>
            <HowToRegIcon />
          </ListItemIcon>
          <ListItemText
            className="sidebar-text"
            primary={t("translations:know_how")}
          />
        </ListItem>
        <ListItem
          className={
            "mobile-links" +
            (superAdmin ? " mobile-links-super" : "") +
            (path === "/campaigns"
              ? superAdmin
                ? " mobile-active-super"
                : " mobile-active"
              : "")
          }
          button
          onClick={() => history.push("/campaigns")}
        >
          <ListItemIcon>
            <CampaignIcon />
          </ListItemIcon>
          <ListItemText
            className="sidebar-text"
            primary={t("translations:campaigns")}
          />
        </ListItem>
        <ListItem
          className={
            "mobile-links" +
            (superAdmin ? " mobile-links-super" : "") +
            (path === "https://yeap.crisp.help/en/"
              ? superAdmin
                ? " mobile-active-super"
                : " mobile-active"
              : "")
          }
          button
          onClick={() => window.open("https://yeap.crisp.help/en/", "_blank")}
        >
          <ListItemIcon>
            <SupportAgentIcon />
          </ListItemIcon>
          <ListItemText
            className="sidebar-text"
            primary={t("translations:support")}
          />
        </ListItem>

        {selectedCompany?.role === "admin" && (
          <ListItem
            className={
              "mobile-links" +
              (superAdmin ? " mobile-links-super" : "") +
              (path === "/team"
                ? superAdmin
                  ? " mobile-active-super"
                  : " mobile-active"
                : "")
            }
            button
            onClick={() => history.push("/team")}
          >
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText
              className="sidebar-text"
              primary={t("translations:team")}
            />
          </ListItem>
        )}
        {superAdmin && (
          <ListItem
            className={
              "mobile-links" +
              (superAdmin ? " mobile-links-super" : "") +
              (path === "/companies"
                ? superAdmin
                  ? " mobile-active-super"
                  : " mobile-active"
                : "")
            }
            button
            onClick={() => history.push("/companies")}
          >
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText
              className="sidebar-text"
              primary={t("translations:companies")}
            />
          </ListItem>
        )}
        {superAdmin && (
          <ListItem
            className={
              "mobile-links" +
              (superAdmin ? " mobile-links-super" : "") +
              (path === "/packages"
                ? superAdmin
                  ? " mobile-active-super"
                  : " mobile-active"
                : "")
            }
            button
            onClick={() => history.push("/packages")}
          >
            <ListItemIcon>
              <LeaderboardIcon />
            </ListItemIcon>
            <ListItemText
              className="sidebar-text"
              primary={t("translations:packages")}
            />
          </ListItem>
        )}

        <Divider />
        {Object.keys(user).length !== 0
          ? user?.company.map((i, index) => {
              return (
                <ListItem
                  className={
                    "mobile-links" + (superAdmin ? " mobile-links-super" : "")
                  }
                  key={index}
                  onClick={() => {
                    localCompany?.companyId !== i.id && handleMenuClick(i);
                  }}
                  selected={localCompany && i.id === localCompany.companyId}
                >
                  <ListItemIcon sx={{ borderRadius: "20px" }}>
                    <img
                      style={{ width: "40px", height: "40px" }}
                      src={
                        i?.logo
                          ? `https://yeapportal.blob.core.windows.net/yeapportal/${i?.logo}?${sas}`
                          : companyLogo
                      }
                      alt="logo"
                    />
                  </ListItemIcon>
                  <ListItemText
                    className="sidebar-text"
                    primary={i.name}
                    key={index}
                  />
                </ListItem>
              );
            })
          : ""}
        <ListItem
          className={
            "mobile-links" +
            (superAdmin ? " mobile-links-super" : "") +
            (path === "/myprofile"
              ? superAdmin
                ? " mobile-active-super"
                : " mobile-active"
              : "")
          }
          button
          onClick={() => history.push("/myprofile")}
        >
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText
            className={"sidebar-text"}
            primary={t("translations:settings")}
          />
        </ListItem>
        <ListItem
          className={"mobile-links" + (superAdmin ? " mobile-links-super" : "")}
          button
          onClick={() => window.open("https://yeap.tv/", "_blank")}
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText
            className={"sidebar-text"}
            primary={t("translations:about_us")}
          />
        </ListItem>
        <ListItem
          button
          className={"mobile-links" + (superAdmin ? " mobile-links-super" : "")}
          onClick={() => {
            localStorage.clear();
            logout();
            // logout({ returnTo: "http://localhost:3000" });
          }}
        >
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText
            className={"sidebar-text"}
            primary={t("translations:logout")}
          />
        </ListItem>
      </List>
    </Box>
  );

  useEffect(() => {
    setIsLoading(true);
    setIsLoading(false);
  }, []);

  const handleMenuClick = (item) => {
    localStorage.setItem(
      "company",
      JSON.stringify({
        company: item?.name,
        companyId: item?.id,
        role: item?.role,
      })
    );
    dispatch(
      Authaction.setSelectedCompany({
        company: item?.name,
        companyId: item?.id,
        role: item?.role,
      })
    );
    history.push("/");
    window.location.reload();
  };

  const onChangeLanguage = (code) => {
    return i18n.changeLanguage(code);
  };

  return isLoading ? (
    <Progress />
  ) : (
    <div>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&& .Mui-selected": {
              backgroundColor: "#FF6601",
              color: "white",
              "&:hover": {
                color: "#FF6601",
                backgroundColor: "white",
              },
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/*{companyList && companyList?.company.map((i, index) => (*/}
        {Object.keys(user).length !== 0
          ? user?.company.map((i, index) => (
              <MenuItem
                className="mobile-links"
                key={index}
                onClick={() => {
                  localCompany?.companyId !== i.id && handleMenuClick(i);
                }}
                selected={localCompany && i.id === localCompany.companyId}
              >
                <ListItemIcon sx={{ paddingRight: "10px" }}>
                  <img
                    style={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "20px",
                    }}
                    src={
                      i?.logo
                        ? `https://yeapportal.blob.core.windows.net/yeapportal/${i?.logo}?${sas}`
                        : companyLogo
                    }
                    alt="logo"
                  />
                </ListItemIcon>
                {i.name}
              </MenuItem>
            ))
          : ""}
        {/*}*/}
        {/*))}*/}
        {/* <MenuItem
          className="mobile-links"
          onClick={() => history.push("/myprofile")}
        >
          <Avatar /> My account
        </MenuItem> */}
        <Divider />
        <MenuItem
          className="mobile-links"
          onClick={() => history.push("/myprofile")}
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <span>{t("translations:settings")}</span>
        </MenuItem>
        <MenuItem
          className="mobile-links"
          onClick={() => window.open("https://yeap.tv/", "_blank")}
        >
          <ListItemIcon>
            <PeopleIcon fontSize="small" />
          </ListItemIcon>
          <span>{t("translations:about_us")}</span>
        </MenuItem>
        <MenuItem
          className="mobile-links"
          onClick={() => {
            localStorage.clear();
            logout();
            // logout({ returnTo: "http://localhost:3000" });
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <span>{t("translations:logout")}</span>
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={anchorEll}
        id="select-language"
        open={openl}
        onClose={() => setAnchorEll(null)}
        onClick={() => setAnchorEll(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&& .Mui-selected": {
              backgroundColor: "#FF6601",
              color: "white",
              "&:hover": {
                color: "#FF6601",
                backgroundColor: "white",
              },
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 65,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        {languageList.map((lang) => (
          <MenuItem
            className="mobile-links"
            onClick={() => {
              setSelectedLanguage(lang?.code);
              onChangeLanguage(lang?.code);
            }}
            selected={selectedLanguage && lang.code === selectedLanguage}
          >
            {`${lang.name} (${lang.code})`}
          </MenuItem>
        ))}
      </Menu>
      <Drawer anchor="left" open={sidebar} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
      <div
        className={
          "main-header d-flex align-items-center justify-content-between" +
          (superAdmin ? " main-header-super" : "")
        }
      >
        <div className="d-flex align-items-center">
          <div className="header-logo">
            <img src={logo} className="main-header-logo" alt="logo" />
          </div>
          <div className="header-links align-items-center justify-content-center d-none d-md-none d-xl-flex">
            <NavLink
              exact
              activeClassName={superAdmin ? " active-super" : "active"}
              to="/"
              className={"links" + (superAdmin ? " link-super" : "")}
            >
              <span>{t("translations:home")}</span>
            </NavLink>
            <NavLink
              activeClassName={superAdmin ? " active-super" : "active"}
              className={"links" + (superAdmin ? " link-super" : "")}
              to="/motivation"
            >
              <span>{t("translations:motivation")}</span>
            </NavLink>
            <NavLink
              activeClassName={superAdmin ? " active-super" : "active"}
              className={"links" + (superAdmin ? " link-super" : "")}
              to="/know-how"
            >
              <span>{t("translations:know_how")}</span>
            </NavLink>
            <NavLink
              activeClassName={superAdmin ? " active-super" : "active"}
              className={"links" + (superAdmin ? " link-super" : "")}
              to="/campaigns"
            >
              <span>{t("translations:campaigns")}</span>
            </NavLink>
            {/* <NavLink
              exact
              activeClassName={superAdmin ? " active-super" : "active"}
              className={"links" + (superAdmin ? " link-super" : "")}
              to="https://yeap.crisp.help/en/"
            > */}
            <a className={"links" + (superAdmin ? " link-super" : "")} href>
              <span
                onClick={() =>
                  window.open("https://yeap.crisp.help/en/", "_blank")
                }
              >
                <span>{t("translations:support")}</span>
              </span>
            </a>

            {/* </NavLink> */}

            {selectedCompany?.role === "admin" && (
              <NavLink
                exact
                activeClassName={superAdmin ? " active-super" : "active"}
                className={"links" + (superAdmin ? " link-super" : "")}
                to="/team"
              >
                <span>{t("translations:team")}</span>
              </NavLink>
            )}
            {superAdmin && (
              <NavLink
                activeClassName={superAdmin ? " active-super" : "active"}
                className={"links" + (superAdmin ? " link-super" : "")}
                to="/companies"
              >
                <span>{t("translations:companies")}</span>
              </NavLink>
            )}
            {superAdmin && (
              <NavLink
                activeClassName={superAdmin ? " active-super" : "active"}
                className={"links" + (superAdmin ? " link-super" : "")}
                to="/packages"
              >
                <span>{t("translations:packages")}</span>
              </NavLink>
            )}
          </div>
        </div>

        <div className="mobile-menu-icon d-flex d-md-flex d-xl-none">
          <DensityMediumRoundedIcon
            sx={{ color: "#ff6600", fontSize: "40px" }}
            onClick={() => setSidebar(true)}
          />
        </div>

        <div className="header-user d-flex align-items-center justify-content-end d-none d-md-none d-xl-flex">
          {/*<div className="user-name">Hi, {user?.firstName}</div>*/}
          <div className="user-icon">
            <Tooltip title={t("translations:language_tooltip")}>
              <IconButton
                onClick={(event) => setAnchorEll(event.currentTarget)}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={openl ? "select-language" : undefined}
                aria-haspopup="true"
                aria-expanded={openl ? "true" : undefined}
              >
                <Avatar
                  sx={{
                    bgcolor: "#ff6600",
                    width: 46,
                    height: 46,
                    border: "2px solid #ff6600",
                  }}
                >
                  {selectedLanguage.toUpperCase()}
                </Avatar>
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <div className="header-user d-flex align-items-center justify-content-end d-none d-md-none d-xl-flex">
          {/*<div className="user-name">Hi, {user?.firstName}</div>*/}
          <div className="user-icon">
            <Tooltip title={selectedCompanyInfo?.name}>
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar
                  sx={{ width: 46, height: 46, border: "2px solid #ff6600" }}
                >
                  <img
                    style={{ width: "46px", height: "46px" }}
                    src={
                      selectedCompanyInfo?.logo
                        ? `https://yeapportal.blob.core.windows.net/yeapportal/${selectedCompanyInfo?.logo}?${sas}`
                        : companyLogo
                    }
                    alt="logo"
                  />
                </Avatar>
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: "80px" }}>{props.children}</div>
    </div>
  );
};

export default MainHeader;
