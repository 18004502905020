import React, { useEffect, useState } from "react";
import { Button, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { message } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReorderIcon from "@mui/icons-material/Reorder";
import ReactDevicePreview from "react-device-preview";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TablePagination from "@mui/material/TablePagination";
import Rating from "@mui/material/Rating";
import { useTranslation } from "react-i18next";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#373030",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: "nowrap",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const PackageContent = ({ allContent }) => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [edit, setEdit] = useState(false);
  const [selectedContent, setSelectedContent] = useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [contentText, setContentText] = useState({});
  const editorState = EditorState.createEmpty();
  const [description, setDescription] = useState(editorState);
  const onEditorStateChange = (editor) => {
    setDescription(editor);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const customContentStateConverter = (contentState) => {
    const newBlockMap = contentState.getBlockMap().map((block) => {
      const entityKey = block.getEntityAt(0);
      if (entityKey !== null) {
        const entityBlock = contentState.getEntity(entityKey);
        const entityType = entityBlock.getType();
        switch (entityType) {
          case "IMAGE": {
            const newBlock = block.merge({
              type: "atomic",
              text: "img",
            });
            return newBlock;
          }
          default:
            return block;
        }
      }
      return block;
    });
    const newContentState = contentState.set("blockMap", newBlockMap);
    return newContentState;
  };

  useEffect(() => {
    if (edit) {
      const blocksFromHTML = convertFromHTML(selectedContent?.description);
      var editorState = EditorState.createWithContent(
        customContentStateConverter(
          ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          )
        )
      );
      setDescription(editorState);
    }
  }, [edit]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [rows, setRows] = useState([]);

  useEffect(() => {
    allContent && setRows(allContent.sort((a, b) => a.order - b.order));
  }, [allContent]);

  useEffect(() => {
    if (dialogOpen === false) {
      setEdit(false);
      setDescription(EditorState.createEmpty());
      handleClose();
    }
  }, [dialogOpen]);

  const onView = () => {
    setEdit(true);
    setDialogOpen(true);
  };

  const getFileBase64 = (file, callback) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = (error) => {
      message.error(t("translations:file_upload_failed"));
    };
  };

  const imageUploadCallback = (file) =>
    new Promise((resolve, reject) =>
      getFileBase64(file, (data) => resolve({ data: { link: data } }))
    );

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const items = reorder(rows, result.source.index, result.destination.index);

    setRows(items);
  };

  return (
    <div className="default-container mt-5">
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={onView}>
          <ListItemIcon>
            <VisibilityIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("translations:view_content")}</ListItemText>
        </MenuItem>
      </Menu>
      {rows && (
        <div style={{ paddingBottom: "30px" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer>
              <Table sx={{ minWidth: 600 }} aria-label="customized table">
                <colgroup>
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "50%" }} />
                  <col style={{ width: "5%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "10%" }} />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <StyledTableCell />
                    <StyledTableCell>
                      {t("translations:content")}
                    </StyledTableCell>
                    <StyledTableCell>{t("translations:views")}</StyledTableCell>
                    <StyledTableCell>
                      {t("translations:rating")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {t("translations:action")}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {(droppableProvided) => (
                      <TableBody
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                      >
                        {rows.length !== 0 ? (
                          rows.map((row, index) => (
                            <Draggable
                              key={row._id}
                              draggableId={row._id}
                              index={index}
                            >
                              {(draggableProvided, snapshot) => {
                                return (
                                  <StyledTableRow
                                    key={index}
                                    ref={draggableProvided.innerRef}
                                    {...draggableProvided.draggableProps}
                                    style={{
                                      ...draggableProvided.draggableProps.style,
                                      background: snapshot.isDragging
                                        ? "rgba(245,245,245, 0.75)"
                                        : "none",
                                    }}
                                  >
                                    <StyledTableCell
                                      component="th"
                                      scope="row"
                                      align="center"
                                    >
                                      <div
                                        {...draggableProvided.dragHandleProps}
                                      >
                                        <ReorderIcon />
                                      </div>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setSelectedContent(row);
                                        onView();
                                      }}
                                    >
                                      <div
                                        className="post__description"
                                        dangerouslySetInnerHTML={{
                                          __html: row?.description,
                                        }}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {row?.views}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <div className="rating-wrapper">
                                        <Rating
                                          defaultValue={row?.rating}
                                          precision={0.1}
                                          readOnly
                                        />
                                        <span className="rating-count">
                                          ({row?.numReviews} user)
                                        </span>
                                      </div>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <IconButton
                                        id="basic-button"
                                        aria-controls={
                                          open ? "basic-menu" : undefined
                                        }
                                        aria-haspopup="true"
                                        aria-expanded={
                                          open ? "true" : undefined
                                        }
                                        onClick={(e) => {
                                          setSelectedContent(row);
                                          handleClick(e);
                                        }}
                                      >
                                        <MoreVertRoundedIcon color="primary" />
                                      </IconButton>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                );
                              }}
                            </Draggable>
                          ))
                        ) : (
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              {t("translations:no_content_found")}
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                        {droppableProvided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
            <div style={{ borderTop: "1px solid #e0e0e0" }}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </Paper>
        </div>
      )}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="900px"
        className="add-dialog"
      >
        <DialogContent>
          <div style={{ display: "flex", marginTop: "12px" }}>
            <Editor
              editorState={description}
              onEditorStateChange={onEditorStateChange}
              editorClassName="add-content-editor"
              toolbar={{
                inline: { inDropdown: true },
                image: {
                  uploadCallback: imageUploadCallback,
                  previewImage: true,
                  inputAccept:
                    "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                  defaultSize: {
                    height: "auto",
                    width: "auto",
                  },
                },
              }}
            />
            <textarea
              disabled
              style={{ display: "none" }}
              ref={(val) => (contentText.description = val)}
              value={draftToHtml(convertToRaw(description.getCurrentContent()))}
            />
            <ReactDevicePreview device="galaxys5" scale="0.8">
              <div
                className="post__description_preview"
                dangerouslySetInnerHTML={{
                  __html: contentText?.description
                    ? contentText?.description?.value ||
                      contentText?.description
                    : selectedContent?.description,
                }}
              />
            </ReactDevicePreview>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogOpen(false);
              setEdit(false);
            }}
            className="me-3"
          >
            {t("translations:close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PackageContent;
