import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CompanyPackage from "./CompanyPackages";
import CompanyTeam from "./CompanyTeam";
import { Tabs, Tab } from "@mui/material";
import Progress from "../../../Component/Progress/Progress";
import { BackButton } from "../../../Common/BackButton";
import axios from "../../../axios";
import { useTranslation } from "react-i18next";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CompanyProfile = () => {
  const { t } = useTranslation();
  let query = useQuery();
  const companyId = query.get("id");
  const companyName = query.get("name");
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [motivation, setMotivation] = useState([]);
  const [campaign, setCampaign] = useState([]);
  const [knowhow, setKnowhow] = useState([]);

  const handleChange = (event, newValue) => {
    setActiveStep(newValue);
  };

  useEffect(async () => {
    setLoading(true);
    axios
      .get(`/getMotivation/${companyId}`)
      .then((res) => {
        setMotivation(res?.data?.data);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
    axios
      .get(`/getKnowhow/${companyId}`)
      .then((res) => {
        setCampaign(res?.data?.data);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
    axios
      .get(`/getCampaign/${companyId}`)
      .then((res) => {
        setKnowhow(res?.data?.data);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
    setLoading(false);
  }, [companyId]);

  return loading ? (
    <Progress />
  ) : (
    <div className="mt-3">
      <div className="companies-tab default-container">
        <BackButton />
        <div className="title-heading mb-3">{companyName}</div>
        <Tabs
          value={activeStep}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label={t("translations:package_details")} {...a11yProps(0)} />
          <Tab label={t("translations:team_details")} {...a11yProps(1)} />
        </Tabs>
      </div>
      {activeStep === 0 && (
        <CompanyPackage
          motivation={motivation}
          campaign={campaign}
          knowhow={knowhow}
        />
      )}
      {activeStep === 1 && <CompanyTeam companyId={companyId} />}
    </div>
  );
};

export default CompanyProfile;
