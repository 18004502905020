import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import DoneIcon from "@mui/icons-material/Done";
import { visuallyHidden } from "@mui/utils";
import { useDispatch } from "react-redux";
import { onChangeStatus } from "../../../../../Redux/Action/Transactionaction";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import axios from "../../../../../axios";
import Progress from "../../../../../Component/Progress/Progress";
import { useTranslation } from "react-i18next";
import "./AdminTransactionDetail.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#373030",
    color: theme.palette.common.white,
    padding: "8px 0 8px 16px",
    "& > .Mui-active": {
      color: "#fff",
      "& svg": {
        opacity: 1,
        color: "#fff",
      },
    },
    "& > span:hover": {
      color: "#fff",
      opacity: 1,
      "& svg": {
        opacity: 1,
      },
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: "nowrap",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const AdminTransactionDetail = ({ transaction }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [allTransaction, setAllTransaction] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [selectedPackage, setSelectedPackage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(false);
  const [bankInfo, setBankInfo] = useState({});
  const [paypalInfo, setPaypalInfo] = useState({});

  const getBankInfo = (id) => {
    setLoading(true);
    axios
      .get(`/getBankInfo/${id}`)
      .then((res) => {
        setBankInfo(res.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const getPaypalInfo = (id) => {
    setLoading(true);
    axios
      .get(`/getPaypalInfo/${id}`)
      .then((res) => {
        setPaypalInfo(res.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const handleClickOpen = () => {
    setDialogOpen(true);
    if (selectedPackage.transactionType === "Bank Transafer") {
      getBankInfo(selectedPackage.companyId);
    } else {
      getPaypalInfo(selectedPackage.companyId);
    }
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setBankInfo({});
    setPaypalInfo({});
    setAnchorEl(null);
  };

  useEffect(() => {
    const array = [];
    transaction.forEach((item) => {
      array.push({
        ...item,
        company: item?.company?.name,
        companyId: item?.company?._id,
      });
    });
    setAllTransaction(array);
  }, [transaction]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <StyledTableCell />
          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align={"left"}
              padding={"normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
          <StyledTableCell />
        </TableRow>
      </TableHead>
    );
  }
  const headCells = [
    {
      id: "company",
      disablePadding: false,
      label: t("translations:company"),
    },
    {
      id: "createdAt",
      disablePadding: false,
      label: t("translations:date"),
    },
    {
      id: "requestAmount",
      disablePadding: false,
      label: t("translations:amount"),
    },
    {
      id: "transactionType",
      disablePadding: false,
      label: t("translations:transaction_type"),
    },
    {
      id: "status",
      disablePadding: false,
      label: t("translations:status"),
    },
  ];

  return (
    <div className="transaction-report-wrapper">
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={dialogOpen}
        fullWidth={true}
        maxWidth={"md"}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
        >
          {t("translations:receiver")}{" "}
          {Object.keys(bankInfo).length === 0
            ? t("translations:paypal")
            : t("translations:bank")}{" "}
          {t("translations:info")}
        </BootstrapDialogTitle>
        {loading ? (
          <Progress />
        ) : Object.keys(bankInfo).length !== 0 ? (
          <DialogContent dividers>
            <div
              style={{
                display: "flex",
                paddingBottom: "12px",
                fontSize: "18px",
                flexWrap: "wrap",
              }}
            >
              <div style={{ minWidth: "50%" }}>
                <span style={{ fontWeight: "700", paddingRight: "20px" }}>
                  {t("translations:swift")}:
                </span>
                <span>{bankInfo?.swift}</span>
              </div>
              <div>
                <span style={{ fontWeight: "700", paddingRight: "20px" }}>
                  {t("translations:iban")}:
                </span>
                <span>{bankInfo?.iban}</span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                paddingBottom: "12px",
                fontSize: "18px",
                flexWrap: "wrap",
              }}
            >
              <div style={{ minWidth: "50%" }}>
                <span style={{ fontWeight: "700", paddingRight: "20px" }}>
                  {t("translations:company_name")}:
                </span>
                <span>{bankInfo?.companyName}</span>
              </div>
              <div>
                <span style={{ fontWeight: "700", paddingRight: "20px" }}>
                  {t("translations:name_on_account")}:
                </span>
                <span>{bankInfo?.nameOnAccount}</span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                paddingBottom: "12px",
                fontSize: "18px",
                flexWrap: "wrap",
              }}
            >
              <div style={{ minWidth: "50%" }}>
                <span style={{ fontWeight: "700", paddingRight: "20px" }}>
                  {t("translations:address")}:
                </span>
                <span>{bankInfo?.address}</span>
              </div>
              <div>
                <span style={{ fontWeight: "700", paddingRight: "20px" }}>
                  {t("translations:city_state")}:
                </span>
                <span>{bankInfo?.city}</span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                paddingBottom: "12px",
                fontSize: "18px",
                flexWrap: "wrap",
              }}
            >
              <div style={{ minWidth: "50%" }}>
                <span style={{ fontWeight: "700", paddingRight: "20px" }}>
                  {t("translations:country")}:
                </span>
                <span>{bankInfo?.country}</span>
              </div>
              <div>
                <span style={{ fontWeight: "700", paddingRight: "20px" }}>
                  {t("translations:phone_number")}:
                </span>
                <span>{bankInfo?.phoneNo}</span>
              </div>
            </div>
          </DialogContent>
        ) : (
          <DialogContent dividers>
            <div>
              <span style={{ fontWeight: "700", paddingRight: "20px" }}>
                {t("translations:email")}:
              </span>
              <span>{paypalInfo?.email}</span>
            </div>
          </DialogContent>
        )}
      </BootstrapDialog>
      {selectedPackage?.status !== "Completed" && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              dispatch(
                onChangeStatus({
                  id: selectedPackage?._id,
                  status:
                    selectedPackage?.status === "Requested"
                      ? t("translations:approved")
                      : selectedPackage?.status === "Approved"
                      ? t("translations:processing")
                      : t("translations:completed"),
                })
              );
              handleClose();
            }}
          >
            <ListItemIcon>
              <DoneIcon fontSize="small" style={{ fill: "#008000" }} />
            </ListItemIcon>
            <ListItemText>
              {selectedPackage?.status === "Requested"
                ? t("translations:approve")
                : selectedPackage?.status === "Approved"
                ? t("translations:process")
                : t("translations:complete")}
              {t("translations:transaction")}
            </ListItemText>
          </MenuItem>

          <MenuItem onClick={() => handleClickOpen(true)}>
            <ListItemIcon>
              <AccountBalanceIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("translations:show_bank_info")}</ListItemText>
          </MenuItem>
        </Menu>
      )}
      {/* <div className="transaction-report-header">Transaction Report</div> */}
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table aria-label="customized table">
            <colgroup>
              <col style={{ width: "5%" }} />
              <col style={{ width: "18%" }} />
              <col style={{ width: "12%" }} />
              <col style={{ width: "12%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "5%" }} />
            </colgroup>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {allTransaction.length !== 0 ? (
                stableSort(allTransaction, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell>{row?.company}</StyledTableCell>
                      <StyledTableCell>
                        {moment(row?.createdAt).format("LL")}
                      </StyledTableCell>
                      <StyledTableCell>{row?.requestAmount}</StyledTableCell>
                      <StyledTableCell>{row?.transactionType}</StyledTableCell>
                      <StyledTableCell
                        style={{
                          color:
                            row?.status === "Pending" ? "#ff0000" : "#008000",
                        }}
                      >
                        {row?.status}
                      </StyledTableCell>
                      <StyledTableCell>
                        <IconButton
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={(e) => {
                            setSelectedPackage(row);
                            handleClick(e);
                          }}
                        >
                          <MoreVertRoundedIcon color="primary" />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
              ) : (
                <StyledTableCell component="th" scope="row">
                  {t("translations:not_found_transaction")}
                </StyledTableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ borderTop: "1px solid #e0e0e0" }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={transaction.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </div>
  );
};

export default AdminTransactionDetail;
