import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Profile from "../Profile/Profile";
import EditCompany from "./EditCompany/EditCompany";
import Revenue from "./Revenue/Revenue";
import coin from "../../Assets/coin.png";
import { onEditCompany } from "../../Redux/Action/Authaction";
import { useTranslation } from "react-i18next";
import "./Settings.css";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  height: 48,
  padding: theme.spacing(0, 1),
}));

const Settings = () => {
  const { selectedCompanyInfo } = useSelector((state) => state.Auth);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const dispatch = useDispatch();

  const update = async (data, selectedCompany) => {
    dispatch(onEditCompany(data));
    localStorage.setItem(
      "company",
      JSON.stringify({
        company: data?.name,
        companyId: selectedCompany?.companyId,
        role: selectedCompany?.role,
      })
    );
  };

  return (
    <div>
      <div className="setting">
        <Drawer variant="permanent" open={open}>
          {/* <Toolbar /> */}
          <DrawerHeader>
            <IconButton onClick={() => setOpen(!open)}>
              {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <List
            className="setting-sidebar"
            style={{ width: open ? "230px" : "" }}
          >
            <ListItem
              button
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              className={
                "setting-link " + (selectedTab === 0 ? "setting-active" : "")
              }
              onClick={() => setSelectedTab(0)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <PersonIcon />
              </ListItemIcon>
              {open && (
                <ListItemText
                  primary={t("translations:profile")}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              )}
            </ListItem>
          </List>
          <List className="setting-sidebar">
            <ListItem
              button
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              className={
                "setting-link " + (selectedTab === 1 ? "setting-active" : "")
              }
              onClick={() => setSelectedTab(1)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <BusinessIcon />
              </ListItemIcon>
              {open && (
                <ListItemText
                  primary={t("translations:company")}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              )}
            </ListItem>
          </List>
          <List className="setting-sidebar">
            <ListItem
              button
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              className={
                "setting-link " + (selectedTab === 2 ? "setting-active" : "")
              }
              onClick={() => setSelectedTab(2)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={coin} className="coin-image" alt="coin" />
              </ListItemIcon>
              {open && (
                <ListItemText
                  primary={
                    selectedCompanyInfo?._id === "623a23085f6b21fda23d1d2a"
                      ? "Transaction"
                      : t("translations:wallet")
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
              )}
            </ListItem>
          </List>
        </Drawer>
      </div>
      <div style={{ marginLeft: open ? "250px" : "90px" }}>
        {selectedTab === 0 && <Profile />}
        {selectedTab === 1 && (
          <EditCompany
            onUpdateCompany={(data, selectedCompany) =>
              update(data, selectedCompany)
            }
          />
        )}
        {selectedTab === 2 && <Revenue />}
      </div>
    </div>
  );
};

export default Settings;
