import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import BusinessIcon from "@mui/icons-material/Business";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import coin from "../../Assets/coin.png";
import { useTranslation } from "react-i18next";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#ff6600",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ff6600",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#1490cc",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ff6600",
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#373030",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: "nowrap",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ActiveCompany = ({ activeList, onAccept }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [clicked, setClicked] = useState(false);
  const [priceDialog, setPriceDialog] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const onAccepting = () => {
    setClicked(true);
    if (rejectReason === "") {
      return;
    } else {
      onAccept({
        id: selectedCompany?._id,
        onHold: true,
        onHoldReason: rejectReason,
        campaignPrice: selectedCompany?.campaignPrice,
      });
      setDialogOpen(false);
    }
  };

  useEffect(() => {
    setClicked(false);
  }, [dialogOpen, priceDialog]);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(orderBy) {
    return (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const onPriceChange = () => {
    setClicked(true);
    if (selectedCompany?.campaignPrice <= 0) {
      return;
    } else {
      onAccept({
        id: selectedCompany?._id,
        onHold: false,
        onHoldReason: "",
        campaignPrice: selectedCompany?.campaignPrice,
      });
      setPriceDialog(false);
    }
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() =>
            history.push(
              `/companies/companyProfile?id=${selectedCompany?._id}&name=${selectedCompany?.name}`
            )
          }
        >
          <ListItemIcon>
            <BusinessIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("translations:go_to_company_profile")}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDialogOpen(true);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("translations:on_hold")}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setPriceDialog(true);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <img src={coin} className="coin-image" alt="coin" />
          </ListItemIcon>
          <ListItemText>{t("translations:change_campaign_price")}</ListItemText>
        </MenuItem>
      </Menu>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <DialogTitle>
          {`${t("translations:hold_message")} ${selectedCompany?.name}?`}
        </DialogTitle>
        <DialogContent>
          {
            <div style={{ paddingTop: "10px" }}>
              <CssTextField
                value={rejectReason}
                multiline
                rows={4}
                label={t("translations:reason")}
                type="text"
                className="w-100 mb-3"
                id="custom-css-outlined-input"
                autoComplete="off"
                placeholder={t("translations:reason_placeholder")}
                helperText={
                  clicked && rejectReason === ""
                    ? t("translations:reason_error")
                    : ""
                }
                error={clicked && rejectReason === ""}
                onChange={(e) => setRejectReason(e.target.value)}
              />
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>
            {t("translations:cancel")}
          </Button>
          <Button
            onClick={() => {
              onAccepting();
            }}
            autoFocus
          >
            {t("translations:ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={priceDialog}
        onClose={() => {
          setPriceDialog(false);
        }}
      >
        <DialogTitle>
          {`${t("translations:campaign_dialog")} ${selectedCompany?.name}`}
        </DialogTitle>
        <DialogContent>
          {
            <CssTextField
              value={selectedCompany?.campaignPrice}
              label={t("translations:campaign_price")}
              type="number"
              className="w-100 mb-3 mt-3"
              id="custom-css-outlined-input"
              autoComplete="off"
              placeholder={t("translations:campaign_price_placeholder")}
              helperText={
                clicked && selectedCompany?.campaignPrice <= 0
                  ? t("translations:campaign_price_error")
                  : ""
              }
              InputLabelProps={{
                shrink: true,
              }}
              error={clicked && selectedCompany?.campaignPrice <= 0}
              onChange={(e) =>
                setSelectedCompany({
                  ...selectedCompany,
                  campaignPrice: e.target.value,
                })
              }
            />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPriceDialog(false)}>
            {t("translations:cancel")}
          </Button>
          <Button
            onClick={() => {
              onPriceChange();
            }}
            autoFocus
          >
            {t("translations:ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table aria-label="customized table">
            <colgroup>
              <col style={{ width: "5%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "21%" }} />
              <col style={{ width: "13%" }} />
              <col style={{ width: "13%" }} />
              <col style={{ width: "13%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "5%" }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell>
                  {t("translations:company_name")}
                </StyledTableCell>
                <StyledTableCell>
                  {t("translations:company_address")}
                </StyledTableCell>
                <StyledTableCell>{t("translations:user_name")}</StyledTableCell>
                <StyledTableCell>
                  {t("translations:user_email")}
                </StyledTableCell>
                <StyledTableCell>
                  {t("translations:created_on")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {t("translations:campaign_price")}
                </StyledTableCell>
                <StyledTableCell />
              </TableRow>
            </TableHead>
            {/* <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            /> */}

            <TableBody>
              {activeList.length !== 0 ? (
                stableSort(activeList, getComparator("title"))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          history.push(
                            `/companies/companyProfile?id=${row?._id}&name=${row?.name}`
                          )
                        }
                      >
                        {row?.name}
                      </StyledTableCell>
                      <StyledTableCell>{row?.address}</StyledTableCell>
                      <StyledTableCell>{`${row?.user[0]?.firstName} ${row?.user[0]?.lastName}`}</StyledTableCell>
                      <StyledTableCell>{row?.user[0]?.email}</StyledTableCell>
                      <StyledTableCell>
                        {moment(row?.createdAt).format("LL")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.campaignPrice}
                      </StyledTableCell>
                      <StyledTableCell>
                        <IconButton
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={(e) => {
                            setSelectedCompany(row);
                            handleClick(e);
                          }}
                        >
                          <MoreVertRoundedIcon color="primary" />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
              ) : (
                <StyledTableCell component="th" scope="row">
                  {t("translations:no_company_found")}
                </StyledTableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ borderTop: "1px solid #e0e0e0" }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={activeList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </div>
  );
};

export default ActiveCompany;
