import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { TextField, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import { editBankInfo } from "../../../../../Redux/Action/BankInfoaction";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#ff6600",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ff6600",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#1490cc",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ff6600",
    },
  },
});

const BankForm = ({ bankInfo }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedCompanyInfo } = useSelector((state) => state.Auth);
  const [data, setData] = useState({
    swift: "",
    iban: "",
    companyName: "",
    firstName: "",
    lastName: "",
    nameOnAccount: "",
    address: "",
    city: "",
    country: "",
    phoneNo: "",
    auth: false,
  });
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    setData({ ...bankInfo });
  }, [bankInfo]);

  const validate = () => {
    if (
      data?.swift === "" ||
      data.iban === "" ||
      data?.firstName === "" ||
      data?.lastName === "" ||
      data?.nameOnAccount === "" ||
      data?.address === "" ||
      data?.city === "" ||
      data?.country === "" ||
      data?.phoneNo === "" ||
      data?.auth === false
    ) {
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    setClicked(true);
    const isValid = validate();
    if (isValid) {
      dispatch(editBankInfo({ ...data, company: selectedCompanyInfo?._id }));
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-sm">
          <CssTextField
            value={data?.swift}
            label={`${t("translations:swift")}*`}
            type="string"
            className="w-100 mb-3 mt-3"
            placeholder={t("translations:swift")}
            error={clicked && data?.swift === ""}
            helperText={
              clicked && data?.swift === "" ? t("translations:swift_error") : ""
            }
            onChange={(e) => setData({ ...data, swift: e.target.value })}
          />
        </div>
        <div className="col-sm">
          <CssTextField
            value={data?.iban}
            label={`${t("translations:iban")}*`}
            type="string"
            className="w-100 mb-3 mt-3"
            placeholder={t("translations:iban_no")}
            error={clicked && data?.iban === ""}
            helperText={
              clicked && data?.iban === "" ? t("translations:iban_error") : ""
            }
            onChange={(e) => setData({ ...data, iban: e.target.value })}
          />
        </div>
        <div className="col-sm">
          <CssTextField
            value={data?.companyName}
            label={t("translations:company_name")}
            type="string"
            className="w-100 mb-3 mt-3"
            placeholder={t("translations:company_name")}
            // error={clicked && data?.companyName === ""}
            // helperText={clicked && data?.companyName === "" ? "Company Name is required" : ""}
            onChange={(e) => setData({ ...data, companyName: e.target.value })}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm">
          <CssTextField
            value={data?.nameOnAccount}
            label={`${t("translations:name_on_account")}`}
            type="string"
            className="w-100 mb-3 mt-3"
            placeholder={t("translations:name_on_account")}
            error={clicked && data?.nameOnAccount === ""}
            helperText={
              clicked && data?.nameOnAccount === ""
                ? t("translations:name_error")
                : ""
            }
            onChange={(e) =>
              setData({ ...data, nameOnAccount: e.target.value })
            }
          />
        </div>
        <div className="col-sm">
          <CssTextField
            value={data?.firstName}
            label={`${t("translations:first_name")}*`}
            type="string"
            className="w-100 mb-3 mt-3"
            placeholder={t("translations:first_name")}
            error={clicked && data?.firstName === ""}
            helperText={
              clicked && data?.firstName === ""
                ? t("translations:first_name_error")
                : ""
            }
            onChange={(e) => setData({ ...data, firstName: e.target.value })}
          />
        </div>
        <div className="col-sm">
          <CssTextField
            value={data?.lastName}
            label={`${t("translations:last_name")}*`}
            type="string"
            className="w-100 mb-3 mt-3"
            placeholder={t("translations:last_name")}
            error={clicked && data?.lastName === ""}
            helperText={
              clicked && data?.lastName === ""
                ? t("translations:last_name_error")
                : ""
            }
            onChange={(e) => setData({ ...data, lastName: e.target.value })}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm">
          <CssTextField
            value={data?.address}
            label={`${t("translations:address")}*`}
            type="string"
            className="w-100 mb-3 mt-3"
            placeholder={t("translations:address")}
            error={clicked && data?.address === ""}
            helperText={
              clicked && data?.address === ""
                ? t("translations:address_is_required")
                : ""
            }
            onChange={(e) => setData({ ...data, address: e.target.value })}
          />
        </div>
        <div className="col-sm">
          <CssTextField
            value={data?.city}
            label={t("translations:city_state")}
            type="string"
            className="w-100 mb-3 mt-3"
            placeholder={t("translations:city_state")}
            error={clicked && data?.city === ""}
            helperText={
              clicked && data?.city === "" ? t("translations:city_error") : ""
            }
            onChange={(e) => setData({ ...data, city: e.target.value })}
          />
        </div>
        <div className="col-sm">
          <CssTextField
            value={data?.country}
            label={t("translations:country")}
            type="string"
            className="w-100 mb-3 mt-3"
            placeholder={t("translations:country")}
            error={clicked && data?.country === ""}
            helperText={
              clicked && data?.country === ""
                ? t("translations:country_error")
                : ""
            }
            onChange={(e) => setData({ ...data, country: e.target.value })}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm">
          <CssTextField
            value={data?.phoneNo}
            label={t("translations:phone_number")}
            type="string"
            className="w-100 mb-3 mt-3"
            placeholder={t("translations:phone_number")}
            error={clicked && data?.phoneNo === ""}
            helperText={
              clicked && data?.phoneNo === ""
                ? t("translations:phone_error")
                : ""
            }
            onChange={(e) => setData({ ...data, phoneNo: e.target.value })}
          />
        </div>
      </div>
      <div className="add-header-part">
        <Checkbox
          checked={data?.auth}
          onChange={() => setData({ ...data, auth: !data?.auth })}
        />
        <span className="checkbox-text">{t("translations:check_payment")}</span>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <button className="px-4 profile-button" onClick={(e) => onSubmit()}>
          {t("translations:save")}
        </button>
      </div>
    </div>
  );
};

export default BankForm;
