import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as Authaction from "./Redux/Action/Authaction";
import SignIn from "./Pages/SignIn/SignIn";
import SignUp from "./Pages/SignUp/SignUp";
import Dashboard from "./Pages/Dashboard/Dashboard";
import CompleteProfile from "./Pages/CompleteProfile/CompleteProfile";
import MainHeader from "./Component/MainHeader/MainHeader";
import KnowHow from "./Pages/KnowHow/KnowHow";
import Motivation from "./Pages/Motivation/Motivation";
import Campaigns from "./Pages/Campaigns/Campaigns";
import Support from "./Pages/Support/Support";
import Progress from "./Component/Progress/Progress";
import CreatePackage from "./Pages/CreatePackage/CreatePackage";
import Profile from "./Pages/Profile/Profile";
import CreateCampaign from "./Pages/CreateCampaign/CreateCampaign";
import CreateKnowHow from "./Pages/CreateKnowHow/CreateKnowHow";
import NotFound from "./Pages/NotFound/NotFound";
import Companies from "./Pages/Companies/Companies";
import Packages from "./Pages/Packages/Packages";
import VerificationInfoPage from "./Pages/VerificationInfoPage/index";
import Team from "./Pages/Team/Team";
import axios from "./axios";
import Settings from "./Pages/Settings/Settings";
import jwt_decode from "jwt-decode";
import CompanyProfile from "./Pages/Companies/CompanyProfile/CompanyProfile";
import ViewPackage from "./Pages/Packages/ViewPackages/ViewPackage";
import { useTranslation } from "react-i18next";

const Routes = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const localToken = localStorage.getItem("token");
  const decodedToken = localToken && jwt_decode(localToken);
  const company = JSON.parse(localStorage.getItem("company"));
  const { token, user, selectedCompanyInfo, selectedCompany, loading } =
    useSelector((state) => state.Auth);
  const [load, setLoad] = useState(false);
  const selectedLanguage = localStorage.getItem("i18nextLng");

  useEffect(async () => {
    if (decodedToken !== null) {
      if (
        user === undefined ||
        user === null ||
        Object.keys(user).length === 0
      ) {
        await getUser();
      }
    }
  }, [decodedToken]);

  useEffect(() => {
    selectedLanguage && i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const getUser = () => {
    setLoad(true);
    if (decodedToken !== null) {
      setLoad(true);
      axios
        .get(`user/${decodedToken?._id}`)
        .then((res) => {
          dispatch(
            Authaction.storeuser({
              user: res?.data,
            })
          );
          setLoad(false);
        })
        .catch((e) => {
          console.error(e);
          setLoad(false);
        });
    } else {
      setLoad(false);
    }
  };

  useEffect(() => {
    dispatch(Authaction.setSelectedCompany(company));
    dispatch(Authaction.setSasToken());
  }, []);

  const getCompany = () => {
    setLoad(true);
    axios
      .get(`/getCompanyById/${company?.companyId}`)
      .then((res) => {
        dispatch(Authaction.setSelectedCompanyInfo(res.data));
        setLoad(false);
      })
      .catch((e) => {
        console.error(e);
        setLoad(false);
      });
  };

  useEffect(async () => {
    if (company !== null) {
      if (
        selectedCompany.companyId !== undefined ||
        selectedCompany.companyId !== null
      ) {
        setLoad(true);
        getCompany();
      }
    }
  }, [selectedCompany]);

  let route = (
    <Switch>
      <Route exact path="/" component={SignIn} />
      <Route exact path="/signUp" component={SignUp} />
      <Route path="*" component={SignIn} />
      {/*<Route exact path="/completeProfile">*/}
      {/*  <CompleteProfile getUser={getUser}/>*/}
      {/*</Route>*/}
      {/*user?.completeProfile ? <Redirect to="/" /> : <Redirect to="/completeProfile" />*/}
    </Switch>
  );
  if ((token || localToken) && Object.keys(user).length !== 0) {
    if (user?.completeProfile) {
      if (
        selectedCompanyInfo?.onHold === false ||
        Object.keys(selectedCompanyInfo).length === 0
      ) {
        route = (
          <MainHeader>
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/motivation" component={Motivation} />
              <Route exact path="/know-how" component={KnowHow} />
              <Route exact path="/campaigns" component={Campaigns} />
              <Route exact path="/support" component={Support} />
              <Route
                exact
                path="/motivation/createpackage"
                component={CreatePackage}
              />
              <Route
                exact
                path="/motivation/motivationDetail"
                component={CreatePackage}
              />
              <Route
                exact
                path="/campaigns/createCampaign"
                component={CreateCampaign}
              />
              <Route
                exact
                path="/campaigns/campaignDetail"
                component={CreateCampaign}
              />
              <Route
                exact
                path="/know-how/createknowhow"
                component={CreateKnowHow}
              />
              <Route
                exact
                path="/know-how/knowhowDetail"
                component={CreateKnowHow}
              />
              <Route exact path="/myprofile" component={Settings} />
              {selectedCompany?.companyId &&
                selectedCompany?.companyId === "623a23085f6b21fda23d1d2a" && (
                  <Route
                    exact
                    path="/companies/companyProfile"
                    component={CompanyProfile}
                  />
                )}
              {selectedCompany?.role === "admin" && (
                <Route exact path="/team" component={Team} />
              )}
              {selectedCompany?.companyId &&
                selectedCompany?.companyId === "623a23085f6b21fda23d1d2a" && (
                  <Route exact path="/companies" component={Companies} />
                )}
              {selectedCompany?.companyId &&
                selectedCompany?.companyId === "623a23085f6b21fda23d1d2a" && (
                  <Route exact path="/packages" component={Packages} />
                )}
              {selectedCompany?.companyId &&
                selectedCompany?.companyId === "623a23085f6b21fda23d1d2a" && (
                  <Route
                    exact
                    path="/packages/packageDetails"
                    component={ViewPackage}
                  />
                )}
              <Route path="*" component={NotFound} />
            </Switch>
          </MainHeader>
        );
      } else {
        route = (
          <Switch>
            <MainHeader>
              <Route
                exact
                path="/verificationinfo"
                component={VerificationInfoPage}
              />
              <Route exact path="/myprofile" component={Profile} />
              <Route path="*" component={VerificationInfoPage} />
            </MainHeader>
          </Switch>
        );
      }
    } else {
      route = (
        <Switch>
          <Route exact path="/completeProfile">
            <CompleteProfile />
          </Route>
          <Route path="*" component={CompleteProfile} />
        </Switch>
      );
    }
  }

  return loading || load ? <Progress /> : <>{route}</>;
};

export default Routes;
