import LanguageApiService from "../Services/LanguageApiService";
import i18n from "i18next";

export const languageInitialize = async () => {
  const languages = await LanguageApiService.getLanguages();
  const translation = {};
  await languages.forEach(async (element) => {
    translation[element.code] = {};
    const translations = await LanguageApiService.getTranslation(element.code);
    translations.forEach((word) => {
      translation[element.code][word.context] = word.translation.content;
    });
    i18n.addResourceBundle(
      element.code,
      "translations",
      translation[element.code]
    );
  });
  return translation;
};
