import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CreateIcon from "@mui/icons-material/Create";
import AddIcon from "@mui/icons-material/Add";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import CreateFirstPage from "./CreateFirstPage";
import AddContent from "./AddContent";
import Publish from "./Publish";
import Analystics from "./Analystics";
import axios from "../../axios";
import Progress from "../../Component/Progress/Progress";
import { message } from "antd";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { BackButton } from "../../Common/BackButton";
import { useTranslation } from "react-i18next";

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <CreateIcon />,
    2: <AddIcon />,
    3: <PublishedWithChangesIcon />,
  };
  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CreatePackage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  let query = useQuery();
  const id = query.get("id");
  const steps = [
    t("translations:create_package"),
    t("translations:add_content"),
    t("translations:publish_package"),
  ];
  const pendingPublish = [
    t("translations:edit_motivation"),
    t("translations:add_content"),
    t("translations:publish_package"),
  ];
  const [loading, setLoading] = useState(false);
  const [motivationId, setMotivationId] = useState(
    localStorage.getItem("motivationId")
  );
  const [activeStep, setActiveStep] = useState(0);
  const [motivation, setMotivation] = useState({
    title: "",
    description: "",
    image: "",
    price: 0,
    _id: "",
  });
  const [created, setCreated] = useState(false);
  const [allContent, setAllContent] = useState([]);
  const [oldMotivation, setOldMotivation] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const { user, selectedCompany } = useSelector((state) => state.Auth);

  useEffect(() => {
    if (id) {
      setMotivationId(id);
      setOldMotivation(true);
    }
  }, [id]);

  useEffect(() => {
    setLoading(true);
    axios
      .get("/getCategories")
      .then((res) => {
        const data = [];
        res?.data &&
          res?.data?.forEach((item) => {
            data.push(item?.title);
          });
        setAllCategories(data);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  }, []);

  useEffect(async () => {
    if (motivationId !== "undefined" && motivationId !== null) {
      setLoading(true);
      axios
        .get(`getMotivationById/${motivationId}`)
        .then((res) => {
          res?.data && setMotivation(res.data);
          setCreated(true);
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
      getContent();
    }
  }, [motivationId]);

  const getContent = () => {
    setLoading(true);
    axios
      .get(`/getContent/${motivationId}`)
      .then((res) => {
        res?.data && setAllContent(res.data);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  const onCreateMotivation = (data) => {
    setLoading(true);
    axios
      .post("/createMotivation", {
        ...data,
        userId: user?._id,
        companyId: selectedCompany?.companyId,
      })
      .then((res) => {
        setMotivation(res?.data?.motivation);
        localStorage.setItem("motivationId", res?.data?.motivation?._id);
        setCreated(true);
        setActiveStep(1);
        setLoading(false);
        message.success(t("translations:motivation_create_success"));
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        message.error(t("translations:motivation_create_error"));
      });
  };

  const onUpdateMotivation = (data) => {
    setLoading(true);
    axios
      .patch(`/editMotivation/${motivation?._id}`, { ...data })
      .then((res) => {
        setMotivation({
          ...motivation,
          title: data?.title,
          description: data?.description,
          price: data?.price,
          showHeader: data?.showHeader,
          showFooter: data?.showFooter,
        });
        setLoading(false);
        setActiveStep(1);
        message.success(t("translations:motivation_update_success"));
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        message.error(t("translations:motivation_update_error"));
      });
  };

  const onAddContent = (data) => {
    setLoading(true);
    axios
      .post("addContent", {
        description: data?.description,
        motivationId: motivation?._id,
        order: data?.order,
      })
      .then((res) => {
        if (res.data) {
          const newData = allContent;
          newData.push(res.data);
          setAllContent(newData);
          setLoading(false);
          message.success(t("translations:content_add_success"));
        }
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        message.error(t("translations:content_add_error"));
      });
  };

  const onEditContent = (data) => {
    setLoading(true);
    axios
      .patch(`/editContent/${data?.selectedId}`, {
        description: data?.description,
      })
      .then((res) => {
        if (res.status === 200) {
          getContent();
          message.success(t("translations:content_update_success"));
          setLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        message.error(t("translations:content_update_error"));
        setLoading(false);
      });
  };

  const onDeleteContent = (selectedId, selectedIndex) => {
    setLoading(true);
    axios
      .delete(`/deleteContent/${selectedId}`)
      .then((res) => {
        if (res.status === 200) {
          const newData = allContent;
          newData.splice(selectedIndex, 1);
          setAllContent(newData);
          setLoading(false);
          message.success(t("translations:content_delete_success"));
        }
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        message.error(t("translations:content_delete_error"));
      });
  };

  const onPublish = (data, publish) => {
    setLoading(true);
    const datas = publish ? { status: data, publish: true } : { status: data };
    axios
      .post(`/publish/${motivation?._id}`, datas)
      .then((res) => {
        if (res.status === 200) {
          message.success(t("translations:motivation_publish_success"));
          history.push("/motivation");
          localStorage.removeItem("motivationId");
        }
      })
      .catch((e) => {
        message.error(t("translations:motivation_publish_error"));
        console.error(e);
        setLoading(false);
      });
  };

  const handleChange = (event, newValue) => {
    setActiveStep(newValue);
  };

  return loading ? (
    <Progress />
  ) : (
    <div style={{ paddingTop: "20px" }}>
      <div className="default-container">
        <BackButton />
      </div>
      {motivation?.publish ? (
        <div className="motiavtion-tab default-container">
          <Tabs
            value={activeStep}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label={t("translations:edit_motivation")} {...a11yProps(0)} />
            <Tab label={t("translations:add_content")} {...a11yProps(1)} />
          </Tabs>
        </div>
      ) : (
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {oldMotivation && !motivation?.publish
              ? pendingPublish.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))
              : steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
          </Stepper>
        </Stack>
      )}
      {loading ? (
        <Progress />
      ) : (
        <div className="default-container">
          {activeStep === 0 && (
            <CreateFirstPage
              motivation={motivation}
              created={created}
              onAddMotivation={(data) => onCreateMotivation(data)}
              onChange={(id) => setActiveStep(id)}
              onEditMotivation={(data) => onUpdateMotivation(data)}
              allCategories={allCategories}
              oldMotivation={oldMotivation}
              onForward={() => setActiveStep(1)}
            />
          )}
          {activeStep === 1 && (
            <AddContent
              allContent={allContent}
              motivation={motivation}
              onChange={(id) => setActiveStep(id)}
              onAddingContent={(data) => {
                onAddContent(data);
              }}
              onEditingContent={(data) => onEditContent(data)}
              onDeletingContent={(selectedId, selectedIndex) =>
                onDeleteContent(selectedId, selectedIndex)
              }
              oldMotivation={oldMotivation}
              motivationPublish={motivation?.publish}
            />
          )}
          {activeStep === 2 &&
            oldMotivation &&
            motivation?.publish === true && (
              <Analystics onChange={(id) => setActiveStep(id)} />
            )}
          {activeStep === 2 && motivation?.publish === false && (
            <Publish
              onChange={(id) => setActiveStep(id)}
              onPublish={(data, status) => onPublish(data, status)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CreatePackage;
