import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RESET_ON_EDIT_COMPANY_ERROR } from "../../../Redux/Action/ActionType";
import EditIcon from "@mui/icons-material/Edit";
import logo from "../../../Assets/logo.png";
import { TextField } from "@mui/material";
import Progress from "../../../Component/Progress/Progress";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { onAddCompany } from "../../../Redux/Action/Authaction";
import { message } from "antd";
import axios from "../../../axios";
import { useTranslation } from "react-i18next";
import "./EditCompany.css";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#ff6600",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ff6600",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#1490cc",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ff6600",
    },
  },
});

const EditCompany = ({ onUpdateCompany }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    error,
    selectedCompanyInfo,
    selectedCompany,
    updateMessage,
    sas,
    loading,
    user,
  } = useSelector((state) => state.Auth);
  const [addCompanyData, setAddCompanyData] = useState({
    company: "",
    address: "",
    companyBio: "",
    introductionLink: "",
  });
  const [selectedFile, setSelectedFile] = useState();
  const [imageUrl, setImageUrl] = useState(undefined);
  const [companyData, setCompanyData] = useState();
  const [clicked, setClicked] = useState(false);
  const [load, setLoad] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [companyAddClicked, setCompanyAddClicked] = useState(false);
  const [imageError, setImageError] = useState("");

  useEffect(() => {
    setCompanyData({ ...selectedCompanyInfo });
  }, [selectedCompanyInfo]);

  useEffect(() => {
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setImageUrl(objectUrl);
    }
  }, [selectedFile]);

  useEffect(() => {
    if (updateMessage !== "") {
      message.success(updateMessage);
      dispatch({ type: RESET_ON_EDIT_COMPANY_ERROR });
    }
  }, [updateMessage]);

  useEffect(() => {
    if (error !== "") {
      message.error(error);
      dispatch({ type: RESET_ON_EDIT_COMPANY_ERROR });
    }
  }, [error]);

  const validate = () => {
    if (companyData?.name === "" || companyData?.address === "") {
      return false;
    } else {
      return true;
    }
  };

  const uploadFile = (type) => {
    if (selectedFile.length !== 0) {
      const form = new FormData();
      form.append("file", selectedFile);
      setLoad(true);
      axios
        .post("/uploading", form)
        .then((res) => {
          if (type === "old") {
            onUpdate({
              ...companyData,
              id: companyData?._id,
              logo: res.data.blobName,
            });
          } else {
            onCreateNewCompany(res.data.blobName);
          }
          setLoad(false);
        })
        .catch((e) => {
          console.error(e);
          message.error(t("translations:profile"));
          setLoad(false);
        });
    }
  };

  const onUpdate = (data) => {
    onUpdateCompany(data, selectedCompany);
  };

  const onSubmit = () => {
    setClicked(true);
    const isValid = validate();
    if (isValid) {
      if (selectedFile) {
        uploadFile("old");
      } else {
        onUpdate({ ...companyData, id: companyData?._id });
      }
    }
  };

  const onCloseDialog = () => {
    setDialogOpen(false);
    setCompanyAddClicked(false);
    setSelectedFile(null);
    setAddCompanyData({ company: "", address: "" });
  };

  const onClickAdd = () => {
    setCompanyAddClicked(true);
    if (
      selectedFile &&
      addCompanyData?.company !== "" &&
      addCompanyData?.address !== ""
    ) {
      uploadFile("new");
      console.log("in add");
    } else {
      setImageError(t("translations:all_field_is_required"));
    }
  };

  const onCreateNewCompany = (title) => {
    dispatch(
      onAddCompany({
        name: addCompanyData?.company,
        address: addCompanyData?.address,
        companyBio: addCompanyData?.companyBio,
        introductionLink: addCompanyData?.introductionLink,
        userId: user?._id,
        logo: title,
      })
    );
    onCloseDialog();
  };

  return loading || load ? (
    <Progress />
  ) : (
    <div>
      <div
        className="d-flex justify-content-end mt-4"
        style={{ marginRight: "3.3rem" }}
      >
        <button
          className="px-4 profile-button"
          style={{ width: "fit-content" }}
          onClick={() => setDialogOpen(true)}
        >
          {t("translations:create_new_company")}
        </button>
      </div>
      <div className="edit-company-wrapper">
        <div className="company">
          <div className="company-heading">{t("translations:company")}</div>
          <div className="logo-part">
            <div className="img-wrapper">
              {" "}
              <img
                className="company-logo"
                alt="logo"
                src={
                  !selectedFile && companyData?.logo === ""
                    ? logo
                    : selectedFile
                    ? imageUrl
                    : `https://yeapportal.blob.core.windows.net/yeapportal/${companyData?.logo}?${sas}`
                }
              />
              {selectedCompany?.role === "admin" && (
                <div className="edit-wrapper">
                  <input
                    type="file"
                    className="company-input"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                    accept="image/*"
                    title=""
                  ></input>
                  <EditIcon />
                </div>
              )}
            </div>
          </div>
          <div className="company-info col-10">
            <CssTextField
              value={companyData?.name}
              label={t("translations:name")}
              type="text"
              // variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              className="w-100 mb-3"
              autoComplete="off"
              onChange={(e) =>
                setCompanyData({ ...companyData, name: e.target.value })
              }
              error={clicked && companyData?.name === ""}
              helperText={
                clicked && companyData?.name === ""
                  ? t("translations:company_name_error")
                  : ""
              }
            />
            <CssTextField
              value={companyData?.address}
              label={t("translations:address")}
              type="text"
              // variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              className="w-100 mb-3"
              autoComplete="off"
              onChange={(e) =>
                setCompanyData({ ...companyData, address: e.target.value })
              }
              error={clicked && companyData?.address === ""}
              helperText={
                clicked && companyData?.address === ""
                  ? t("translations:comapny_address_error")
                  : ""
              }
            />
            <CssTextField
              value={companyData?.introductionLink}
              label={t("translations:company_introduction_link")}
              type="text"
              // variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              className="w-100 mb-3"
              autoComplete="off"
              onChange={(e) =>
                setCompanyData({
                  ...companyData,
                  introductionLink: e.target.value,
                })
              }
              // error={clicked && companyData?.introductionLink === ""}
              // helperText={
              //   clicked && companyData?.introductionLink === ""
              //     ? t("translations:comapny_address_error")
              //     : ""
              // }
            />
            <CssTextField
              value={companyData?.companyBio}
              label={t("translations:company_bio")}
              type="text"
              // variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              className="w-100 mb-3"
              autoComplete="off"
              multiline
              rows={3}
              onChange={(e) =>
                setCompanyData({ ...companyData, companyBio: e.target.value })
              }
              // error={clicked && companyData?.address === ""}
              // helperText={
              //   clicked && companyData?.address === ""
              //     ? t("translations:comapny_address_error")
              //     : ""
              // }
            />

            {selectedCompany?.role === "admin" && (
              <div className="d-flex justify-content-center mt-4">
                <button
                  className="px-4 profile-button"
                  onClick={() => onSubmit()}
                >
                  {t("translations:update")}
                </button>
              </div>
            )}
          </div>
        </div>
        <Dialog
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false);
            setSelectedFile(null);
            setImageUrl(undefined);
          }}
          fullWidth={true}
        >
          <DialogTitle>{t("translations:create_company")}</DialogTitle>
          <DialogContent>
            <>
              <div>
                <CssTextField
                  value={addCompanyData?.company}
                  label={t("translations:your_company_name")}
                  type="text"
                  className="w-100 mb-3 mt-3"
                  error={companyAddClicked && addCompanyData?.company === ""}
                  helperText={
                    companyAddClicked && addCompanyData?.company === ""
                      ? t("translations:company_name_error")
                      : ""
                  }
                  onChange={(e) =>
                    setAddCompanyData({
                      ...addCompanyData,
                      company: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <CssTextField
                  value={addCompanyData?.address}
                  label={t("translations:main_address")}
                  type="text"
                  className="w-100 mb-3"
                  autoComplete="off"
                  error={companyAddClicked && addCompanyData?.address === ""}
                  helperText={
                    companyAddClicked && addCompanyData?.address === ""
                      ? t("translations:address_is_required")
                      : ""
                  }
                  onChange={(e) =>
                    setAddCompanyData({
                      ...addCompanyData,
                      address: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <CssTextField
                  value={addCompanyData?.introductionLink}
                  label={t("translations:company_introduction_link")}
                  type="text"
                  className="w-100 mb-3"
                  autoComplete="off"
                  // error={companyAddClicked && addCompanyData?.address === ""}
                  // helperText={
                  //   companyAddClicked && addCompanyData?.address === ""
                  //     ? t("translations:address_is_required")
                  //     : ""
                  // }
                  onChange={(e) =>
                    setAddCompanyData({
                      ...addCompanyData,
                      introductionLink: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <CssTextField
                  value={addCompanyData?.companyBio}
                  label={t("translations:company_bio")}
                  type="text"
                  className="w-100 mb-3"
                  autoComplete="off"
                  multiline
                  rows={3}
                  // error={companyAddClicked && addCompanyData?.address === ""}
                  // helperText={
                  //   companyAddClicked && addCompanyData?.address === ""
                  //     ? t("translations:address_is_required")
                  //     : ""
                  // }
                  onChange={(e) =>
                    setAddCompanyData({
                      ...addCompanyData,
                      companyBio: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <label className="logo">
                  {t("translations:logo")}
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setImageError("");
                      setSelectedFile(e.target.files[0]);
                    }}
                    accept="image/*"
                  ></input>
                </label>
                {selectedFile && (
                  <img className="selected-logo" src={imageUrl} alt="Logo" />
                )}
              </div>
              {imageError && (
                <div className="error-message" style={{ marginLeft: "0px" }}>
                  {imageError}
                </div>
              )}
            </>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onCloseDialog()}>
              {t("translations:cancel")}
            </Button>
            <Button onClick={() => onClickAdd()}>
              {t("translations:add")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default EditCompany;
