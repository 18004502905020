export const STORE_TOKEN_SUCEESS = "STORE_TOKEN_SUCEESS";
export const STORE_TOKEN_FAILED = "STORE_TOKEN_FAILED";

export const COMPLETE_PROFILE_SUCCESS = "COMPLETE_PROFILE_SUCCESS";

export const SET_SELECTED_COMPANY = "SET_SELECTED_COMPANY";

export const SET_SELECTED_COMPANYINFO = "SET_SELECTED_COMPANYINFO";

export const GET_ALL_COMPANIES_LOADING = "GET_ALL_COMPANIES_LOADING";
export const GET_ALL_COMPANIES_ERROR = "GET_ALL_COMPANIES_ERROR";
export const GET_ALL_COMPANIES_SUCCESS = "GET_ALL_COMPANIES_SUCCESS";
export const RESET_GET_ALL_COMPANIES_ERROR = "RESET_GET_ALL_COMPANIES_ERROR";

export const ACCEPT_REJECT_COMPANY_LOADING = "ACCEPT_REJECT_COMPANY_LOADING";
export const ACCEPT_REJECT_COMPANY_ERROR = "ACCEPT_REJECT_COMPANY_ERROR";
export const ACCEPT_REJECT_COMPANY_SUCCESS = "ACCEPT_REJECT_COMPANY_SUCCESS";

export const GET_ALL_PACKAGES_LOADING = "GET_ALL_PACKAGES_LOADING";
export const GET_ALL_PACKAGES_ERROR = "GET_ALL_PACKAGES_ERROR";
export const GET_ALL_PACKAGES_SUCCESS = "GET_ALL_PACKAGES_SUCCESS";
export const RESET_GET_ALL_PACKAGES_ERROR = "RESET_GET_ALL_PACKAGES_ERROR";

export const GET_ALL_KNOWHOW_LOADING = "GET_ALL_KNOWHOW_LOADING";
export const GET_ALL_KNOWHOW_ERROR = "GET_ALL_KNOWHOW_ERROR";
export const GET_ALL_KNOWHOW_SUCCESS = "GET_ALL_KNOWHOW_SUCCESS";

export const GET_ALL_CAMPAIGN_LOADING = "GET_ALL_CAMPAIGN_LOADING";
export const GET_ALL_CAMPAIGN_ERROR = "GET_ALL_CAMPAIGN_ERROR";
export const GET_ALL_CAMPAIGN_SUCCESS = "GET_ALL_CAMPAIGN_SUCCESS";

export const ACCEPT_REJECT_PACKAGE_LOADING = "ACCEPT_REJECT_PACKAGE_LOADING";
export const ACCEPT_REJECT_PACKAGE_ERROR = "ACCEPT_REJECT_PACKAGE_ERROR";
export const ACCEPT_REJECT_PACKAGE_SUCCESS = "ACCEPT_REJECT_PACKAGE_SUCCESS";

export const ACCEPT_REJECT_KNOWHOW_SUCCESS = "ACCEPT_REJECT_KNOWHOW_SUCCESS";

export const ACCEPT_REJECT_CAMPAIGN_SUCCESS = "ACCEPT_REJECT_CAMPAIGN_SUCCESS";

export const SET_SAS_TOKEN = "SET_SAS_TOKEN";

export const ON_EDIT_COMPANY_LOADING = "ON_EDIT_COMPANY_LOADING";
export const ON_EDIT_COMPANY_ERROR = "ON_EDIT_COMPANY_ERROR";
export const ON_EDIT_COMPANY_SUCCESS = "ON_EDIT_COMPANY_SUCCESS";
export const RESET_ON_EDIT_COMPANY_ERROR = "RESET_ON_EDIT_COMPANY_ERROR";

export const ON_EDIT_PROFILE_LOADING = "ON_EDIT_PROFILE_LOADING";
export const ON_EDIT_PROFILE_ERROR = "ON_EDIT_PROFILE_ERROR";
export const ON_EDIT_PROFILE_SUCCESS = "ON_EDIT_PROFILE_SUCCESS";
export const RESET_ON_EDIT_PROFILE_ERROR = "RESET_ON_EDIT_PROFILE_ERROR";

export const ON_ADD_COMPANY_ERROR = "ON_ADD_COMPANY_ERROR";
export const ON_ADD_COMPANY_SUCCESS = "ON_ADD_COMPANY_SUCCESS";

export const ON_LOADING = "ON_LOADING";

export const CHANGE_CAMPAIGN_PRICE = "CHANGE_CAMPAIGN_PRICE";

export const GET_WALLET_LOADING = "GET_WALLET_LOADING";
export const GET_WALLET_ERROR = "GET_WALLET_ERROR";
export const GET_WALLET_SUCCESS = "GET_WALLET_SUCCESS";

export const CREATE_YEAP_TRANSACTION_LOADING =
  "CREATE_YEAP_TRANSACTION_LOADING";
export const CREATE_YEAP_TRANSACTION_SUCCESS =
  "CREATE_YEAP_TRANSACTION_SUCCESS";
export const CREATE_YEAP_TRANSACTION_ERROR = "CREATE_YEAP_TRANSACTION_ERROR";
export const CREATE_YEAP_TRANSACTION_AFTER_SUCCESS =
  "CREATE_YEAP_TRANSACTION_AFTER_SUCCESS";

export const GET_TRANSACTION_LOADING = "GET_TRANSACTION_LOADING";
export const GET_TRANSACTION_ERROR = "GET_TRANSACTION_ERROR";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";

export const ON_EDIT_STATUS_LOADING = "ON_EDIT_STATUS_LOADING";
export const ON_EDIT_STATUS_ERROR = "ON_EDIT_STATUS_ERROR";
export const ON_EDIT_STATUS_SUCCESS = "ON_EDIT_STATUS_SUCCESS";

export const GET_RATE_LOADING = "GET_RATE_LOADING";
export const GET_RATE_ERROR = "GET_RATE_ERROR";
export const GET_RATE_SUCCESS = "GET_RATE_SUCCESS";

export const ON_EDIT_RATE_LOADING = "ON_EDIT_RATE_LOADING";
export const ON_EDIT_RATE_ERROR = "ON_EDIT_RATE_ERROR";
export const ON_EDIT_RATE_SUCCESS = "ON_EDIT_RATE_SUCCESS";
export const ON_EDIT_RATE_AFTER_SUCCESS = "ON_EDIT_RATE_AFTER_SUCCESS";

export const GET_PAYPAL_LOADING = "GET_PAYPAL_LOADING";
export const GET_PAYPAL_ERROR = "GET_PAYPAL_ERROR";
export const GET_PAYPAL_SUCCESS = "GET_PAYPAL_SUCCESS";

export const ON_EDIT_PAYPAL_LOADING = "ON_EDIT_PAYPAL_LOADING";
export const ON_EDIT_PAYPAL_ERROR = "ON_EDIT_PAYPAL_ERROR";
export const ON_EDIT_PAYPAL_SUCCESS = "ON_EDIT_PAYPAL_SUCCESS";

export const GET_BANK_LOADING = "GET_BANK_LOADING";
export const GET_BANK_ERROR = "GET_BANK_ERROR";
export const GET_BANK_SUCCESS = "GET_BANK_SUCCESS";

export const ON_EDIT_BANK_LOADING = "ON_EDIT_BANK_LOADING";
export const ON_EDIT_BANK_ERROR = "ON_EDIT_BANK_ERROR";
export const ON_EDIT_BANK_SUCCESS = "ON_EDIT_BANK_SUCCESS";
export const ON_EDIT_BANK_INFO_AFTER_SUCCESS =
  "ON_EDIT_BANK_INFO_AFTER_SUCCESS";
