import * as actionsTypes from "./ActionType";
import axios from "../../axios";

//get paypal info
export const getPaypalInfo = (id) => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.GET_PAYPAL_LOADING });
    await axios
      .get(`/getPaypalInfo/${id}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionsTypes.GET_PAYPAL_SUCCESS,
            payload: res.data,
          });
        } else {
          dispatch({
            type: actionsTypes.ON_EDIT_BANK_INFO_AFTER_SUCCESS,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionsTypes.GET_PAYPAL_ERROR,
        });
      });
  };
};

//get bank info
export const getBankInfo = (id) => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.GET_BANK_LOADING });
    await axios
      .get(`/getBankInfo/${id}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionsTypes.GET_BANK_SUCCESS,
            payload: res.data,
          });
        } else {
          dispatch({
            type: actionsTypes.ON_EDIT_BANK_INFO_AFTER_SUCCESS,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionsTypes.GET_PAYPAL_ERROR,
        });
      });
  };
};

//edit paypal info
export const editPaypalInfo = (data) => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.ON_EDIT_PAYPAL_LOADING });
    await axios
      .post("/addPaypalInfo", { ...data })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          dispatch({
            type: actionsTypes.ON_EDIT_PAYPAL_SUCCESS,
            message: res.data.message,
          });
        } else {
          dispatch({
            type: actionsTypes.ON_EDIT_BANK_INFO_AFTER_SUCCESS,
            message: res.data.message,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionsTypes.ON_EDIT_PAYPAL_ERROR,
          message: e.response.data.message,
        });
      });
  };
};

//edit bank info
export const editBankInfo = (data) => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.ON_EDIT_BANK_LOADING });
    await axios
      .post("/addBankInfo", { ...data })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          dispatch({
            type: actionsTypes.ON_EDIT_BANK_SUCCESS,
            message: res.data.message,
          });
        } else {
          dispatch({
            type: actionsTypes.ON_EDIT_BANK_INFO_AFTER_SUCCESS,
            message: res.data.message,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionsTypes.ON_EDIT_BANK_ERROR,
          message: e.response.data.message,
        });
      });
  };
};
