import * as actionTypes from "../Action/ActionType";

const initstate = {
  transaction: [],
  transactionLoading: false,
  transactionError: "",
  transactionSuccess: "",
};

const companyProfile = (state = initstate, action) => {
  switch (action.type) {
    case actionTypes.CREATE_YEAP_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactionLoading: false,
        transactionSuccess: action.message,
      };
    case actionTypes.CREATE_YEAP_TRANSACTION_LOADING:
      return {
        ...state,
        transactionLoading: true,
      };
    case actionTypes.CREATE_YEAP_TRANSACTION_ERROR:
      return {
        ...state,
        transactionLoading: false,
        transactionError: action.error,
      };
    case actionTypes.CREATE_YEAP_TRANSACTION_AFTER_SUCCESS:
      return {
        ...state,
        transactionLoading: false,
        transactionError: "",
        transactionSuccess: "",
      };
    case actionTypes.GET_TRANSACTION_SUCCESS:
      return {
        ...state,
        transaction: action.payload,
        transactionLoading: false,
      };
    case actionTypes.GET_TRANSACTION_LOADING:
      return {
        ...state,
        transactionLoading: true,
      };
    case actionTypes.GET_TRANSACTION_ERROR:
      return {
        ...state,
        transactionLoading: false,
        transactionError: action.error,
      };
    case actionTypes.ON_EDIT_STATUS_SUCCESS:
      return {
        ...state,
        transactionLoading: false,
        transactionSuccess: action.payload.message,
        transaction: state.transaction?.map((tran) => {
          if (tran?._id === action?.payload?.id) {
            return {
              ...tran,
              status: action?.payload?.status,
            };
          }
          return tran;
        }),
      };
    case actionTypes.ON_EDIT_STATUS_LOADING:
      return {
        ...state,
        transactionLoading: true,
      };
    case actionTypes.ON_EDIT_STATUS_ERROR:
      return {
        ...state,
        transactionLoading: false,
        transactionError: action.error,
      };
    default:
      return state;
  }
};

export default companyProfile;
