import axios from "../axios";
class LanguageApiService {
  getLanguages = () => {
    return axios
      .get(`/getLanguageList`)
      .then((response) => Promise.resolve(response.data.result.languages))
      .catch((err) => Promise.reject(err));
  };

  getTranslation = (languageCode = "en") => {
    return axios
      .post(`/getTermsList`, { languageCode })
      .then((response) => Promise.resolve(response.data.result.terms))
      .catch((err) => Promise.reject(err));
  };
}

export default new LanguageApiService();
