import * as actionTypes from "../Action/ActionType";

const initstate = {
  user: {},
  token: "",
  error: "",
  selectedCompany: {},
  selectedCompanyInfo: {},
  updateMessage: "",
  sas: "",
  loading: false,
};

const store = (state = initstate, action) => {
  switch (action.type) {
    case actionTypes.STORE_TOKEN_SUCEESS:
      return {
        ...state,
        user: action?.payload?.user,
        token: action?.payload?.token,
        loading: false
      };
    case actionTypes.SET_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action?.payload,
        loading: false
      };
    case actionTypes.SET_SELECTED_COMPANYINFO:
      return {
        ...state,
        selectedCompanyInfo: action?.payload,
        loading: false
      };
    case actionTypes.SET_SAS_TOKEN:
      return {
        ...state,
        sas: action?.sas,
        loading: false
      };
    case actionTypes.ON_EDIT_COMPANY_SUCCESS:
      return {
        ...state,
        updateMessage: action?.message,
        loading: false,
        error: "",
        selectedCompanyInfo: action?.data,
        user: {
          ...state?.user,
          company: state.user.company.map((com) => {
            if (com?.id === action?.data?.id) {
              return {
                ...com,
                name: action?.data?.name,
              };
            }
            return com;
          }),
        },
      };
    case actionTypes.ON_EDIT_COMPANY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ON_EDIT_COMPANY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.RESET_ON_EDIT_COMPANY_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
        updateMessage: "",
      };
    case actionTypes.ON_EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        updateMessage: action?.message,
        loading: false,
        error: "",
        user: {
          ...state?.user,
          firstName: action?.data?.firstName,
          lastName: action?.data?.lastName,
          image: action?.data?.image
        },
      };
    case actionTypes.ON_EDIT_PROFILE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ON_EDIT_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.RESET_ON_EDIT_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
        updateMessage: "",
      };
    case actionTypes.ON_ADD_COMPANY_SUCCESS:
      return {
        ...state,
        updateMessage: action?.message,
        loading: false,
        error: "",
        user: { ...action.user },
      };
    case actionTypes.ON_ADD_COMPANY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.ON_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.CHANGE_CAMPAIGN_PRICE:
      return {
        ...state,
        selectedCompanyInfo: {
          ...state?.selectedCompanyInfo,
          campaignPrice: action?.campaignPrice
        }
      }
    default:
      return state;
  }
};
export default store;
