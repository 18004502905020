import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Tooltip } from "antd";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import BusinessIcon from "@mui/icons-material/Business";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#373030",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: "nowrap",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const RejectedCompany = ({ rejectedList, onAccept }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const onAccepting = () => {
    onAccept({
      id: selectedCompany?._id,
      onHold: false,
      onHoldReason: "",
      campaignPrice: selectedCompany?.campaignPrice,
    });
    setDialogOpen(false);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(orderBy) {
    return (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  return (
    <div style={{ marginTop: "20px" }}>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() =>
            history.push(
              `/companies/companyProfile?id=${selectedCompany?._id}&name=${selectedCompany?.name}`
            )
          }
        >
          <ListItemIcon>
            <BusinessIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("translations:go_to_company_profile")}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDialogOpen(true);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("translations:accept_company")}</ListItemText>
        </MenuItem>
      </Menu>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table aria-label="customized table">
            <colgroup>
              <col style={{ width: "5%" }} />
              <col style={{ width: "15%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "10%" }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell>
                  {t("translations:company_name")}
                </StyledTableCell>
                <StyledTableCell>
                  {t("translations:company_address")}
                </StyledTableCell>
                <StyledTableCell>{t("translations:user_name")}</StyledTableCell>
                <StyledTableCell>
                  {t("translations:user_email")}
                </StyledTableCell>
                <StyledTableCell>
                  {t("translations:created_on")}
                </StyledTableCell>
                <StyledTableCell>
                  {t("translations:rejected_reason")}
                </StyledTableCell>
                <StyledTableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {rejectedList.length !== 0 ? (
                stableSort(rejectedList, getComparator("title"))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          history.push(
                            `/companies/companyProfile?id=${row?._id}&name=${row?.name}`
                          )
                        }
                      >
                        {row?.name}
                      </StyledTableCell>
                      <StyledTableCell>{row?.address}</StyledTableCell>
                      <StyledTableCell>{`${row?.user[0]?.firstName} ${row?.user[0]?.lastName}`}</StyledTableCell>
                      <StyledTableCell>{row?.user[0]?.email}</StyledTableCell>
                      <StyledTableCell>
                        {moment(row?.createdAt).format("LL")}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Tooltip placement="bottom" title={row?.onHoldReason}>
                          {row?.onHoldReason.length >= 40
                            ? row?.onHoldReason.substring(0, 40) + "..."
                            : row?.onHoldReason}
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell>
                        <IconButton
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={(e) => {
                            setSelectedCompany(row);
                            handleClick(e);
                          }}
                        >
                          <MoreVertRoundedIcon color="primary" />
                        </IconButton>
                      </StyledTableCell>
                      {/* <StyledTableCell>
                        <div className="d-flex justify-content-between">
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => onClickButton(row)}
                          >
                            Accept
                          </Button>
                        </div>
                      </StyledTableCell> */}
                    </StyledTableRow>
                  ))
              ) : (
                <StyledTableCell component="th" scope="row">
                  {t("translations:no_rejected_company_found")}
                </StyledTableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            borderTop: "1px solid #e0e0e0",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rejectedList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <DialogTitle>
          {`${t("translations:accept_caompany_dialog")} ${
            selectedCompany?.name
          }?`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>
            {t("translations:cancel")}
          </Button>
          <Button
            onClick={() => {
              onAccepting();
            }}
            autoFocus
          >
            {t("translations:ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RejectedCompany;
