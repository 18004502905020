import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CreateIcon from "@mui/icons-material/Create";
import AddIcon from "@mui/icons-material/Add";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import CreateCampaignFirstPage from "./CreateCampaignFirstPage";
import AddCampaignContent from "./AddCampaignContent";
import PublishCampaign from "./PublishCampaign";
import axios from "../../axios";
import Progress from "../../Component/Progress/Progress";
import { message } from "antd";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { BackButton } from "../../Common/BackButton";
import { useTranslation } from "react-i18next";

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <CreateIcon />,
    2: <AddIcon />,
    3: <PublishedWithChangesIcon />,
  };
  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CreateCampaign = () => {
  const { t } = useTranslation();
  const history = useHistory();
  let query = useQuery();
  const id = query.get("id");
  const steps = [
    t("translations:create_campaign"),
    t("translations:add_content"),
    t("translations:publish_campaign"),
  ];
  const pendingPublish = [
    t("translations:edit_campaign"),
    t("translations:add_content"),
    t("translations:publish_campaign"),
  ];
  const [loading, setLoading] = useState(false);
  const [campaignId, setCampaignId] = useState(
    localStorage.getItem("campaignId")
  );
  const [activeStep, setActiveStep] = useState(0);
  const [campaign, setCampaign] = useState({
    title: "",
    description: "",
    image: "",
    price: 0,
    _id: "",
  });
  const [created, setCreated] = useState(false);
  const [allContent, setAllContent] = useState([]);
  const [oldCampaign, setOldCampaign] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const { user, selectedCompany } = useSelector((state) => state.Auth);

  useEffect(() => {
    if (id) {
      setCampaignId(id);
      setOldCampaign(true);
    }
  }, [id]);

  useEffect(() => {
    setLoading(true);
    axios
      .get("/getCategories")
      .then((res) => {
        const data = [];
        res?.data &&
          res?.data?.forEach((item) => {
            data.push(item?.title);
          });
        setAllCategories(data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  useEffect(async () => {
    if (campaignId) {
      setLoading(true);
      axios
        .get(`getCampaignById/${campaignId}`)
        .then((res) => {
          res?.data && setCampaign(res.data);
          setCreated(true);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
      getContent();
    }
  }, [campaignId]);

  const getContent = () => {
    setLoading(true);
    axios
      .get(`/getCampaignContent/${campaignId}`)
      .then((res) => {
        res?.data && setAllContent(res.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const onCreateCampaign = (data) => {
    setLoading(true);
    axios
      .post("/createCampaign", {
        ...data,
        userId: user?._id,
        companyId: selectedCompany?.companyId,
      })
      .then((res) => {
        setCampaign(res?.data?.campaign);
        localStorage.setItem("campaignId", res?.data?.campaign?._id);
        setCreated(true);
        setActiveStep(1);
        setLoading(false);
        message.success(t("translations:campaign_create_success"));
      })
      .catch((e) => {
        setLoading(false);
        message.error(t("translations:campaign_create_error"));
      });
  };

  const onUpdateCampaign = (data) => {
    setLoading(true);
    axios
      .patch(`/editCampaign/${campaign?._id}`, { ...data })
      .then((res) => {
        setCampaign({
          ...campaign,
          title: data?.title,
          description: data?.description,
          price: data?.price,
          showHeader: data?.showHeader,
          showFooter: data?.showFooter,
          user: data?.user,
        });
        setLoading(false);
        setActiveStep(1);
        message.success(t("translations:campaign_update_success"));
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        message.error(t("translations:campaign_update_error"));
      });
  };

  const onAddContent = (data) => {
    setLoading(true);
    axios
      .post("/addCampaignContent", {
        description: data?.description,
        campaignId: campaign?._id,
        order: data?.order,
      })
      .then((res) => {
        if (res.data) {
          const newData = allContent;
          newData.push(res.data.data);
          setAllContent(newData);
          setLoading(false);
          message.success(res.data.message);
        }
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        message.error(t("translations:content_add_error"));
      });
  };

  const onEditContent = (data) => {
    setLoading(true);
    axios
      .patch(`/editCampaignContent/${data?.selectedId}`, {
        description: data?.description,
      })
      .then((res) => {
        if (res.status === 200) {
          getContent();
          setLoading(false);
          message.success(t("translations:content_update_success"));
        }
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        message.error(t("translations:content_update_error"));
      });
  };

  const onDeleteContent = (selectedId, selectedIndex) => {
    setLoading(true);
    axios
      .delete(`/deleteCampaignContent/${selectedId}`)
      .then((res) => {
        if (res.status === 200) {
          const newData = allContent;
          newData.splice(selectedIndex, 1);
          setAllContent(newData);
          setLoading(false);
          message.success(
            res.data.message || t("translations:content_delete_success")
          );
        }
      })
      .catch((e) => {
        setLoading(false);
        message.error(t("translations:content_delete_error"));
      });
  };

  const onPublish = (data, publish) => {
    setLoading(true);
    const publish_data = publish
      ? { status: data, publish: true }
      : { status: data };
    axios
      .post(`/publishCampaign/${campaign?._id}`, publish_data)
      .then((res) => {
        if (res.status === 200) {
          message.success(t("translations:campagin_publish_success"));
          history.push("/campaigns");
          localStorage.removeItem("campaignId");
        }
      })
      .catch((e) => {
        message.error(t("translations:campaign_publish_error"));
        console.error(e);
        setLoading(false);
      });
  };

  const handleChange = (event, newValue) => {
    setActiveStep(newValue);
  };

  return loading ? (
    <Progress />
  ) : (
    <div style={{ paddingTop: "20px" }}>
      <div className="default-container">
        <BackButton />
      </div>
      {campaign?.publish ? (
        <div className="motiavtion-tab default-container">
          <Tabs
            value={activeStep}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label={t("translations:edit_campaign")} {...a11yProps(0)} />
            <Tab label={t("translations:add_content")} {...a11yProps(1)} />
          </Tabs>
        </div>
      ) : (
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {oldCampaign && !campaign?.publish
              ? pendingPublish.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))
              : steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
          </Stepper>
        </Stack>
      )}
      {loading ? (
        <Progress />
      ) : (
        <div className="default-container">
          {activeStep === 0 && (
            <CreateCampaignFirstPage
              campaign={campaign}
              created={created}
              onAddCampaign={(data) => onCreateCampaign(data)}
              onChange={(id) => setActiveStep(id)}
              onEditCampaign={(data) => onUpdateCampaign(data)}
              allCategories={allCategories}
              oldCampaign={oldCampaign}
              onForward={() => setActiveStep(1)}
            />
          )}
          {activeStep === 1 && (
            <AddCampaignContent
              allContent={allContent}
              campaign={campaign}
              onChange={(id) => setActiveStep(id)}
              onAddingContent={(data) => {
                onAddContent(data);
              }}
              onEditingContent={(data) => onEditContent(data)}
              onDeletingContent={(selectedId, selectedIndex) =>
                onDeleteContent(selectedId, selectedIndex)
              }
              oldCampaign={oldCampaign}
              campaignPublish={campaign?.publish}
            />
          )}
          {/*{activeStep === 2 &&*/}
          {/*  oldCampaign &&*/}
          {/*  campaign?.publish === true && (*/}
          {/*    <Analystics onChange={(id) => setActiveStep(id)} />*/}
          {/*  )}*/}
          {activeStep === 2 && campaign?.publish === false && (
            <PublishCampaign
              onChange={(id) => setActiveStep(id)}
              onPublish={(data, status) => onPublish(data, status)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CreateCampaign;
