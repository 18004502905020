import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { message } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TablePagination from "@mui/material/TablePagination";
import ReorderIcon from "@mui/icons-material/Reorder";
import axios from "../../axios";
import ReactDevicePreview from "react-device-preview";
import companyLogo from "../../Assets/companylogo.png";
import Rating from "@mui/material/Rating";
import { Tag } from "antd";
import htmlToDraft from "html-to-draftjs";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./CreateKnowhow.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#373030",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: "nowrap",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const AddKnowHowContent = ({
  allContent,
  knowhow,
  onChange,
  onAddingContent,
  onEditingContent,
  onDeletingContent,
  oldKnowhow,
  knowhowPublish,
}) => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [edit, setEdit] = useState(false);
  const [selectedContent, setSelectedContent] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedId, setSelectedId] = useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [contentText, setContentText] = useState({});
  const editorState = EditorState.createEmpty();
  const [description, setDescription] = useState(editorState);
  const [view, setView] = useState(false);
  const { selectedCompanyInfo, sas } = useSelector((state) => state.Auth);
  const onEditorStateChange = (editor) => {
    setDescription(editor);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (edit) {
      const blocksFromHtml = htmlToDraft(selectedContent?.description);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      setDescription(editorState);
    }
  }, [edit]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [data, setData] = useState({
    title: "",
    link: "",
  });
  const [rows, setRows] = useState([]);

  useEffect(() => {
    allContent && setRows(allContent.sort((a, b) => a.order - b.order));
  }, [allContent]);

  useEffect(() => {
    if (dialogOpen === false) {
      setEdit(false);
      setDescription(EditorState.createEmpty());
      setData({ title: "", link: "" });
      setView(false);
      setSelectedContent({});
      handleClose();
    }
  }, [dialogOpen]);

  const validate = () => {
    if (data?.title === "" || data?.link === "") {
      return false;
    }
    return true;
  };

  const onAddContent = () => {
    if (contentText.description.value.length <= 8) {
      message.error(t("translations:add_content_error"));
    } else {
      setDescription(EditorState.createEmpty());
      onAddingContent({
        description: contentText.description.value,
        order: rows.length,
      });
      setDialogOpen(false);
    }
  };

  const onDelete = () => {
    onDeletingContent(selectedId, selectedIndex);
    handleClose();
  };

  const onEdit = () => {
    setEdit(true);
    setData(selectedContent);
    setDialogOpen(true);
  };

  const onView = (row) => {
    setEdit(true);
    setData(row);
    setDialogOpen(true);
  };

  const onEditContent = () => {
    const isValid = validate();
    if (isValid) {
      setData({ title: "", link: "" });
      setDialogOpen(false);
      onEditingContent({
        description: contentText.description.value,
        selectedId: selectedId,
      });
    }
  };

  const getFileBase64 = (file, callback) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = (error) => {
      message.error(t("translations:file_upload_failed"));
    };
  };

  const imageUploadCallback = (file) =>
    new Promise((resolve, reject) =>
      getFileBase64(file, (data) => resolve({ data: { link: data } }))
    );

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const items = reorder(rows, result.source.index, result.destination.index);

    setRows(items);

    await Promise.all(
      items.forEach((item, index) => {
        if (item.order !== index) {
          axios
            .patch(`/knowhow/editContent/${item?._id}`, { order: index })
            .then((res) => {})
            .catch((e) => {
              console.error(e);
            });
        }
      })
    );
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(orderBy) {
    return (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const embedVideoCallBack = (link) => {
    if (link.indexOf("youtube") >= 0) {
      link = link.replace("watch?v=", "embed/");
      link = link.replace("/watch/", "/embed/");
      link = link.replace("youtu.be/", "youtube.com/embed/");
    }
    return link;
  };

  const sendTextToEditor = (text) => {
    setDescription(insertText(text, description));
  };

  const insertText = (text, editorValue) => {
    const currentContent = editorValue.getCurrentContent();
    const currentSelection = editorValue.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      text
    );

    const newEditorState = EditorState.push(
      editorValue,
      newContent,
      "insert-characters"
    );
    return EditorState.forceSelection(
      newEditorState,
      newContent.getSelectionAfter()
    );
  };

  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={onEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("translations:edit")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={onDelete}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("translations:delete")}</ListItemText>
        </MenuItem>
      </Menu>
      <div className="d-flex justify-content-between align-items-center">
        <div className="title-heading">{knowhow?.title}</div>
        <div className="justify-content-end main-button">
          <Button
            variant="contained"
            className="add-button"
            onClick={() => setDialogOpen(true)}
          >
            {t("translations:add_content")}
          </Button>
        </div>
      </div>
      {rows && (
        <div style={{ paddingBottom: "30px" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer>
              <Table sx={{ minWidth: 600 }} aria-label="customized table">
                <colgroup>
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "50%" }} />
                  <col style={{ width: "5%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "10%" }} />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>
                      {t("translations:content")}
                    </StyledTableCell>
                    <StyledTableCell>{t("translations:views")}</StyledTableCell>
                    <StyledTableCell>
                      {t("translations:rating")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {t("translations:action")}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {(droppableProvided) => (
                      <TableBody
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                      >
                        {rows.length !== 0 ? (
                          stableSort(rows, getComparator("title"))
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => (
                              <Draggable
                                key={row._id}
                                draggableId={row._id}
                                index={index}
                              >
                                {(draggableProvided, snapshot) => {
                                  return (
                                    <StyledTableRow
                                      key={index}
                                      ref={draggableProvided.innerRef}
                                      {...draggableProvided.draggableProps}
                                      style={{
                                        ...draggableProvided.draggableProps
                                          .style,
                                        background: snapshot.isDragging
                                          ? "rgba(245,245,245, 0.75)"
                                          : "none",
                                      }}
                                    >
                                      <StyledTableCell
                                        component="th"
                                        scope="row"
                                        align="center"
                                      >
                                        <div
                                          {...draggableProvided.dragHandleProps}
                                        >
                                          <ReorderIcon />
                                        </div>
                                      </StyledTableCell>
                                      {/* <StyledTableCell>{row?.title}</StyledTableCell> */}
                                      <StyledTableCell
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setSelectedIndex(index);
                                          setSelectedId(row?._id);
                                          setSelectedContent(row);
                                          onView(row);
                                        }}
                                      >
                                        <div
                                          className="post__description"
                                          dangerouslySetInnerHTML={{
                                            __html: row?.description,
                                          }}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {row?.views}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        <div className="rating-wrapper">
                                          <Rating
                                            defaultValue={row?.rating}
                                            precision={0.1}
                                            readOnly
                                          />
                                          <span className="rating-count">
                                            {row?.rating.toFixed(2)} (
                                            {row?.numReviews} user)
                                          </span>
                                        </div>
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        <IconButton
                                          id="basic-button"
                                          aria-controls={
                                            open ? "basic-menu" : undefined
                                          }
                                          aria-haspopup="true"
                                          aria-expanded={
                                            open ? "true" : undefined
                                          }
                                          onClick={(e) => {
                                            setSelectedIndex(index);
                                            setSelectedId(row?._id);
                                            setSelectedContent(row);
                                            handleClick(e);
                                          }}
                                        >
                                          <MoreVertRoundedIcon color="primary" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                }}
                              </Draggable>
                            ))
                        ) : (
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              {t("translations:no_content_found")}
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                        {droppableProvided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
            <div style={{ borderTop: "1px solid #e0e0e0" }}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </Paper>
        </div>
      )}
      {(!oldKnowhow || !knowhowPublish) && (
        <div className="d-flex justify-content-between">
          <div className="main-button">
            <Button
              variant="contained"
              className="add-button"
              onClick={() => {
                onChange(0);
              }}
            >
              {t("translations:previous")}
            </Button>
          </div>
          <div className="main-button">
            <Button
              variant="contained"
              className="add-button"
              onClick={() => {
                onChange(2);
              }}
            >
              {t("translations:next")}
            </Button>
          </div>
        </div>
      )}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="900px"
        className="add-dialog"
      >
        <DialogTitle>
          {view
            ? t("translations:view")
            : edit
            ? t("translations:edit")
            : t("translations:add")}{" "}
          {t("translations:content")}
          <IconButton
            aria-label="close"
            onClick={() => {
              setDialogOpen(false);
              setEdit(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div>
            <Tag
              color="magenta"
              className="add-tag"
              onClick={() => sendTextToEditor(" {{userName}} ")}
            >
              {t("translations:add_userName")}
            </Tag>
            <Tag
              color="orange"
              className="add-tag"
              onClick={() => sendTextToEditor(" {{habitName}} ")}
            >
              {t("translations:add_habit")}
            </Tag>
            <Tag
              color="purple"
              className="add-tag"
              onClick={() => sendTextToEditor(" {{goal}} ")}
            >
              {t("translations:add_goal")}
            </Tag>
            <div className="main-editor">
              <Editor
                editorState={description}
                onEditorStateChange={onEditorStateChange}
                editorClassName="add-content-editor"
                toolbar={{
                  inline: { inDropdown: true },
                  image: {
                    uploadCallback: imageUploadCallback,
                    previewImage: true,
                    inputAccept:
                      "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                    defaultSize: {
                      height: "auto",
                      width: "auto",
                    },
                  },
                  link: {
                    linkCallback: (params) => ({ ...params }),
                  },
                  embedded: {
                    embedCallback: embedVideoCallBack,
                  },
                }}
              />
              <textarea
                disabled
                style={{ display: "none" }}
                ref={(val) => (contentText.description = val)}
                value={draftToHtml(
                  convertToRaw(description.getCurrentContent())
                )}
              />
              <ReactDevicePreview device="galaxys5" scale="0.8">
                <div>
                  {knowhow?.showHeader && (
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <span className="package-title">{knowhow?.title}</span>
                      <div>
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={
                            knowhow?.image
                              ? `https://yeapportal.blob.core.windows.net/yeapportal/${knowhow?.image}?${sas}`
                              : companyLogo
                          }
                          alt="logo"
                        />
                      </div>
                    </div>
                  )}
                  <div
                    className="post__description_preview"
                    dangerouslySetInnerHTML={{
                      __html: contentText?.description
                        ? contentText?.description?.value ||
                          contentText?.description
                        : selectedContent?.description,
                    }}
                  />
                  {knowhow?.showFooter && (
                    <div className="footer-content">
                      <span className="package-title">
                        {selectedCompanyInfo?.name}
                      </span>
                      <div>
                        <span className="footer-next">
                          {edit
                            ? selectedContent?.order + 1
                            : allContent.length + 1}{" "}
                          / {edit ? allContent.length : allContent.length + 1}{" "}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </ReactDevicePreview>
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ paddingRight: "50px" }}>
          <Button
            onClick={() => {
              setDialogOpen(false);
              setEdit(false);
            }}
          >
            {t("translations:cancel")}
          </Button>
          {!view && (
            <Button onClick={() => (edit ? onEditContent() : onAddContent())}>
              {edit ? t("translations:save") : t("translations:add")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddKnowHowContent;
