import React from 'react'
import { Button } from '@mui/material'

const Analystics = ({onChange}) => {
  return (
    <div>
      <div>
        <h1 className="dashboard-main">Analystics</h1>
        <div className="d-flex justify-content-between">
          <div className="main-button">
            <Button
              variant="contained"
              className="add-button"
              onClick={() => onChange(1)}
            >
              Previous
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Analystics;
