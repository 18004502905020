import * as actionTypes from "../Action/ActionType";

const initstate = {
  paypalInfo: {
    email: "",
  },
  bankInfo: {
    swift: "",
    iban: "",
    companyName: "",
    firstName: "",
    lastName: "",
    nameOnAccount: "",
    address: "",
    city: "",
    country: "",
    phoneNo: "",
    auth: false,
  },
  bankInfoLoading: false,
  bankInfoError: "",
  bankInfoMessage: "",
};

const companyProfile = (state = initstate, action) => {
  switch (action.type) {
    case actionTypes.GET_PAYPAL_SUCCESS:
      return {
        ...state,
        paypalInfo: action.payload,
        bankInfoLoading: false,
      };
    case actionTypes.GET_PAYPAL_LOADING:
      return {
        ...state,
        bankInfoLoading: true,
      };
    case actionTypes.GET_PAYPAL_ERROR:
      return {
        ...state,
        bankInfoLoading: false,
      };
    case actionTypes.ON_EDIT_PAYPAL_SUCCESS:
      return {
        ...state,
        bankInfoMessage: action.message,
        bankInfoLoading: false,
      };
    case actionTypes.ON_EDIT_PAYPAL_LOADING:
      return {
        ...state,
        bankInfoLoading: true,
      };
    case actionTypes.ON_EDIT_PAYPAL_ERROR:
      return {
        ...state,
        bankInfoLoading: false,
        bankInfoError: action.message,
      };
    case actionTypes.GET_BANK_SUCCESS:
      return {
        ...state,
        bankInfo: action.payload,
        bankInfoLoading: false,
      };
    case actionTypes.GET_BANK_LOADING:
      return {
        ...state,
        bankInfoLoading: true,
      };
    case actionTypes.GET_BANK_ERROR:
      return {
        ...state,
        bankInfoLoading: false,
        bankInfoError: action.error,
      };
    case actionTypes.ON_EDIT_BANK_SUCCESS:
      return {
        ...state,
        bankInfoMessage: action.message,
        bankInfoLoading: false,
      };
    case actionTypes.ON_EDIT_BANK_LOADING:
      return {
        ...state,
        bankInfoLoading: true,
      };
    case actionTypes.ON_EDIT_BANK_ERROR:
      return {
        ...state,
        bankInfoLoading: false,
        bankInfoError: action.message,
      };
    case actionTypes.ON_EDIT_BANK_INFO_AFTER_SUCCESS:
      return {
        ...state,
        bankInfoLoading: false,
        bankInfoError: "",
        bankInfoMessage: "",
      };
    default:
      return state;
  }
};

export default companyProfile;
