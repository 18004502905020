import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import AuthReducer from "./Redux/Reducer/Auth";
import CompanyProfile from "./Redux/Reducer/Companies";
import AllCompanies from "./Redux/Reducer/AllCompanies";
import AllPackages from "./Redux/Reducer/Packages";
import Wallet from "./Redux/Reducer/Wallet";
import Transaction from "./Redux/Reducer/Transaction";
import Rate from "./Redux/Reducer/Rate";
import BankInfo from "./Redux/Reducer/BankInfo";
import "./i18nexConf";

const rootReducer = combineReducers({
  Auth: AuthReducer,
  Company: CompanyProfile,
  AllCompanies: AllCompanies,
  AllPackages: AllPackages,
  Wallet: Wallet,
  Transaction: Transaction,
  Rate: Rate,
  BankInfo: BankInfo,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
  <>
    <Provider store={store}>
      <Auth0Provider
        domain="yeaptv.eu.auth0.com"
        clientId="D5EGnfSrTHPCAaJvAJiJttRRWqV2KWhV"
        redirectUri={window.location.origin}
      >
        <App />
      </Auth0Provider>
    </Provider>
  </>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
