import React from "react";
import { useHistory } from "react-router-dom";
import "./Card.css";

const Card = ({ title, cards, id }) => {
  const history = useHistory();

  return (
    <div className="default-container main-wrapper">
      {title && (
        <div>
          <h1 className="card-title">{title}</h1>
        </div>
      )}
      <div
        className={
          id === "623a23085f6b21fda23d1d2a"
            ? "card-wrapper admin-card-wrapper"
            : "card-wrapper"
        }
      >
        {cards.map((card) => (
          <div
            style={{
              backgroundColor: card?.background ? card?.background : "#fff",
            }}
            className="single-card-wrapper"
            onClick={() => card.link && history.push(card.link)}
          >
            <h2
              className="card-heading"
              style={{ color: card?.firstColor ? card.firstColor : "#ff6600" }}
            >
              {card?.title}
            </h2>
            <h1
              className="d-flex justify-content-center fw-bold card-count"
              style={{ color: card.secondColor ? card.secondColor : "#808080" }}
            >
              {card.number}
            </h1>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Card;
