import * as actionsTypes from './ActionType';
import axios from "../../axios";

export const getAllPackages = () => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.GET_ALL_PACKAGES_LOADING });
    await axios
      .get("/getAllMotivation")
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionsTypes.GET_ALL_PACKAGES_SUCCESS,
            allPackage: res.data.data.map((element) => ({ ...element, type: "Motivation" })),
          });
        } else {
          dispatch({
            type: actionsTypes.GET_ALL_PACKAGES_ERROR,
            error: res.data.message,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionsTypes.GET_ALL_PACKAGES_ERROR,
          error: e,
        });
      });
  };
};

export const getAllKnowHow = () => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.GET_ALL_KNOWHOW_LOADING });
    await axios
      .get("/getAllKnowHow")
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionsTypes.GET_ALL_KNOWHOW_SUCCESS,
            allKnowHow: res.data.data.map((element) => ({ ...element, type: "Know-How" })),
          });
        } else {
          dispatch({
            type: actionsTypes.GET_ALL_KNOWHOW_ERROR,
            error: res.data.message,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionsTypes.GET_ALL_KNOWHOW_ERROR,
          error: e,
        });
      });
  };
};

export const getAllCampaign = () => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.GET_ALL_CAMPAIGN_LOADING });
    await axios
      .get("/getAllCampaign")
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionsTypes.GET_ALL_CAMPAIGN_SUCCESS,
            allCampaign: res.data.data.map((element) => ({ ...element, type: "Campaigns" })),
          });
        } else {
          dispatch({
            type: actionsTypes.GET_ALL_CAMPAIGN_ERROR,
            error: res.data.message,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionsTypes.GET_ALL_CAMPAIGN_ERROR,
          error: e,
        });
      });
  };
};

export const onAcceptRejectPackage = (data) => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.ACCEPT_REJECT_PACKAGE_LOADING });
    await axios
      .patch(`/editMotivation/${data?.id}`,{...data})
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionsTypes.ACCEPT_REJECT_PACKAGE_SUCCESS,
            data: data,
            message: res.data.message,
          });
        } else {
          dispatch({
            type: actionsTypes.ACCEPT_REJECT_PACKAGE_ERROR,
            error: res.data.message,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionsTypes.ACCEPT_REJECT_PACKAGE_ERROR,
          error: e,
        });
      });
  };
};

export const onAcceptRejectKnowHow = (data) => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.ACCEPT_REJECT_PACKAGE_LOADING });
    await axios
      .patch(`/editKnowhow/${data?.id}`,{...data})
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionsTypes.ACCEPT_REJECT_KNOWHOW_SUCCESS,
            data: data,
            message: res.data.message,
          });
        } else {
          dispatch({
            type: actionsTypes.ACCEPT_REJECT_PACKAGE_ERROR,
            error: res.data.message,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionsTypes.ACCEPT_REJECT_PACKAGE_ERROR,
          error: e,
        });
      });
  };
};

export const onAcceptRejectCampaign = (data) => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.ACCEPT_REJECT_PACKAGE_LOADING });
    await axios
      .patch(`/editCampaign/${data?.id}`,{...data})
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionsTypes.ACCEPT_REJECT_CAMPAIGN_SUCCESS,
            data: data,
            message: res.data.message,
          });
        } else {
          dispatch({
            type: actionsTypes.ACCEPT_REJECT_PACKAGE_ERROR,
            error: res.data.message,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionsTypes.ACCEPT_REJECT_PACKAGE_ERROR,
          error: e,
        });
      });
  };
};
