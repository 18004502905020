import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "../../axios";
import { message } from "antd";
import { useSelector } from "react-redux";
import Progress from "../../Component/Progress/Progress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import _ from "lodash";
import moment from "moment";
import { Dialog, DialogTitle, DialogActions } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import "./Team.css";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#ff6600",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ff6600",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#1490cc",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ff6600",
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#373030",
    color: theme.palette.common.white,
    "& > .Mui-active": {
      color: "#fff",
      "& svg": {
        opacity: 1,
        color: "#fff",
      },
    },
    "& > span:hover": {
      color: "#fff",
      opacity: 1,
      "& svg": {
        opacity: 1,
      },
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: "nowrap",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Search = styled("div")(() => ({
  position: "relative",
  border: "1px solid rgb(193 193 193)",
  borderRadius: "5px",
  marginRight: "0 !important",
  marginLeft: "0 !important",
  width: "240px !important",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Team = () => {
  const { t } = useTranslation();
  const { user, selectedCompanyInfo } = useSelector((state) => state.Auth);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [allMember, setAllMember] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState(0);
  const [pendingUsers, setPendingUsers] = useState(0);
  const [ownerId, setOwnerId] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState();

  const handleSubmit = () => {
    if (email && role) {
      const index = allMember.findIndex((user) => user?.email === email);
      if (index !== -1) {
        message.error(t("translations:add_member_error"));
      } else {
        setIsLoading(true);
        axios
          .post("/addMember", {
            email: email,
            role: role,
            companyId: selectedCompanyInfo._id,
            company: selectedCompanyInfo.name,
            user: user?._id,
            logo: selectedCompanyInfo?.logo,
          })
          .then((res) => {
            if (res.data.status) {
              setEmail("");
              setRole("");
              message.success(res.data.message);
              getCopanyUsersData();
            } else message.error(t("translations:network_error"));
            setIsLoading(false);
          })
          .catch((e) => {
            message.error(e || t("translations:network_error"));
            setIsLoading(false);
          });
      }
    } else message.error(t("translations:add_member_error1"));
  };

  const getCopanyUsersData = () => {
    setIsLoading(true);
    if (selectedCompanyInfo._id || selectedCompanyInfo.name) {
      axios
        .get(`/getInvitedUser/${selectedCompanyInfo._id}`)
        .then((res) => {
          if (res.data.success) {
            setOwnerId(res.data.ownerId);
            const allData = [];
            res?.data?.users.forEach((user) => {
              allData.push({
                ...user,
                name: `${user?.firstName} ${user?.lastName}`,
                status: "Active",
                role: user?.company
                  ?.filter((j) => j.id === selectedCompanyInfo._id)
                  .map((k) => _.capitalize(k.role))[0],
              });
            });
            res?.data?.pendingUsers.forEach((user) => {
              allData.push({
                ...user,
                role: _.capitalize(user?.role),
                name: `${user?.firstName} ${user?.lastName}`,
                status: "Pending",
              });
            });
            setAllMember(allData);
            setInvitedUsers(res?.data?.users?.length);
            setPendingUsers(res?.data?.pendingUsers?.length);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        })
        .catch((e) => {
          message.error(e || "Network Error");
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    getCopanyUsersData();
  }, [selectedCompanyInfo._id]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  useEffect(() => {
    if (searchValue === "") {
      setFilteredList(allMember);
    } else {
      const dynamicFilter = allMember
        ? allMember?.filter((single) => {
            return (
              single?.name
                ?.toLowerCase()
                .includes(searchValue?.toLowerCase()) ||
              single?.email
                ?.toLowerCase()
                .includes(searchValue?.toLowerCase()) ||
              single?.role
                ?.toLowerCase()
                .includes(searchValue?.toLowerCase()) ||
              single?.status
                ?.toLowerCase()
                .includes(searchValue?.toLowerCase()) ||
              moment(single?.createdAt)
                .format("LL")
                ?.toLowerCase()
                .includes(searchValue?.toLowerCase())
            );
          })
        : [];
      setFilteredList(dynamicFilter);
    }
  }, [searchValue, allMember]);

  const headCells = [
    {
      id: "name",
      disablePadding: false,
      label: t("translations:name"),
    },
    {
      id: "email",
      disablePadding: false,
      label: t("translations:email"),
    },
    {
      id: "role",
      disablePadding: false,
      label: t("translations:role"),
    },
    {
      id: "createdAt",
      disablePadding: false,
      label: t("translations:created_on"),
    },
    {
      id: "status",
      disablePadding: false,
      label: t("translations:status"),
    },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <StyledTableCell />
          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align={"left"}
              padding={"normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
          <StyledTableCell />
        </TableRow>
      </TableHead>
    );
  }

  const onAccepting = () => {
    setDialogOpen(false);
    setIsLoading(true);
    axios
      .post("/changeRole", { ...selectedUser })
      .then((res) => {
        setIsLoading(false);
        message.success(res.data.message);
        getCopanyUsersData();
      })
      .catch((e) => {
        message.error(t("translations:role_updation_failed"));
      });
  };

  const onDelete = () => {
    setDeleteDialogOpen(false);
    setIsLoading(true);
    axios
      .post("/removeUser", { ...selectedUser })
      .then((res) => {
        setIsLoading(false);
        message.success(res.data.message);
        getCopanyUsersData();
      })
      .catch((e) => {
        message.error(t("translations:remove_user"));
      });
  };

  const onRemoveCompany = (row) => {
    if (row?.email === user?.email) {
      message.error(t("translations:remove_message"));
    } else {
      setSelectedUser({
        email: row?.email,
        companyId: selectedCompanyInfo?._id,
      });
      setDeleteDialogOpen(true);
    }
  };

  return isLoading ? (
    <Progress />
  ) : (
    <div className="default-container">
      <div className="teamContent">
        <div className="teamWrapper team-first">
          <h2 className="heading">{t("translations:add_team_members")}</h2>
          <div className="team-add-wrapper">
            <div className="team-email">
              <CssTextField
                label={t("translations:email_label")}
                type="text"
                required
                variant="outlined"
                value={email}
                className="w-100 mb-3"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="role">
              <FormControl fullWidth>
                <InputLabel>{t("translations:role")}</InputLabel>
                <Select
                  id="demo-simple-select"
                  value={role}
                  label={t("translations:role")}
                  className="w-100 mb-3"
                  onChange={(e) => setRole(e.target.value)}
                >
                  <MenuItem value="admin">{t("translations:admin")}</MenuItem>
                  <MenuItem value="user">{t("translations:user")}</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="d-flex justify-content-center main-button add-user-button">
              <Button
                variant="contained"
                className="add-button px-5"
                style={{ height: "56px" }}
                onClick={handleSubmit}
              >
                {t("translations:add")}
              </Button>
            </div>
          </div>
        </div>
        <div className="content-wrapper">
          <div className="teamWrapper team-second">
            <h2 className="heading">{t("translations:active")}</h2>
            <h1
              className="d-flex justify-content-center fw-bold"
              style={{ color: "green" }}
            >
              {invitedUsers}
            </h1>
          </div>
          <div className="teamWrapper team-third">
            <h2 className="heading">{t("translations:pending")}</h2>
            <h1
              className="d-flex justify-content-center fw-bold"
              style={{ color: "red" }}
            >
              {pendingUsers}
            </h1>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end pb-3">
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            value={searchValue}
            placeholder={t("translations:search")}
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Search>
        <Button
          variant="contained"
          className="ms-2"
          onClick={() => setSearchValue("")}
        >
          {t("translations:clear")}
        </Button>
      </div>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table aria-label="customized table">
            <colgroup>
              <col style={{ width: "10%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "25%" }} />
              <col style={{ width: "13%" }} />
              <col style={{ width: "14%" }} />
              <col style={{ width: "13%" }} />
              <col style={{ width: "5%" }} />
            </colgroup>

            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {filteredList.length !== 0 ? (
                stableSort(filteredList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.name === "undefined undefined" ? "-" : row?.name}
                      </StyledTableCell>
                      <StyledTableCell>{row?.email}</StyledTableCell>
                      <StyledTableCell>
                        {user?.email === ownerId ? (
                          <FormControl fullWidth>
                            <InputLabel>{t("translations:role")}</InputLabel>
                            <Select
                              id="demo-simple-select"
                              value={row?.role}
                              label={t("translations:role")}
                              className="w-100 change-role"
                              disabled={row?.email === ownerId}
                              onChange={(e) => {
                                setSelectedUser({
                                  email: row?.email,
                                  companyId: selectedCompanyInfo?._id,
                                  role:
                                    row?.role === "Admin" ? "User" : "Admin",
                                });
                                setDialogOpen(true);
                              }}
                            >
                              <MenuItem value="Admin">
                                {t("translations:admin")}
                              </MenuItem>
                              <MenuItem value="User">
                                {t("translations:user")}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        ) : (
                          <div>{row?.role}</div>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        {moment(row?.createdAt).format("LL")}
                      </StyledTableCell>
                      <StyledTableCell>
                        <div
                          style={{
                            color:
                              row?.status === "Active" ? "#008000" : "#FF0000",
                          }}
                        >
                          {row?.status}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        {user?.email === ownerId &&
                          row?.email !== ownerId && (
                            <DeleteIcon
                              style={{ fill: "red", cursor: "pointer" }}
                              onClick={() => {
                                onRemoveCompany(row);
                              }}
                            />
                          )}{" "}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
              ) : (
                <StyledTableCell component="th" scope="row">
                  {t("translations:no_member_found")}
                </StyledTableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ borderTop: "1px solid #e0e0e0" }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <DialogTitle>
          {`${t("translations:dialog")} ${selectedUser?.email} ${t(
            "translations:role_from"
          )} ${
            selectedUser?.role === "Admin"
              ? t("translations:user")
              : t("translations:admin")
          } ${t("translations:to")} ${selectedUser?.role} ?`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>
            {t("translations:cancel")}
          </Button>
          <Button
            onClick={() => {
              onAccepting();
            }}
            autoFocus
          >
            {t("translations:ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
      >
        <DialogTitle>
          {`${t("translations:delete_user")} ${selectedUser?.email} ${t(
            "translations:delete_user1"
          )} ?`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>
            {t("translations:cancel")}
          </Button>
          <Button
            onClick={() => {
              onDelete();
            }}
            autoFocus
          >
            {t("translations:ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Team;
