import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TextField, Button, Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import Progress from "../../Component/Progress/Progress";
import axios from "../../axios";
import { message } from "antd";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
import coin from "../../Assets/coin.png";
import { useTranslation } from "react-i18next";
import "./CreatePackage.css";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#ff6600",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ff6600",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#1490cc",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ff6600",
    },
  },
});

const PrettoSlider = styled(Slider)({
  color: "#ff6600",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#ff6600",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const CreateFirstPage = ({
  motivation,
  created,
  onAddMotivation,
  onEditMotivation,
  onChange,
  allCategories,
  oldMotivation,
  onForward,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [data, setData] = useState({
    title: "",
    description: "",
    price: 0,
    category: "",
    showHeader: false,
    showFooter: false,
  });
  const [fileList, setFileList] = useState([]);
  const [error, setError] = useState({
    fileError: "",
    categoryError: "",
  });
  const sas = useSelector((state) => state.Auth.sas);

  const validate = () => {
    let categoryError = "";
    let fileError = "";
    if (
      data?.title === "" ||
      data?.description === "" ||
      data?.category === "" ||
      fileList.length === ""
    ) {
      if (data?.category === "") {
        categoryError = t("translations:category_is_required");
      }
      if (fileList?.length === 0) {
        fileError = t("translations:image_is_required");
      }
      if (categoryError || fileError) {
        setError({
          ...error,
          categoryError: categoryError,
          fileError: fileError,
        });
      }
      return false;
    }
    return true;
  };

  const uploadFile = (type) => {
    if (fileList.length !== 0) {
      const form = new FormData();
      form.append("file", fileList[0]?.originFileObj);
      setLoading(true);
      axios
        .post("/uploading", form)
        .then((res) => {
          type === "new"
            ? onAddMotivation({ ...data, image: res.data.blobName })
            : onEditMotivation({ ...data, image: res.data.blobName });
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          message.error(t("translations:error_image"));
          setLoading(false);
        });
    } else {
      setError({ ...error, fileError: t("translations:image_error") });
    }
  };

  useEffect(() => {
    if (motivation.title !== "") {
      setData({
        title: motivation?.title,
        description: motivation?.description,
        price: motivation?.price,
        category: motivation?.category,
        showHeader: motivation?.showHeader,
        showFooter: motivation?.showFooter,
      });
    }
  }, [motivation]);

  const onCreateMotivation = () => {
    setClicked(true);
    const isValid = validate();
    if (isValid) {
      if (fileList.length !== 0) {
        uploadFile("new");
      } else {
        setError({ ...error, fileError: t("translations:image_error") });
      }
    }
  };

  const onUpdateMotivation = () => {
    setClicked(true);
    const isValid = validate();
    if (isValid) {
      if (fileList[0]?.originFileObj) {
        uploadFile("old");
      } else {
        onEditMotivation(data);
      }
    }
  };

  const onChanges = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    setError({ ...error, fileError: "" });
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  return loading ? (
    <Progress />
  ) : (
    <div className="create-package">
      <div className="create-package-box">
        <div className="main-part">
          <div
            className="col-md-2 col-sm-12 col-xs-12 mb-3"
            style={{ padding: "0 10px" }}
          >
            <div className="image-main-part">
              <div
                className={
                  fileList.length < 1 && motivation?.image
                    ? "in-edit-part-input"
                    : "main-width-part"
                }
              >
                <div className="image-part-create flex mr-2">
                  <ImgCrop>
                    <Upload
                      listType="picture-card"
                      fileList={fileList}
                      onChange={onChanges}
                      onPreview={onPreview}
                      accept="image/*"
                      defaultFileList={[...fileList]}
                    >
                      {fileList.length < 1 && "+ Upload"}
                    </Upload>
                  </ImgCrop>
                </div>
              </div>
              <div className="image-wrapper-create">
                {fileList.length < 1 && motivation?.image && (
                  <img
                    className="image-in-create img-fluid"
                    style={{ marginTop: "0px" }}
                    src={
                      motivation?.image &&
                      `https://yeapportal.blob.core.windows.net/yeapportal/${motivation?.image}?${sas}`
                    }
                    alt="demo"
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className="col-md-5 col-sm-12 col-xs-12"
            style={{ padding: "0 10px" }}
          >
            <div className="input-text">
              <CssTextField
                value={data?.title}
                label={t("translations:title")}
                type="text"
                className="w-100 mb-3"
                id="custom-css-outlined-input"
                autoComplete="off"
                placeholder={t("translations:title_placeholder")}
                helperText={
                  clicked && data?.title === ""
                    ? t("translations:title_Error")
                    : ""
                }
                error={clicked && data?.title === ""}
                onChange={(e) => setData({ ...data, title: e.target.value })}
              />
            </div>
            <div className="input-text mb-3">
              <CssTextField
                error={clicked && data?.description === ""}
                value={data?.description}
                label={t("translations:description")}
                type="text"
                className="w-100"
                autoComplete="off"
                multiline
                rows={4.8}
                placeholder={t("translations:description_placeholder")}
                helperText={
                  clicked && data?.description === ""
                    ? t("translations:description_error")
                    : ""
                }
                onChange={(e) =>
                  e.target.value.length <= 200 &&
                  setData({ ...data, description: e.target.value })
                }
              />
              <span className="d-flex justify-content-end description-charcter">{`${
                data?.description.length
              } / 200 ${t("translations:characters")}`}</span>
            </div>
          </div>
          <div
            className="col-md-5 col-sm-12 col-xs-12"
            style={{ padding: "0 0 0 10px" }}
          >
            <div className="category">
              <FormControl fullWidth error={clicked && data?.category === ""}>
                <InputLabel>{t("translations:category")}</InputLabel>
                <Select
                  id="demo-simple-select"
                  value={data?.category}
                  label={t("translations:category")}
                  onChange={(e) => {
                    setData({ ...data, category: e.target.value });
                    setError({ ...error, categoryError: "" });
                  }}
                >
                  {allCategories.map((cate, index) => {
                    return (
                      <MenuItem value={cate} key={index}>
                        {cate}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {error?.categoryError && (
                <div className="error-message">{error?.categoryError}</div>
              )}
            </div>
            <div className="price-slider mb-2">
              <div style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                {t("translations:price")} :{" "}
                <span style={{ fontWeight: "bold" }}>
                  {data?.price}{" "}
                  <img src={coin} className="coin-image" alt="coin" />
                </span>
              </div>
              <PrettoSlider
                value={data?.price}
                defaultValue={0}
                valueLabelDisplay="auto"
                size="medium"
                min={0}
                max={100}
                onChange={(e) => setData({ ...data, price: e.target.value })}
                marks={[
                  { value: 0, label: "0" },
                  { value: 100, label: "100" },
                ]}
                aria-label="pretto slider"
              />
            </div>
            <div className="add-header-part">
              <Checkbox
                checked={data?.showFooter}
                onChange={() =>
                  setData({ ...data, showFooter: !data?.showFooter })
                }
              />
              <span className="checkbox-text">
                {t("translations:first_check")}
              </span>
            </div>
            <div className="add-header-part">
              <Checkbox
                checked={data?.showHeader}
                onChange={() =>
                  setData({ ...data, showHeader: !data?.showHeader })
                }
              />
              <span className="checkbox-text">
                {t("translations:second_check")}
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-around mt-2">
          {created && (
            <div className="p-0 main-button">
              <Button
                variant="contained"
                className="add-button"
                onClick={() => {
                  onUpdateMotivation();
                }}
              >
                {t("translations:save")}
              </Button>
            </div>
          )}
          {created && (
            <div className="p-0 main-button">
              <Button
                variant="contained"
                className="add-button"
                onClick={() => {
                  onForward();
                }}
              >
                {t("translations:forward")}
              </Button>
            </div>
          )}
          {(!oldMotivation || !motivation?.publish) && (
            <div className="p-0 main-button">
              <Button
                variant="contained"
                className="add-button"
                onClick={() => {
                  created ? onChange(1) : onCreateMotivation();
                }}
              >
                {t("translations:next")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateFirstPage;
