import * as actionsTypes from "./ActionType";
import axios from "../../axios";

export const getWallet = (id) => {
  return async (dispatch) => {
    dispatch({ type: actionsTypes.GET_WALLET_LOADING });
    await axios
      .get(`/walletdetail/${id}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionsTypes.GET_WALLET_SUCCESS,
            payload: {
              withdrawCoin: res.data.withdrawCoin,
              balanceCoin: res.data.balanceCoin,
              totalRevenue: res.data.totalRevenue,
            },
          });
        } else {
          dispatch({
            type: actionsTypes.GET_WALLET_ERROR,
            error: res.data.message,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionsTypes.GET_WALLET_ERROR,
          error: e,
        });
      });
  };
};
