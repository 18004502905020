import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Progress from "../../Component/Progress/Progress";
import axios from "../../axios";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { message, Tooltip } from "antd";
import Rating from "@mui/material/Rating";
import coin from "../../Assets/coin.png";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#373030",
    color: theme.palette.common.white,
    padding: "8px 0 8px 16px",
    "& > .Mui-active": {
      color: "#fff",
      "& svg": {
        opacity: 1,
        color: "#fff",
      },
    },
    "& > span:hover": {
      color: "#fff",
      opacity: 1,
      "& svg": {
        opacity: 1,
      },
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: "nowrap",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Campaigns = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const handleChange = (event, newValue) => {
    setActiveStep(newValue);
  };
  const [loading, setLoading] = useState(false);
  const [Campaigns, setCampaigns] = useState([]);
  const { sas, selectedCompany } = useSelector((state) => state.Auth);

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [allList, setAllList] = useState([]);

  useEffect(() => {
    const array = [];
    Campaigns.forEach((item) => {
      array.push({
        ...item,
        views: item?.contents.reduce((a, b) => {
          return a + b?.views;
        }, 0),
      });
    });
    setAllList(array);
  }, [Campaigns]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    {
      id: "title",
      disablePadding: false,
      label: t("translations:title"),
    },
    {
      id: "description",
      disablePadding: false,
      label: t("translations:description"),
    },
    {
      id: "noOfFeedback",
      disablePadding: false,
      label: t("translations:no_of_content"),
    },
    {
      id: "createdAt",
      disablePadding: false,
      label: t("translations:created_on"),
    },
    {
      id: "price",
      disablePadding: false,
      label: t("translations:price"),
    },
    {
      id: "subscription",
      disablePadding: false,
      label: t("translations:no_of_users"),
    },
    {
      id: "views",
      disablePadding: false,
      label: t("translations:views"),
    },
    {
      id: "rating",
      disablePadding: false,
      label: t("translations:rating"),
    },
    {
      id: "revenue",
      disablePadding: false,
      label: t("translations:revenue"),
    },
    {
      id: "status",
      disablePadding: false,
      label: t("translations:status"),
    },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <StyledTableCell />
          <StyledTableCell>{t("translations:image")}</StyledTableCell>
          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align={"left"}
              padding={"normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
          <StyledTableCell />
        </TableRow>
      </TableHead>
    );
  }

  const getCampaign = () => {
    setLoading(true);
    axios
      .get(`/getCampaign/${selectedCompany?.companyId}`)
      .then((res) => {
        if (res.status === 200) {
          const allCampaign = res?.data?.data?.map((moti) => {
            let rating = 0;
            let count = 0;
            moti.contents.forEach((con) => {
              if (con?.rating !== 0) {
                rating = rating + con?.rating;
                count++;
              }
            });
            return {
              ...moti,
              rating: rating / count ? (rating / count).toFixed(1) : 0,
            };
          });
          setCampaigns(allCampaign);
          setLoading(false);
        }
      })
      .catch((e) => {
        message.error(e || "Network Error");
        setLoading(false);
      });
  };

  useEffect(() => {
    getCampaign();
  }, []);

  const onChangeStatus = (e, id) => {
    setLoading(true);
    axios
      .post(`/publishCampaign/${id}`, { status: e.target.value })
      .then((res) => {
        if (res.status === 200) {
          message.success(t("translations:status_change"));
          history.push("/campaigns");
          localStorage.removeItem("campaignId");
        }
      })
      .catch((e) => {
        message.error(t("translations:error_status_change"));
        setLoading(false);
      });
    getCampaign();
  };

  return loading ? (
    <Progress />
  ) : (
    <div>
      <div className="view-wrapper default-container">
        <div className="view-tab">
          <Tabs
            value={activeStep}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label={t("translations:tile_view")} {...a11yProps(0)} />
            <Tab label={t("translations:list_view")} {...a11yProps(1)} />
          </Tabs>
        </div>
        <div
          className="main-button"
          style={{ paddingRight: "0px", paddingLeft: "0px" }}
        >
          <Button
            variant="contained"
            className="add-button"
            startIcon={<AddCircleIcon />}
            onClick={() => history.push("/campaigns/createCampaign")}
          >
            {t("translations:create_campaign")}
          </Button>
        </div>
      </div>
      {activeStep === 0 ? (
        Campaigns?.length === 0 ? (
          <div className="no-found">{t("translations:no_package_found")}</div>
        ) : (
          <div className="box_main">
            <div className="default-container">
              {Campaigns &&
                Campaigns.map((single, index) => (
                  <div className="box-bg" key={single?._id}>
                    <div className="row">
                      <div className="col-xl-3 col-lg-4 col-md-5 image-left">
                        <img
                          className="w-100 border-image"
                          src={
                            single?.image &&
                            `https://yeapportal.blob.core.windows.net/yeapportal/${single?.image}?${sas}`
                          }
                          alt="demo"
                        />
                      </div>
                      <div className="col-xl-9 col-lg-8 col-md-7 box-map">
                        <div className="heading-drop">
                          <h4 className="title-box">{single?.title}</h4>
                          {single?.publish === true &&
                            single?.status !== "Under Verification" &&
                            single?.onHoldByYeap === false && (
                              <div>
                                <FormControl
                                  variant="standard"
                                  sx={{ m: 1, minWidth: 120 }}
                                >
                                  <InputLabel id="demo-simple-select-standard-label">
                                    {t("translations:status")}
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={single?.status}
                                    onChange={(e) =>
                                      onChangeStatus(e, single?._id)
                                    }
                                    label={t("translations:status")}
                                  >
                                    <MenuItem value={"Active"}>
                                      {t("translations:active")}
                                    </MenuItem>
                                    <MenuItem value={"On Hold"}>
                                      {t("translations:on_hold")}
                                    </MenuItem>
                                    <MenuItem value={"De Activate"}>
                                      {t("translations:de_activate")}
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                            )}
                        </div>
                        <div className=" right-side-content">
                          <div className="box-text-fisrt">
                            <div className="border-input">
                              {single?.description}
                            </div>
                          </div>
                          <div className="secound-box">
                            <div className=" box_secound d-flex w-100 flex-wrap">
                              <div className="back_in_border d-flex flex-column flex-grow-1">
                                <span className="title_head">
                                  {t("translations:no_content_items")}
                                </span>
                                <span className="t_text">
                                  {single?.noOfFeedback === 0
                                    ? "-"
                                    : single?.noOfFeedback}
                                </span>
                              </div>
                              <div className="back_in_border d-flex flex-column flex-grow-1">
                                <span className="title_head">
                                  {t("translations:created_on")}
                                </span>
                                <span className="t_text">
                                  {moment(single?.createdAt).format("LL")}
                                </span>
                              </div>
                              <div className="back_in_border d-flex flex-column flex-grow-1">
                                <span className="title_head">
                                  {t("translations:budget")}
                                </span>
                                <span className="d-flex align-items-center">
                                  {single?.price}{" "}
                                  <img
                                    src={coin}
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      marginLeft: "8px",
                                    }}
                                    alt="coin"
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between w-100 flex-wrap">
                              <div>
                                <div className="d-flex">
                                  <p
                                    className="s-title_head"
                                    style={{
                                      color:
                                        single?.status === "Draft"
                                          ? "#A9A9A9"
                                          : single?.status === "Active"
                                          ? "#008000"
                                          : single?.status === "On Hold"
                                          ? "#CCCC00"
                                          : single?.status === "Rejected"
                                          ? "#FF0000"
                                          : "#FFA500",
                                    }}
                                  >
                                    {single?.status === "Draft" &&
                                      t("translations:draft")}
                                    {single?.status === "Active" &&
                                      t("translations:active")}
                                    {single?.status === "On Hold" &&
                                      t("translations:on_hold")}
                                    {single?.status === "Rejected" &&
                                      t("translations:rejected")}
                                    {single?.status === "De Activate" &&
                                      t("translations:de_activate")}
                                    {single?.status === "Under Verification" &&
                                      t("translations:under_verification")}
                                  </p>
                                  {single?.rejectedReason && (
                                    <Tooltip title={single?.rejectedReason}>
                                      <QuestionCircleOutlined
                                        style={{
                                          margin: "6px 2px",
                                          fontSize: "14px",
                                          color: "#837c7c",
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                </div>
                                <span className="s-t_text">
                                  {t("translations:status")}
                                </span>
                              </div>
                              <div>
                                <p className="s-title_head">
                                  {single?.subscription === 0
                                    ? "-"
                                    : single?.subscription}
                                </p>
                                <span className="s-t_text">
                                  {t("translations:number_of_users")}
                                </span>
                              </div>
                              <div>
                                <p className="s-title_head">
                                  {single?.contents.reduce((a, b) => {
                                    return a + b?.views;
                                  }, 0) === 0
                                    ? "-"
                                    : single?.contents.reduce((a, b) => {
                                        return a + b?.views;
                                      }, 0)}
                                </p>
                                <span className="s-t_text">
                                  {t("translations:views")}
                                </span>
                              </div>
                              <div>
                                <p className="s-title_head">
                                  {single?.rating === 0 ? (
                                    "-"
                                  ) : (
                                    <div className="d-flex align-items-center">
                                      {single?.rating}
                                      <Rating
                                        defaultValue={1}
                                        max={1}
                                        readOnly
                                        style={{ paddingLeft: "5px" }}
                                      />
                                    </div>
                                  )}
                                </p>
                                <span className="s-t_text">
                                  {t("translations:rating")}
                                </span>
                              </div>
                              <div>
                                {single?.revenue === 0 ? (
                                  <span className="s-title_head"> - </span>
                                ) : (
                                  <span className="s-title_head">
                                    {single?.revenue}{" "}
                                    <img
                                      src={coin}
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginLeft: "8px",
                                      }}
                                      alt="coin"
                                    />
                                  </span>
                                )}
                                <span className="s-t_text">
                                  {t("translations:revenue")}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end">
                            <IconButton
                              size="large"
                              onClick={() =>
                                history.push(
                                  `/campaigns/campaignDetail?id=${single?._id}`
                                )
                              }
                            >
                              <ArrowForwardIosIcon
                                sx={{ fill: "#ff6600" }}
                                fontSize="inherit"
                              />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )
      ) : (
        <div className="default-container">
          <Paper sx={{ width: "100%" }}>
            <TableContainer>
              <Table aria-label="customized table">
                <colgroup>
                  <col style={{ width: "5%" }} />
                  <col style={{ width: "5%" }} />
                  <col style={{ width: "12%" }} />
                  <col style={{ width: "18%" }} />
                  <col style={{ width: "5%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "8%" }} />
                  <col style={{ width: "8%" }} />
                  <col style={{ width: "6%" }} />
                  <col style={{ width: "5%" }} />
                  <col style={{ width: "8%" }} />
                  <col style={{ width: "5%" }} />
                  <col style={{ width: "3%" }} />
                </colgroup>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {allList.length !== 0 ? (
                    stableSort(allList, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell>
                            <img
                              src={
                                row?.image &&
                                `https://yeapportal.blob.core.windows.net/yeapportal/${row?.image}?${sas}`
                              }
                              alt="demo"
                              style={{ width: "50px", height: "50px" }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              history.push(
                                `/packages/packageDetails?id=${row?._id}`
                              )
                            }
                          >
                            <Tooltip placement="bottom" title={row?.title}>
                              {row?.title.length >= 20
                                ? row?.title.substring(0, 20)
                                : row?.title}
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Tooltip
                              placement="bottom"
                              title={row?.description}
                            >
                              {row?.description.length >= 40
                                ? row?.description.substring(0, 40) + "..."
                                : row?.description}
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell>{row?.noOfFeedback}</StyledTableCell>
                          <StyledTableCell>
                            {moment(row?.createdAt).format("LL")}
                          </StyledTableCell>
                          <StyledTableCell>
                            <span className="d-flex align-items-center">
                              {row?.price}{" "}
                              <img
                                src={coin}
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginLeft: "8px",
                                }}
                                alt="coin"
                              />
                            </span>
                          </StyledTableCell>
                          <StyledTableCell>{row?.subscription}</StyledTableCell>
                          {/* <StyledTableCell>{row?.numReviews}</StyledTableCell> */}
                          <StyledTableCell>{row?.views}</StyledTableCell>
                          <StyledTableCell>
                            <p className="s-title_head">
                              {row?.rating === 0 ? (
                                "-"
                              ) : (
                                <div className="d-flex align-items-center">
                                  {row?.rating}
                                  <Rating
                                    defaultValue={1}
                                    max={1}
                                    readOnly
                                    style={{ paddingLeft: "5px" }}
                                  />
                                </div>
                              )}
                            </p>
                          </StyledTableCell>
                          <StyledTableCell>
                            <span className="d-flex align-items-center">
                              {row?.revenue}{" "}
                              <img
                                src={coin}
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginLeft: "8px",
                                }}
                                alt="coin"
                              />
                            </span>
                          </StyledTableCell>
                          <StyledTableCell>
                            <div
                              style={{
                                color:
                                  row?.status === "Draft"
                                    ? "#A9A9A9"
                                    : row?.status === "Active"
                                    ? "#008000"
                                    : row?.status === "On Hold"
                                    ? "#CCCC00"
                                    : row?.status === "Rejected"
                                    ? "#FF0000"
                                    : "#FFA500",
                              }}
                            >
                              {row?.status === "Draft" &&
                                t("translations:draft")}
                              {row?.status === "Active" &&
                                t("translations:active")}
                              {row?.status === "On Hold" &&
                                t("translations:on_hold")}
                              {row?.status === "Rejected" &&
                                t("translations:rejected")}
                              {row?.status === "De Activate" &&
                                t("translations:de_activate")}
                              {row?.status === "Under Verification" &&
                                t("translations:under_verification")}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell>
                            <IconButton
                              size="medium"
                              onClick={() =>
                                history.push(
                                  `/motivation/motivationDetail?id=${row?._id}`
                                )
                              }
                            >
                              <ArrowForwardIosIcon
                                sx={{ fill: "#ff6600" }}
                                fontSize="inherit"
                              />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                  ) : (
                    <StyledTableCell component="th" scope="row">
                      {t("translations:no_package_found")}
                    </StyledTableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ borderTop: "1px solid #e0e0e0" }}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={allList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </Paper>
        </div>
      )}
    </div>
  );
};

export default Campaigns;
