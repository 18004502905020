import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { editPaypalInfo } from "../../../../../Redux/Action/BankInfoaction";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#ff6600",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ff6600",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#1490cc",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ff6600",
    },
  },
});

const PaypalForm = ({ paypalInfo }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedCompanyInfo } = useSelector((state) => state.Auth);
  const [clicked, setClicked] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    setEmail(paypalInfo?.email);
  }, [paypalInfo]);

  const onSubmit = () => {
    setClicked(true);
    if (email !== "") {
      dispatch(
        editPaypalInfo({ company: selectedCompanyInfo?._id, email: email })
      );
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-sm">
          <CssTextField
            value={email}
            label={t("translations:email_address")}
            type="email"
            className="w-100 mb-3 mt-3"
            placeholder={t("translations:paypal_email")}
            error={clicked && email === ""}
            helperText={
              clicked && email === "" ? t("translations:email_error1") : ""
            }
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="col-sm"></div>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <button className="px-4 profile-button" onClick={() => onSubmit()}>
          {t("translations:save")}
        </button>
      </div>
    </div>
  );
};

export default PaypalForm;
