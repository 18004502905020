import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PackageContent from "./PackageContent";
import PackageDetails from "./PackageDetails";
import { useSelector, useDispatch } from "react-redux";
import { Tabs, Tab } from "@mui/material";
import { BackButton } from "../../../Common/BackButton";
import Progress from "../../../Component/Progress/Progress";
import {
  getAllKnowHow,
  getAllCampaign,
  getAllPackages,
} from "../../../Redux/Action/Packageaction";
import { useTranslation } from "react-i18next";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ViewPackage = () => {
  const { t } = useTranslation();
  let query = useQuery();
  const pacakgeId = query.get("id");
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedPackage, setSelectedPackage] = useState([]);
  const data = useSelector((state) => state.AllPackages);
  const { allPackage, allKnowHow, allCampaign, loading } = data;

  const sas = useSelector((state) => state.Auth.sas);

  useEffect(() => {
    const allData = allPackage.concat(allKnowHow, allCampaign);
    setSelectedPackage(allData.filter((data) => data?._id === pacakgeId));
    if (
      allPackage.length === 0 &&
      allKnowHow.length === 0 &&
      allCampaign.length === 0
    ) {
      dispatch(getAllPackages());
      dispatch(getAllCampaign());
      dispatch(getAllKnowHow());
    }
  }, [allPackage, allKnowHow, allCampaign]);

  const handleChange = (event, newValue) => {
    setActiveStep(newValue);
  };

  return loading ? (
    <Progress />
  ) : (
    <div className="mt-3">
      <div className="companies-tab default-container">
        <BackButton />
        <Tabs
          value={activeStep}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label={t("translations:package_details")} {...a11yProps(0)} />
          <Tab label={t("translations:package_content")} {...a11yProps(1)} />
        </Tabs>
      </div>
      {activeStep === 0 && (
        <PackageDetails selectedPackage={selectedPackage[0]} sas={sas} />
      )}
      {activeStep === 1 && (
        <PackageContent allContent={selectedPackage[0]?.contents} />
      )}
    </div>
  );
};

export default ViewPackage;
