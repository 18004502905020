import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllKnowHow,
  getAllCampaign,
  getAllPackages,
  onAcceptRejectPackage,
  onAcceptRejectKnowHow,
  onAcceptRejectCampaign,
} from "../../Redux/Action/Packageaction";
import { RESET_GET_ALL_PACKAGES_ERROR } from "../../Redux/Action/ActionType";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PendingPackages from "./PendingPackages";
import ActivePackages from "./ActivePackages";
import RejectedPackages from "./RejectedPackages";
import Progress from "../../Component/Progress/Progress";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import "./Packages.css";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Packages = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const handleChange = (event, newValue) => {
    setActiveStep(newValue);
  };
  const data = useSelector((state) => state.AllPackages);
  const { allPackage, allKnowHow, allCampaign, loading, error, updateMessage } =
    data;
  const sas = useSelector((state) => state.Auth.sas);
  const [allMergePackages, setAllMergePackages] = useState([]);
  const [pendingList, setPendingList] = useState([]);
  const [activeList, setActiveList] = useState([]);
  const [rejectedList, setRejectedList] = useState([]);
  const [selectedPackageType, setSelectedPackageType] = useState("All");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPackages());
    dispatch(getAllCampaign());
    dispatch(getAllKnowHow());
  }, []);

  useEffect(() => {
    setAllMergePackages(allPackage.concat(allKnowHow, allCampaign));
  }, [allPackage, allKnowHow, allCampaign]);

  useEffect(() => {
    const list =
      selectedPackageType === "All"
        ? allMergePackages
        : selectedPackageType === "Motivation"
        ? allPackage
        : selectedPackageType === "Know-How"
        ? allKnowHow
        : allCampaign;
    setPendingList(list.filter((com) => com?.status === "Under Verification"));
    setActiveList(
      list.filter(
        (com) =>
          com?.status !== "Under Verification" && com?.onHoldByYeap === false
      )
    );
    setRejectedList(list.filter((com) => com?.onHoldByYeap === true));
  }, [
    allMergePackages,
    allPackage,
    allKnowHow,
    allCampaign,
    selectedPackageType,
    loading,
  ]);

  useEffect(() => {
    if (error !== "") {
      message.error(error);
      dispatch({ type: RESET_GET_ALL_PACKAGES_ERROR });
    }
  }, [error]);

  useEffect(() => {
    if (updateMessage !== "") {
      message.success(updateMessage);
      dispatch({ type: RESET_GET_ALL_PACKAGES_ERROR });
    }
  }, [updateMessage]);

  useEffect(() => {}, [selectedPackageType]);

  const onAccept = (data) => {
    data?.type === "Motivation"
      ? dispatch(onAcceptRejectPackage({ ...data }))
      : data?.type === "Know-How"
      ? dispatch(onAcceptRejectKnowHow({ ...data }))
      : dispatch(onAcceptRejectCampaign({ ...data }));
  };

  return loading ? (
    <Progress />
  ) : (
    <div style={{ paddingTop: "20px" }}>
      <div className="companies-tab default-container">
        <Tabs
          value={activeStep}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label={t("translations:pending_list")} {...a11yProps(0)} />
          <Tab label={t("translations:active_list")} {...a11yProps(1)} />
          <Tab label={t("translations:rejected_list")} {...a11yProps(2)} />
          <div className="ms-auto select-type">
            <span
              className={selectedPackageType === "All" && "selected-type"}
              onClick={() => setSelectedPackageType("All")}
            >
              {t("translations:all")}
            </span>
            <span
              className={
                selectedPackageType === "Motivation" && "selected-type"
              }
              onClick={() => setSelectedPackageType("Motivation")}
            >
              {t("translations:motivation")}
            </span>
            <span
              className={selectedPackageType === "Know-How" && "selected-type"}
              onClick={() => setSelectedPackageType("Know-How")}
            >
              {t("translations:know_how")}
            </span>
            <span
              className={selectedPackageType === "Campaigns" && "selected-type"}
              onClick={() => setSelectedPackageType("Campaigns")}
            >
              {t("translations:campaigns")}
            </span>
          </div>
        </Tabs>
      </div>
      <div className="default-container">
        {activeStep === 0 && (
          <PendingPackages
            pendingList={pendingList}
            sas={sas}
            onAccept={(data) => onAccept(data)}
          />
        )}
        {activeStep === 1 && (
          <ActivePackages
            activeList={activeList}
            sas={sas}
            onAccept={(data) => onAccept(data)}
          />
        )}
        {activeStep === 2 && (
          <RejectedPackages
            rejectedList={rejectedList}
            sas={sas}
            onAccept={(data) => onAccept(data)}
          />
        )}
      </div>
    </div>
  );
};

export default Packages;
