import * as actionTypes from "../Action/ActionType";

const initstate = {
  company: {},
};

const companyProfile = (state = initstate, action) => {
  switch (action.type) {
    case actionTypes.COMPLETE_PROFILE_SUCCESS:
      return {
        ...state,
        company: action?.payload?.company
      };
    default:
      return state;
  }
};

export default companyProfile;
