import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Progress from "../../Component/Progress/Progress";
import Card from "../../Component/Card/Card";
import axios from "../../axios";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Radio } from "antd";
import { DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import "./Dashboard.css";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const { RangePicker } = DatePicker;

const Dashboard = () => {
  const { t } = useTranslation();

  const { selectedCompanyInfo } = useSelector((state) => state.Auth);
  const [graphData, setGraphData] = useState();
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState();
  const [graphLoading, setGraphLoading] = useState(true);

  const [selectValue, setSelectValue] = useState("monthly");

  const onChangeSelect = (e) => {
    setValue([]);
    setHackValue([]);
    setDates([]);
    setSelectValue(e.target.value);
  };

  const [dates, setDates] = useState([]);
  const [hackValue, setHackValue] = useState([]);
  const [value, setValue] = useState([]);
  const disabledDate = (current) => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate =
      selectValue === "daily"
        ? dates[0] && current.diff(dates[0], "days") > 14
        : selectValue === "weekly"
        ? dates[0] && current.diff(dates[0], "days") > 90
        : dates[0] && current.diff(dates[0], "days") > 330;
    const tooEarly =
      selectValue === "daily"
        ? dates[1] && dates[1].diff(current, "days") > 14
        : selectValue === "weekly"
        ? dates[0] && current.diff(dates[0], "days") > 90
        : dates[0] && current.diff(dates[0], "days") > 330;
    return tooEarly || tooLate;
  };

  const onOpenChange = (open) => {
    if (open) {
      setHackValue([]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  };

  useEffect(() => {
    if (selectedCompanyInfo._id !== undefined) {
      setLoading(true);
      axios
        .get(`/getDashboardData/${selectedCompanyInfo?._id}`)
        .then((res) => {
          setDashboardData(res.data);
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    }
  }, [selectedCompanyInfo]);

  const getGraphData = (startDate, endDate) => {
    setGraphLoading(true);
    axios
      .post(`/getGraphData/${selectValue}/${selectedCompanyInfo?._id}`, {
        startDate,
        endDate,
      })
      .then((res) => {
        setGraphData(res.data);
        setGraphLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setGraphLoading(false);
      });
  };

  useEffect(() => {
    if (selectedCompanyInfo._id !== undefined) {
      getGraphData(
        new Date(Date.now() - 365 * 24 * 60 * 60 * 1000),
        new Date(Date.now())
      );
    }
  }, [selectedCompanyInfo]);

  const onChangeDate = (val) => {
    const startDate = new Date(val[0]._d);
    selectValue === "monthly" && startDate.setMonth(startDate.getMonth() - 1);
    setValue(val);
    getGraphData(startDate, new Date(val[1]._d));
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      labels: {
        family: `"Poppins", sans-serif`,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const labels = graphData && Object.keys(graphData?.likes);

  const revenueData = {
    labels,
    datasets: [
      {
        label: "Revenue",
        data: graphData && Object.values(graphData?.revenue),
        borderColor: "#ff6600",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const customerData = {
    labels,
    datasets: [
      {
        label: "Customers",
        data: graphData && Object.values(graphData?.customers),
        borderColor: "#ff6600",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const distributionData = {
    labels,
    datasets: [
      {
        label: "Distributions",
        data: graphData && Object.values(graphData?.distributions),
        borderColor: "#ff6600",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const likesData = {
    labels,
    datasets: [
      {
        label: "Likes",
        data: graphData && Object.values(graphData.likes),
        borderColor: "#ff6600",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const packages = [
    {
      title: t("translations:motivation"),
      number: dashboardData?.motivation,
      link: "/motivation",
    },
    {
      title: t("translations:know_how"),
      number: dashboardData?.knowhow,
      link: "/know-how",
    },
    {
      title: t("translations:campaigns"),
      number: dashboardData?.campaign,
      link: "/campaigns",
    },
  ];

  const adminPackages = [
    {
      title: t("translations:motivation"),
      number: dashboardData?.motivation,
      link: "/motivation",
      background: "#55B4B0",
      firstColor: "#FFFFFF",
      secondColor: "#FFFFFF",
    },
    {
      title: t("translations:know_how"),
      number: dashboardData?.knowhow,
      link: "/know-how",
      background: "#6B5B95",
      firstColor: "#FFFFFF",
      secondColor: "#FFFFFF",
    },
    {
      title: t("translations:campaigns"),
      number: dashboardData?.campaign,
      link: "/campaigns",
      background: "#FF6F61",
      firstColor: "#FFFFFF",
      secondColor: "#FFFFFF",
    },
  ];

  const analysis = [
    {
      title: t("translations:total_revenues"),
      number: dashboardData?.revenue,
    },
    {
      title: t("translations:total_customers"),
      number: dashboardData?.customers,
    },
    {
      title: t("translations:total_distributions"),
      number: dashboardData?.distribution,
    },
    {
      title: t("translations:total_likes"),
      number: dashboardData?.likes,
    },
  ];

  const adminAnalysis = [
    {
      title: t("translations:total_companies"),
      number: dashboardData?.companies,
      background: "#88B04B",
      firstColor: "#FFFFFF",
      secondColor: "#FFFFFF",
    },
    {
      title: t("translations:total_revenues"),
      number: dashboardData?.revenue,
      background: "#0072B5",
      firstColor: "#FFFFFF",
      secondColor: "#FFFFFF",
    },
    {
      title: t("translations:total_customers"),
      number: dashboardData?.customers,
      background: "#D2386C",
      firstColor: "#FFFFFF",
      secondColor: "#FFFFFF",
    },
    {
      title: t("translations:total_distributions"),
      number: dashboardData?.distribution,
      background: "#00A170",
      firstColor: "#FFFFFF",
      secondColor: "#FFFFFF",
    },
  ];

  return (
    <div style={{ paddingBottom: "30px" }}>
      {loading ? (
        <Progress />
      ) : (
        <div>
          <Card
            title={t("translations:packages")}
            cards={
              selectedCompanyInfo?._id === "623a23085f6b21fda23d1d2a"
                ? adminPackages
                : packages
            }
            id={selectedCompanyInfo._id}
          />
          <Card
            title={t("translations:analysis")}
            cards={
              selectedCompanyInfo?._id === "623a23085f6b21fda23d1d2a"
                ? adminAnalysis
                : analysis
            }
            id={selectedCompanyInfo._id}
          />
          <div className="default-container filter-part">
            <Radio.Group
              defaultValue={"monthly"}
              size={"large"}
              onChange={onChangeSelect}
              value={selectValue}
              className="select-part"
            >
              <Radio.Button value={"monthly"} size={"large"}>
                {t("translations:monthly")}
              </Radio.Button>
              <Radio.Button value={"weekly"} size={"large"}>
                {t("translations:weekly")}
              </Radio.Button>
              <Radio.Button value={"daily"} size={"large"}>
                {t("translations:daily")}
              </Radio.Button>
            </Radio.Group>
            {(selectValue === "daily" || selectValue === "weekly") && (
              <RangePicker
                size={"large"}
                value={hackValue || value}
                format={"MMMM Do YYYY"}
                disabledDate={disabledDate}
                onCalendarChange={(val) => setDates(val)}
                onChange={(val) => onChangeDate(val)}
                onOpenChange={onOpenChange}
              />
            )}
            {selectValue === "monthly" && (
              <RangePicker
                picker="month"
                size={"large"}
                format={"MMM YYYY"}
                value={hackValue || value}
                disabledDate={disabledDate}
                onCalendarChange={(val) => setDates(val)}
                onChange={(val) => onChangeDate(val)}
                onOpenChange={onOpenChange}
              />
            )}
          </div>
        </div>
      )}
      {graphLoading ? (
        <Progress />
      ) : (
        <div className="chart-wrapper">
          <div className="default-container chart-para">
            <div className="single-chart">
              <div className="chart-heading">{t("translations:revenue")}</div>
              <Line
                options={options}
                data={revenueData}
                width={500}
                height={300}
              />
            </div>
            <div className="single-chart">
              <div className="chart-heading">{t("translations:customers")}</div>
              <Line
                options={options}
                data={customerData}
                width={500}
                height={300}
              />
            </div>
          </div>
          <div className="default-container chart-para">
            <div className="single-chart">
              <div className="chart-heading">
                {t("translations:distributions")}
              </div>
              <Line
                options={options}
                data={distributionData}
                width={500}
                height={300}
              />
            </div>
            <div className="single-chart">
              <div className="chart-heading">{t("translations:likes")}</div>
              <Line
                options={options}
                data={likesData}
                width={500}
                height={300}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
