import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./CreateKnowhow.css";

const Publish = ({ onChange, onPublish }) => {
  const { t } = useTranslation();
  return (
    <div className="publish-package-box-motivation">
      <div className="d-flex justify-content-center mt-3 mb-3 main-button">
        <Button
          variant="contained"
          className="add-button"
          onClick={() => onPublish("Under Verification", true)}
        >
          {t("translations:publish_know_how")}
        </Button>
      </div>
      <div className="d-flex justify-content-between">
        <div className="main-button">
          <Button
            variant="contained"
            className="add-button"
            onClick={() => onChange(1)}
          >
            {t("translations:previous")}
          </Button>
        </div>
        <div className="main-button">
          <Button
            variant="contained"
            className="add-button"
            onClick={() => onPublish("Draft", false)}
          >
            {t("translations:save_as_draft")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Publish;
