import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import { visuallyHidden } from "@mui/utils";
import moment from "moment";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#373030",
    color: theme.palette.common.white,
    padding: "8px 0 8px 16px",
    "& > .Mui-active": {
      color: "#fff",
      "& svg": {
        opacity: 1,
        color: "#fff",
      },
    },
    "& > span:hover": {
      color: "#fff",
      opacity: 1,
      "& svg": {
        opacity: 1,
      },
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: "nowrap",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TransactionDetail = ({ transaction }) => {
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [selectedPackage, setSelectedPackage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <StyledTableCell />
          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align={"left"}
              padding={"normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
          <StyledTableCell />
        </TableRow>
      </TableHead>
    );
  }
  const headCells = [
    {
      id: "createdAt",
      disablePadding: false,
      label: t("translations:date"),
    },
    {
      id: "requestAmount",
      disablePadding: false,
      label: t("translations:amount"),
    },
    {
      id: "transactionType",
      disablePadding: false,
      label: t("translations:transaction_type"),
    },
    {
      id: "status",
      disablePadding: false,
      label: t("translations:status"),
    },
  ];

  return (
    <div>
      {/* <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem>
          <ListItemIcon>
            <EditIcon fontSize="small" style={{ fill: "#008000" }} />
          </ListItemIcon>
          <ListItemText>Edit Transaction</ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <CancelIcon fontSize="small" style={{ fill: "#ff0000" }} />
          </ListItemIcon>
          <ListItemText>Cancel Transaction</ListItemText>
        </MenuItem>
      </Menu> */}
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table aria-label="customized table">
            <colgroup>
              <col style={{ width: "5%" }} />
              <col style={{ width: "12%" }} />
              <col style={{ width: "12%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "5%" }} />
            </colgroup>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {transaction.length !== 0 ? (
                stableSort(transaction, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell>
                        {moment(row?.createdAt).format("LL")}
                      </StyledTableCell>
                      <StyledTableCell>{row?.requestAmount}</StyledTableCell>
                      <StyledTableCell>{row?.transactionType}</StyledTableCell>
                      <StyledTableCell
                        style={{
                          color:
                            row?.status === "Pending" ? "#ff0000" : "#008000",
                        }}
                      >
                        {row?.status}
                      </StyledTableCell>
                      <StyledTableCell>
                        <IconButton
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={(e) => {
                            setSelectedPackage(row);
                            handleClick(e);
                          }}
                        >
                          <MoreVertRoundedIcon color="primary" />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
              ) : (
                <StyledTableCell component="th" scope="row">
                  {t("translations:not_found_transaction")}
                </StyledTableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ borderTop: "1px solid #e0e0e0" }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={transaction.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </div>
  );
};

export default TransactionDetail;
